import React, { useEffect, useState } from 'react';

import axios from 'axios';

import { Link } from 'react-router-dom';

import API from '../../../../api';

import { Html5Entities } from 'html-entities';
const htmlEntities = new Html5Entities();

const PostCategory = (props) => {
  const [categories, getCategories] = useState([]);
  const [dataStatus, setDataStatus] = useState(false);
  const { isBig, categoriesBreadcrumbs } = props;

  useEffect(() => {
    (async () => {
      try {
        const res = await axios.get(API.basePath + '/wp-json/wp/v2/categories?post=' + props.post);
        if (res.data) {
          getCategories(res.data);
          setDataStatus(true);
        } else {
          console.log(res);
        }
      } catch (e) {
        console.log(e);
      }
    })();
  }, [props.post]);


  if (dataStatus) {
    return (
      <div className={isBig ? "articles__category-post" : "articles__category"}>
        {/* {(categories.length <= 2 || isBig) ? categories.map(({ name }) => name).join(" | ") : `${categories.map(({ name }) => name).slice(0, 2).join(" | ")}...`} */}
        {categories.map((category, key) => {
          const { name, slug, id, count } = category;
          return (
            <div key={key}>
              {slug !== "uncategorized" ?
                <div className='articles__category_item' key={`post_cat_${id}`}>
                  <Link to={`${'/blog/'}${slug}`} onClick={() => categoriesBreadcrumbs(name, count)}>
                    <svg width="21" height="12" viewBox="0 0 21 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M20.4588 5.91706L20.4602 5.91829C20.4886 5.94321 20.5 5.9737 20.5 6C20.5 6.0263 20.4886 6.05679 20.4602 6.08171L20.4588 6.08294L14.8045 11.0829L14.8033 11.0841C14.7766 11.1078 14.7357 11.125 14.6895 11.125H0.65625C0.546873 11.125 0.5 11.0461 0.5 11V1C0.5 0.953851 0.546874 0.875 0.65625 0.875H14.6895C14.7357 0.875 14.7766 0.892215 14.8033 0.915951L14.8045 0.917059L20.4588 5.91706Z" stroke="url(#category-icon)" />
                      <defs>
                        <linearGradient id="category-icon" x1="21" y1="11.625" x2="11.6343" y2="-5.85766" gradientUnits="userSpaceOnUse">
                          <stop stopColor="#FC6076" />
                          <stop offset="0.484375" stopColor="#FF4D66" />
                          <stop offset="1" stopColor="#FF6826" />
                        </linearGradient>
                      </defs>
                    </svg>
                    <span>{htmlEntities.decode(name)}</span>
                  </Link>
                </div>
                :

                <div className='articles__category_item' key={`post_cat_${id}`}>
                  <svg width="21" height="12" viewBox="0 0 21 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20.4588 5.91706L20.4602 5.91829C20.4886 5.94321 20.5 5.9737 20.5 6C20.5 6.0263 20.4886 6.05679 20.4602 6.08171L20.4588 6.08294L14.8045 11.0829L14.8033 11.0841C14.7766 11.1078 14.7357 11.125 14.6895 11.125H0.65625C0.546873 11.125 0.5 11.0461 0.5 11V1C0.5 0.953851 0.546874 0.875 0.65625 0.875H14.6895C14.7357 0.875 14.7766 0.892215 14.8033 0.915951L14.8045 0.917059L20.4588 5.91706Z" stroke="url(#category-icon)" />
                    <defs>
                      <linearGradient id="category-icon" x1="21" y1="11.625" x2="11.6343" y2="-5.85766" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FC6076" />
                        <stop offset="0.484375" stopColor="#FF4D66" />
                        <stop offset="1" stopColor="#FF6826" />
                      </linearGradient>
                    </defs>
                  </svg>
                  <span>{htmlEntities.decode(name)}</span>
                </div>
              }
            </div>
          )
        }
        )}
      </div>
    );
  }
  return null;
};

export default PostCategory;
