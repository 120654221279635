import React, {Component} from "react";
import axios from "axios";
import API from "../../../api";
import MainBanner from '../../MainBanner/MainBanner';
import PageMeta from "../../PageMeta/PageMeta";
import CustomSchema from "../../CustomSchema/CustomSchema";
import Support from "../../Support/Support";
import Titling from "./Titling/Titling";
import Info from "./Info/Info";
import TechnologiesList from "./TechnologiesList/TechnologiesList";
import "./WebEntwicklung.scss";

class Webentwicklung extends Component {
    state = {
        page_data: [],
        yoast_meta: [],
        loaded_status: false
    };

    loadData = async () => {
        // Get company menu items
        axios.get(API.basePath+'/wp-json/wp/v2/pages?slug=webentwicklung')
            .then(response => {
                this.setState({
                    page_data: response.data[0].acf,
                    yoast_meta: response.data[0],
                    loaded_status: true
                });
            })
            .catch(error => {
                console.log(error);
            });
        this.props.setContactElements();
        this.props.setHeaderOptions();
    }

    componentDidMount() {
        this.loadData()
    }
    render() {
        if (this.state.loaded_status) {

            const page_data = this.state.page_data;

            return (
                <>
                    <PageMeta metaData={this.state.yoast_meta || null} hreflangs={this.state.page_data['hreflang_list'] || null} />
                    <CustomSchema schema={this.state.page_data.schema} />
                    <div className="software-page">
                        <MainBanner content={this.state.page_data.main_banner_content} id='software'/>
                        <div className="software-page__content" id="scrollPosition">
                            <Titling data={page_data} />
                            <Info data={page_data} />
                            <TechnologiesList data={page_data} />
                            <Support data={page_data} />
                        </div>
                    </div>
                </>
            );

        } else {
            return (
                <>
                    <PageMeta metaData={this.state.yoast_meta || null} />
                    <div className="software-page">
                        <MainBanner content={this.state.page_data.main_banner_content || null} id='software'/>
                    </div>
                </>
            );
        }
    }
}

export default Webentwicklung;