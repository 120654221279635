import React, { useState, useEffect } from 'react';
import '../Usercentrics/Usercentrics.scss';
import MainBanner from "../../MainBanner/MainBanner";
import axios from 'axios';
import API from '../../../api';
import Loader from "../../Loader/Loader";
import PageMeta from "../../PageMeta/PageMeta";
import CustomSchema from "../../CustomSchema/CustomSchema";

import Info from './Info/Info';
import CookieRequired from './CookieRequired/CookieRequired';
import Compliant from './Compliant/Compliant';
import Management from './Management/Management';
import Integration from './Integration/Integration';
import Collaboration from './Collaboration/Collaboration';
import What from './What/What';
import CMP from './CMP/CMP';
import Why from './Why/Why';
import Implementation from './Implementation/Implementation';

import FAQBlock from '../../FAQBlock/FAQBlock'

const Usercentrics = (props) => {
    const [page_data, setPage_data] = useState([]);
    const [yoast_meta, setYoast_meta] = useState([]);
    const [loaded_status, setLoaded_status] = useState(false);

    useEffect(() => {
        axios
            .get(API.basePath + '/wp-json/wp/v2/pages?slug=cookie-banner-integration')
            .then((response) => {
                setPage_data(response.data[0]);
                setYoast_meta(response.data[0]);
                setLoaded_status(true);
            })
            .catch((error) => {
                console.log(error);
            });

        props.setContactElements();
    }, [loaded_status]);

    if (loaded_status) {
        return (
            <>
                <PageMeta metaData={yoast_meta || null} hreflangs={page_data.acf['hreflang_list'] || null} />
                <CustomSchema schema={page_data.acf.schema} />
                <div className='usercentrics-page'>
                    <MainBanner content={page_data.acf.main_banner_content || null} id='cookieBannerIntegration' />
                    <div className='usercentrics-page__content' id="scrollPosition">
                        <Info content={page_data.acf.info || null} />
                        <CookieRequired content={page_data.acf.cookie_required || null} />
                        <Compliant content={page_data.acf.compliant || null} />
                        <Management content={page_data.acf.management || null} />
                        <Integration content={page_data.acf.integration || null} />
                        <Collaboration content={page_data.acf.collaboration || null} />
                        <What content={page_data.acf.what || null} />
                        <CMP content={page_data.acf.cmp || null} />
                        <Why content={page_data.acf.why || null} />
                        <Implementation content={page_data.acf.implementation || null} support={page_data.acf.support || null}/>
                        <FAQBlock content={page_data.acf.FAQ_list || null}/>
                    </div>
                </div>
            </>
        );
    } else {
        return (
            <>
                <div className="usercentrics-page usercentrics-page--preloader">
                    <Loader />
                </div>
            </>
        );
    }
};
export default Usercentrics;
