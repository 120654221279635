import React from "react";
import "../WebEntwicklung.scss"

const Info = ({data}) => {
    return (
        data?.left_description_block && (<div className="software-page__text-info">
            <div className="software-info">
                <div className="software-info__title">
                    <h2 className="software-info__title-text"
                        dangerouslySetInnerHTML={{__html: data?.left_description_block?.title}}/>
                    <div className="software-info__title-bg"
                         dangerouslySetInnerHTML={{__html: data?.left_description_block?.title_bg}}/>
                </div>
                <div className="software-info__description"
                     dangerouslySetInnerHTML={{__html: data?.left_description_block?.description}}/>
            </div>
            <div className="software-info software-info--right">
                <div className="software-info__title">
                    <h2 className="software-info__title-text"
                        dangerouslySetInnerHTML={{__html: data?.right_description_block?.title}}/>
                    <div className="software-info__title-bg"
                         dangerouslySetInnerHTML={{__html: data?.right_description_block?.title_bg}}/>
                </div>
                <div className="software-info__description"
                     dangerouslySetInnerHTML={{__html: data?.right_description_block?.description}}/>
            </div>
        </div>)
    )
}

export default Info;