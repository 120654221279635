import React, { useRef, useEffect, useState, useCallback } from 'react'

import SecondLevelTitle from '../SecondLevelTitle/SecondLevelTitle';

import Particles from 'react-particles-js';

import Plx from 'react-plx/lib/Plx';

import './TimeLine.scss'

import useMobile from "../../../hooks/useMobile"


const TimeLine = (props) => {
    const { title, work_schedule, rocket_launch, button } = props.content
    const animationBlock = useRef(null);
    const [displayedShedule, setDisplayedShedule] = useState([]);
    const isMobile = useMobile();
    const mobileSheduleFirstSize = 8;
    const { canAnimate } = props;


    useEffect(() => {
        setDisplayedShedule(isMobile ? work_schedule.slice(0, mobileSheduleFirstSize) : work_schedule)
    }, [isMobile, work_schedule])

    function getRandomIntInclusive(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    const createElement = useCallback(
        () => {

            const divElement = document.querySelector(".exhaust-fumes");

            let i = 1;
            let j = -18
            while (i <= 12) {
                let w = Math.floor(Math.abs(400 / j));
                if (w >= 120) w = 90;
                if (!document.getElementById('smoke' + i)) {
                    const element = document.createElement("span");
                    element.setAttribute("id", "smoke" + i);
                    element.setAttribute("class", "inner");
                    element.style.width = getRandomIntInclusive(w + 100, w + 150) + 'px';
                    element.style.height = element.style.width;
                    element.style.setProperty('--x', i * 55)
                    element.style.setProperty('--y', w)
                    divElement.appendChild(element);
                }
                i++;
                j += 4;

            }
        },
        [],
    )

    const particles = {

        particles: {
            number: { value: 15, },
            color: { value: '#ffffff' },
            opacity: {
                value: 0.9,
                anim: { enable: true }
            },
            size: {
                value: 20,
                random: true,
                anim: {
                    enable: true,
                    speed: 5
                }
            },
            line_linked: {
                enable: false
            },
            move: {
                speed: 5
            }
        }
    }

    const handleScroll = useCallback(
        () => {
            const rocket = animationBlock.current.getBoundingClientRect();

            if (canAnimate(rocket) && !isMobile) {
                createElement()
            }
        },
        [canAnimate, createElement, isMobile],
    )


    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        }
    }, [handleScroll])

    const parallaxData = [
        {
            start: '.timeline',
            end: '.project-implementation',
            duration: 200,
            properties: [
                {
                    startValue: isMobile ? 0 : 60,
                    endValue: isMobile ? 40 : -20,
                    unit: "%",
                    property: 'translateY',
                },
            ],
        },
    ];

    const showBtn = displayedShedule.length < work_schedule.length;

    const RocketWrapper = isMobile ? "div" : React.forwardRef((props, ref) => <Plx ref={ref} parallaxData={parallaxData} {...props} />)

    const mobileLoadMore = () => {
        if (showBtn) {
            setDisplayedShedule((old) => {
                const rest = work_schedule.slice(mobileSheduleFirstSize);
                return [...old, ...rest]
            })
        }
    }


    return (
        <section className="timeline">
            <SecondLevelTitle title={title} />
            <div className="timeline__wrapper">
                <div className="timeline__items timeline__items--left">
                    {displayedShedule && displayedShedule.map((item, key) => {
                        return (
                            <div className="timeline__schedule" key={key}>
                                <div className="timeline__schedule__number">{item.number}</div>
                                <div className="timeline__schedule__wrapper">
                                    <div className="timeline__schedule__date">{item.date}</div>
                                    <div className="timeline__schedule__description">{item.description}</div>
                                </div>
                            </div>
                        )
                    })}
                    {isMobile && showBtn && <span className="timeline__items__btn" onClick={mobileLoadMore}>{button}</span>}
                </div>
                <div className="timeline__items timeline__items--right">
                    <RocketWrapper className='timeline__items__img timeline__items__img--rocket'>
                        <img src={rocket_launch.rocket.url} alt="rocket" ref={animationBlock} />
                    </RocketWrapper>
                    <div className='exhaust-fumes'></div>
                    {isMobile ? null : <Particles className="tsparticles" params={particles} />}
                </div>
            </div>
        </section>
    )
}

export default TimeLine
