import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { withRouter } from 'react-router';
import API from '../../../api';

import Loader from "../../Loader/Loader";
import Home from "../../Blog/Home";
import Post from "../../Blog/Categories/Posts/Post";


const BlogSwitcher = (props) => {
    const { slug } = props.match.params;
    const [loaded_status, setLoaded_status] = useState(false);
    const [categorySlug, setCategorySlug] = useState();
    const [authorSlug, setAuthorSlug] = useState();
    const [breadcrumbsTitle, setBreadcrumbsTitle] = useState('');

    const fetchSlugs = async () => {
        await axios
            .get(API.basePath + '/wp-json/wp/v2/pages?slug=blog')
            .then(response => {
                const categoriesValues = Object.values(response.data[0].acf.categories);
                setCategorySlug(categoriesValues.map((item) => {
                    return item.slug
                }));
            })
            .catch(error => {
                console.log(error);
            });
        await axios
            .get(API.basePath + '/wp-json/wp/v2/users/?per_page=100')
            .then((response) => {
                setAuthorSlug(response.data.map((item) => {
                    return `autoren/${item.slug}`
                }))
                setLoaded_status(true)
            })
            .catch((error) => {
                console.log(error);
            });
    }

    useEffect(() => {
        fetchSlugs()
        props.setContactElements();
    }, [])

    function populartagsBreadcrumbs(tag) {
        setBreadcrumbsTitle(`#${tag}`)
    }

    function authorBreadcrumbs(author) {
        setBreadcrumbsTitle(author)
    }

    function categoriesBreadcrumbs(categorie) {
        setBreadcrumbsTitle(categorie)
    }

    function onblogBreadcrumbs(tag) {
        setBreadcrumbsTitle(undefined)
    }

    function authorBreadcrumbsBlog(author) {
        setBreadcrumbsTitle(author)
    }


    if (!loaded_status) {
        return (
            <div className="blog-page-loader">
                <Loader />
            </div>
        );
    } else {
        const isCategory = categorySlug.filter(item => item === slug);
        const isAuthor = authorSlug.filter(item => item === props.history.location.pathname.slice(6));

        if (!slug || (slug && isCategory.length > 0) || (slug && isAuthor.length > 0) || (slug && ("autoren" === slug || "autoren/" === slug))) {
            document.getElementById("page").style.overflow = "hidden";
            return <Home setContactElements={props.setContactElements} populartagsBreadcrumbs={populartagsBreadcrumbs} authorBreadcrumbsBlog={authorBreadcrumbsBlog} categoriesBreadcrumbs={categoriesBreadcrumbs} onblogBreadcrumbs={onblogBreadcrumbs} breadcrumbsTitle={breadcrumbsTitle} />
        } else {
            document.getElementById("page").style.overflow = "visible";
            return <Post setContactElements={props.setContactElements} setHeaderOptions={props.setHeaderOptions} populartagsBreadcrumbs={populartagsBreadcrumbs} authorBreadcrumbs={authorBreadcrumbs} onblogBreadcrumbs={onblogBreadcrumbs} categoriesBreadcrumbs={categoriesBreadcrumbs} />
        }
    }

}

export default withRouter(BlogSwitcher);