import React, {Component, useState} from "react";
import { animateScroll as scroll } from 'react-scroll';

import "./Technologies.scss";

import Technology from "./Technology";
import Animation from "./Animation";

class Technologies extends Component {
    state = {
        technologies__data: [],
        technology_description_height: 0,
        active_technology_index: null,
        animationStatus: true
    };

    componentDidMount () {
        this.animationInterval = 0;
        this.delayInterval = 0;
        this.startAnimation ();
    }

    componentWillUnmount () {
        this.clearInterval ();
    }

    showDescription(key) {
        this.setState({
            active_technology_index:key
        });

        this.stopAnimation ();
    }

    showDescriptionMobile(key) {

        if(this.state.active_technology_index === key) {
            this.setState({
                active_technology_index:null,
                technology_description_height:0
            });
        } else {
            let height = document.getElementById('technologyDescription'+key).offsetHeight;
            this.setState({
                active_technology_index:key,
                technology_description_height:height
            });

            const boxPos = document.getElementsByClassName("technologies__description")[0].offsetTop;
            const itemHeight = document.getElementsByClassName('technologies__item')[0].offsetHeight;
            scroll.scrollTo(boxPos-itemHeight);
        }
    }

    hideDescription() {
        this.setState({
            active_technology_index:null
        });

        this.startAnimation ();
    }

    update () {
        this.delayInterval = setTimeout( () => {
            this.setState({
                animationStatus:true
            });
        }, 500);

    }

    startAnimation () {
        this.update();
        this.animationInterval = setTimeout( () => {
            this.stopAnimation ();
            this.startAnimation ();
        }, 5000);
    }

    stopAnimation () {
        this.setState({
            animationStatus:false
        });

        this.clearInterval ();
    }

    clearInterval () {
        clearInterval(this.animationInterval);
        clearInterval(this.delayInterval);
    }

    render() {

        let w = window.innerWidth;

        if(this.props.content) {

            const {
                title,
                title_bg,
                description_block_bg,
                scheme_img,
                technologies_list
            } = this.props.content;

            return (
                <>
                    <div className="technologies" id="technologies">

                        <div className="technologies__list">
                            <div className="technologies__title technologies__item">
                                <div className="technologies__title-bg" dangerouslySetInnerHTML={{__html: title_bg}}/>
                                <h2 className="technologies__title-text"  dangerouslySetInnerHTML={{__html: title}}/>
                            </div>
                            {
                                w < 992 ? (
                                    technologies_list.map((item, key) => {
                                        return (
                                            <div className={`technologies__item our-technology${this.state.active_technology_index === key + 1 ? ' technologies__item--active' : ''}`}
                                                 key={key}
                                                 onClick={() =>{this.showDescriptionMobile(key+1)}}>

                                                <Technology technology_data={item.technology} item={key} noLink={true} />
                                            </div>
                                        )
                                    })
                                ) : (
                                    technologies_list.map((item, key) => {
                                        return (
                                            <div className="technologies__item our-technology"
                                                 key={key}
                                                 onMouseEnter={() =>{this.showDescription(key+1)}}
                                                 onMouseLeave={this.hideDescription.bind(this)}>

                                                <Technology technology_data={item.technology} item={key}/>
                                            </div>
                                        )
                                    })
                                )
                            }
                        </div>
                        {
                            w < 992 ? (
                                <div className='technologies__description' style={{ height: this.state.technology_description_height }}>
                                    <div className="technologies__description-content">
                                        {
                                            technologies_list.map((item, key) => {
                                                return (
                                                    <div key={key}
                                                         id={`technologyDescription${key+1}`}
                                                         className={`technologies__description-item${this.state.active_technology_index === key+1 ? ' technologies__description-item--visible' : ''}`}>
                                                        <h2 className="technologies__description-title">{item.technology.title}</h2>
                                                        <div className="technologies__description-text" dangerouslySetInnerHTML={{__html: item.technology.description}}/>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            ) : ('')
                        }
                        <div className="technologies__detail-info">

                            {
                                w >= 992 ? (
                                    <div className={`technologies__description${(this.state.active_technology_index) ? ' technologies__description--opened':""}`}>
                                        <div className="technologies__description-bg" dangerouslySetInnerHTML={{__html: description_block_bg}}/>
                                        <div className="technologies__description-content">
                                            {
                                                technologies_list.map((item, key) => {
                                                    return (
                                                        <div key={key} className={`technologies__description-item ${this.state.active_technology_index === key+1 ? 'active' : ""}`}>
                                                            <h3 className="technologies__description-title">{item.technology.title}</h3>
                                                            <div className="technologies__description-text" dangerouslySetInnerHTML={{__html: item.technology.description}}/>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                ) : ('')
                            }

                            <div className="technologies__scheme">
                                <div className={`technologies__scheme-inner${(this.state.active_technology_index) ? ' technologies__support-inner--moved-right':""}`}>
                                    <div className="technologies__scheme-img">
                                        <Animation playStatus={this.state.animationStatus}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            );
        } else {
            return (
                <></>
            );
        }
    }
}

export default Technologies;