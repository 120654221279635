import React, { useEffect, useState } from 'react';

import axios from 'axios';

import { withRouter } from 'react-router';

import API from '../../../api';

import Categories from '../Categories/Categories'

import PageMeta from '../../PageMeta/PageMeta'

import Loader from "../../Loader/Loader";

import "./Blog.scss"

const Blog = (props) => {
    const { slug } = props.match.params;
    const { populartagsBreadcrumbs, authorBreadcrumbsBlog, categoriesBreadcrumbs, onblogBreadcrumbs, breadcrumbsTitle } = props

    const [page_data, setPage_data] = useState({});
    const [yoast_meta, setYoast_meta] = useState([]);
    const [loaded_status, setLoaded_status] = useState(false);
    const [authors, setAuthors] = useState([]);
    const [allCategories, setAllCategories] = useState([]);

    const [currentBreadcrumb, setCurrentBreadcrumb] = useState('');

    const handleCurrentBreadcrumb = (slug, itemsToFind, pageData ) => {

        const currentPage = itemsToFind.filter(({slug:categorySlug}) => categorySlug === slug);
        if(currentPage.length){
            let breadcrumbName = currentPage[0].name;
            const isAuthorPage = Boolean(currentPage[0].avatar_urls)
            if (pageData.description && pageData.description.authors_title && isAuthorPage) {
                breadcrumbName = `${pageData.description.authors_title} <br /> ${breadcrumbName}`
            }
            setCurrentBreadcrumb(breadcrumbName);
        }

    }

    const fetchSlugs = async () => {
        try{
            const blogPageResponse = await axios.get(API.basePath + '/wp-json/wp/v2/pages?slug=blog')
            setPage_data(blogPageResponse.data[0].acf);
            setYoastData(blogPageResponse.data[0]);

            const blogUsersResponse = await axios.get(API.basePath + '/wp-json/wp/v2/users/?per_page=100')
            setAuthors(blogUsersResponse.data)

            const categoriesResult = await axios.get(API.basePath + "/wp-json/wp/v2/categories?slug=");
            setAllCategories(categoriesResult.data)
            handleCurrentBreadcrumb(slug, [...blogUsersResponse.data, ...categoriesResult.data], blogPageResponse.data[0].acf);

            setLoaded_status(true)
        }catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        handleCurrentBreadcrumb(slug, [...allCategories, ...authors], page_data)
    },[slug, allCategories, authors, page_data])

    useEffect(() => {
        fetchSlugs()
    }, [])

    const setYoastData = (data) => {
        setYoast_meta(data);
    }

    if (!loaded_status) {
        return (
            <div className="blog-page-loader">
                <Loader />
            </div>
        );
    } else {
        return (
            <>
                <PageMeta metaData={yoast_meta} />
                <div className="blog-page">
                    <Categories
                        setContactElements={props.setContactElements}
                        content={page_data}
                        activeCategorySlug={slug}
                        activeAuthorSlug={slug}
                        setYoastData={setYoastData}
                        authors={authors}
                        currentBreadcrumb={currentBreadcrumb}
                        categories={page_data.categories}
                        populartagsBreadcrumbs={populartagsBreadcrumbs}
                        authorBreadcrumbsBlog={authorBreadcrumbsBlog}
                        categoriesBreadcrumbs={categoriesBreadcrumbs}
                        onblogBreadcrumbs={onblogBreadcrumbs}
                        allCategories={allCategories}
                        breadcrumbsTitle={breadcrumbsTitle}
                    />
                </div>

            </>
        )
    }

}

export default withRouter(Blog);