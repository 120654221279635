import React from 'react';
import useMobile from "../../../../hooks/useMobile";
import Swiper from "react-id-swiper";
import './DesignAnimation.scss';

const Design = ({content = {}}) => {
    const isMobile = useMobile();

    const params = {
        slidesPerView: 'auto',
        speed: 400,
        autoplay: true,
        loop: true,
    }

    return (
        <section className='design anim-item'>
            <div className='number design__number'>2</div>
            <div className='e-commerce-page__title design__title'>
                <h2 className='e-commerce-page__title-text' dangerouslySetInnerHTML={{ __html: content.title?.text }} />
                <div className='e-commerce-page__title-bg' dangerouslySetInnerHTML={{ __html: content.title?.background_text }} />
            </div>
            <div className='design__content'>
                <div className="design__content--text-wrapper">
                    {content.text?.map(block => {
                        return (
                            <div
                                className="design__content--text-content"
                                dangerouslySetInnerHTML={{__html: block.text_block}}
                                key={block.text_block}
                            />
                        )
                    })}
                </div>
                {
                    isMobile ?
                        <div className="slider-holder">
                            <img className="e-commerce-page__image frame" src={content.slider_frame.url} alt={content.slider_frame.alt} />
                            <Swiper {...params}>
                                {
                                    content.steps.map((item, key) => {
                                        return (
                                            <img
                                                src={item.url}
                                                alt={item.alt}
                                                key={key}
                                            />
                                        )
                                    })
                                }
                            </Swiper>
                        </div>
                        :
                        <div className="design__phoneHolder">
                            <img className="design__frame" src={content.slider_frame.url} alt={content.slider_frame.alt} />
                            <div className="design__screensHolder">
                                {
                                    content.steps.map((item, key) => {
                                        return (
                                            <img
                                                className={`design__screensHolder_item design__screensHolder_item--${key + 1}`}
                                                src={item.url}
                                                alt={item.alt}
                                                key={key}
                                            />
                                        )
                                    })
                                }
                            </div>
                        </div>
                }
            </div>
        </section>
    )
}

export default Design;