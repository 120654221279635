import React, { Component } from 'react'

import MainBanner from '../../MainBanner/MainBanner';
import Perfectes from '../../WebDesign/Perfectes/Perfectes'
import UxDesign from '../../WebDesign/UxDesign/UxDesign'
import Services from '../../WebDesign/Services/Services'
import MobileFirst from '../../WebDesign/MobileFirst/MobileFirst'
import DesignProcess from '../../WebDesign/DesignProcess/DesignProcess'
import Products from '../../WebDesign/Products/Products'

import './WebDesign.scss'

import axios from 'axios'

import API from '../../../api'
import Loader from '../../Loader/Loader'
import PageMeta from '../../PageMeta/PageMeta'
import CustomSchema from "../../CustomSchema/CustomSchema";

import FAQBlock from '../../FAQBlock/FAQBlock'

class WebDesign extends Component {
    state = {
        page_data: [],
        yoast_meta: [],
        loaded_status: false,
        web_design_perfectes: []
    }

    componentDidMount() {
        // Get company menu items
        axios
            .get(API.basePath + '/wp-json/wp/v2/pages?slug=webdesign')
            .then((response) => {
                this.setState({
                    page_data: response.data[0].acf,
                    yoast_meta: response.data[0],
                    loaded_status: true
                })
            })
            .catch((error) => {
                console.log(error)
            })
    }

    render() {
        if (!this.state.loaded_status) {
            return (
                <div className="loading">
                    <Loader />
                </div>
            )
        }

        return (
            <>
                <PageMeta metaData={this.state.yoast_meta} hreflangs={this.state.page_data['hreflang_list'] || null} />
                <CustomSchema schema={this.state.page_data.schema} />
                <div className="web-design-page">
                    <MainBanner content={this.state.page_data.main_banner_content} id='webDesign' />
                    <Perfectes
                        content={this.state.page_data.web_design_perfectes}
                    />
                    <UxDesign
                        content={this.state.page_data.web_design_uxdesign}
                    />
                    <Services
                        content={this.state.page_data.web_design_services}
                    />
                    <MobileFirst
                        content={this.state.page_data.web_design_mobile_first}
                    />
                    <DesignProcess
                        content={this.state.page_data.web_design_design_process}
                    />
                    <Products
                        content={this.state.page_data.web_design_products}
                    />
                    <FAQBlock 
                        content={this.state.page_data.FAQ_list || null}
                    />
                </div>
            </>
        )
    }
}

export default WebDesign
