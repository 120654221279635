import React, {Component} from "react";

import Loader from "../../../Loader/Loader";
import "./SplitScreen.scss";

class SplitScreen extends Component {

    state = {
        slider_data: [],
        transform: "none",
        width: 50,
        transition: false
    };

    mouseMove(e) {
        let w, h;
        w = window.innerWidth;
        h = window.innerHeight;

        if (w > 992) {
            document.getElementById("scrollPosition").classList.remove("transition");

            let x, y, rotateX, moveY,
                boxPos = document.getElementById("scrollPosition").offsetTop;

            if (e.pageX || e.pageY) {
                x = e.pageX;
                y = e.pageY;
            } else if (e.clientX || e.clientY) {
                x = e.clientX + document.body.scrollLeft + document.documentElement.scrollLeft;
                y = e.clientY + document.body.scrollTop + document.documentElement.scrollTop;
            }

            y = -(boxPos - y);

            rotateX = ((w / 2 - x) / 1000) / 4;
            moveY = (y - h / 2) / 25;

            let movePercent = (((w / 2 - x) * 100) / w) / 3;

            this.setState({
                transform: "matrix3d(" + Math.cos(rotateX) + ", 0, " + Math.sin(rotateX) + ", 0, 0, 1, 0, 0, " + Math.sin(rotateX) + ", 0, " + Math.cos(rotateX) + ", 0 , 0, " + moveY + ", 0, 1)",
                width: 50 + movePercent,
            });

            if(this.state.transition) {
                this.setState({
                    transition: !this.state.transition,
                });
            }
        }
    }

    mouseLive() {
        this.setState({
            transform: "none",
            width: 50,
            transition: !this.state.transition,
        });
    }

    render() {

        if (this.props.content) {

            const left_side = this.props.content.left_side;
            const right_side = this.props.content.right_side;

            return (
                <>

                    <div className={`split-screen${this.state.transition ? ' transition':""}`} onMouseMove={this.mouseMove.bind(this)}
                         onMouseLeave={this.mouseLive.bind(this)}>
                        <div className="split-screen__bg-elements">
                            <div className="split-screen__wrapper split-screen__wrapper--before" id="split-screenBefore" style={{width:this.state.width+"%"}}>
                                <div className="split-screen__content">
                                    <div className="split-screen__top-bg"></div>
                                    <div className="split-screen__bg-left-gears"></div>
                                    <div className="split-screen__image loading-block">
                                        <img src={left_side.poster.url} id="imgBefore" alt="" style={{transform:this.state.transform}}/>
                                    </div>
                                </div>
                            </div>
                            <div className="split-screen__wrapper split-screen__wrapper--after">
                                <div className="split-screen__content split-screen__content--rotated-bg">
                                    <div className="split-screen__bg-right-gears"></div>
                                    <div className="split-screen__image loading-block">
                                        <img src={right_side.poster.url} id="imgAfter" alt="" style={{transform:this.state.transform}}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="split-screen__container">
                            <div className="split-screen__item split-screen__item--left-col loading-block loading-block--2">
                                <div className="split-screen__item-body">
                                    <h2 className="split-screen__item-title">{left_side.title}</h2>
                                    <div className="split-screen__item-description" dangerouslySetInnerHTML={{__html: left_side.description}} />
                                </div>
                            </div>
                            <div className="split-screen__item split-screen__item--right-col loading-block loading-block--2">
                                {
                                    this.props.content.mobile_image ? (
                                        <div className="split-screen__item-image">
                                            <img src={this.props.content.mobile_image.url} alt=""/>
                                        </div>
                                    ) : ('')
                                }
                                <div className="split-screen__item-body">
                                    <h2 className="split-screen__item-title">{right_side.title}</h2>
                                    <div className="split-screen__item-description" dangerouslySetInnerHTML={{__html: right_side.description}} />
                                </div>
                                <div className="split-screen__bottom-bg"></div>
                            </div>
                        </div>
                    </div>
                </>
            );

        } else {
            return (
                <>
                    <div className='split-screen'>
                        <div className="split-screen__bg-elements split-screen">
                            <div className="split-screen__wrapper split-screen__wrapper--before" id="split-screenBefore" style={{width:this.state.width+"%"}}>
                                <div className="split-screen__content">
                                </div>
                            </div>
                            <div className="split-screen__wrapper split-screen__wrapper--after">
                                <div className="split-screen__content split-screen__content--rotated-bg">
                                </div>
                            </div>
                        </div>
                        <div className="split-screen__container">
                            <Loader />
                        </div>
                    </div>
                </>
            );
        }
    }
}

export default SplitScreen;