import React from 'react';
import { Link } from 'react-router-dom';
import useMobile from "../../../hooks/useMobile";
import { Html5Entities } from 'html-entities';
const htmlEntities = new Html5Entities();

const CategoriesList = ({ categories, scrollOnChangeCategory, categoriesBreadcrumbs, titlingBlog }) => {
    const isMobile = useMobile();

    return (
        <div className="categories__items">
            {Object.values(categories).map((category) => {
                const id = category.term_id || category.id
                if (id !== 1) {
                    return (
                        <div className="categories__item" key={`list_item_${id}`}>
                            <Link to={`/blog/${category.slug}`}>
                                <span className="categories__item--title"
                                    onClick={() => { scrollOnChangeCategory(); titlingBlog(category.name, category.description); categoriesBreadcrumbs(category.name, category.count) }}>{htmlEntities.decode(category.name)}</span>
                            </Link>
                        </div>
                    );
                }
            })}
        </div>
    )
}

export default CategoriesList
