import React from "react";
import "./Loader.scss";

function Loader(props) {
    return (
        <div className="data-preloader" /* {props.buttonClicked === 'clicked' ? "data-preloader button-clicked" : "data-preloader"} */>
            <div className="data-preloader__el sk-cube-grid">
                <div className="sk-cube sk-cube1"></div>
                <div className="sk-cube sk-cube2"></div>
                <div className="sk-cube sk-cube3"></div>
                <div className="sk-cube sk-cube4"></div>
                <div className="sk-cube sk-cube5"></div>
                <div className="sk-cube sk-cube6"></div>
                <div className="sk-cube sk-cube7"></div>
                <div className="sk-cube sk-cube8"></div>
                <div className="sk-cube sk-cube9"></div>
            </div>
        </div>
    );
}

export default Loader;