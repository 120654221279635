import React from 'react';

import './HostingAnimation.scss';

const Hosting = (props) => {
    const content = props.content;

    return (
        <section className='hosting anim-item'>
            <div className='number hosting__number'>7</div>
            <div className="hosting__leftLaptop e-commerce-page__image">
                <img src={content.desktop_image.url} alt={content.desktop_image.alt} />
            </div>

            <div className='hosting__textHolder'>
                <div className='e-commerce-page__title hosting__title'>
                    <h2 className='e-commerce-page__title-text' dangerouslySetInnerHTML={{ __html: content.title.text }} />
                    <div className='e-commerce-page__title-bg' dangerouslySetInnerHTML={{ __html: content.title.background_text }} />
                </div>
                <div className="e-commerce-page__text" dangerouslySetInnerHTML={{ __html: content.text }} />
                <div className='hosting__mobileImage e-commerce-page__image'>
                    <img src={content.mobile_image.url} alt={content.mobile_image.alt} />
                </div>
            </div>

            <div className="hosting__rightLaptop e-commerce-page__image-laptop">
                <img src={content.error_laptop.frame.url} alt={content.error_laptop.frame.alt} />
                <div className="animation">
                    <div className="animation__text">{content.error_laptop.title}</div>
                    <div className="animation__image" dangerouslySetInnerHTML={{ __html: content.error_laptop.animation }} />
                </div>
            </div>
        </section>
    )
};

export default Hosting;
