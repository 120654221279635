import React, {Component} from "react";

import Banner from './Banner/Banner';
import Loader from "../../../components/Loader/Loader";

import "./Partner.scss";

import PageMeta from "../../PageMeta/PageMeta";
import CustomSchema from "../../CustomSchema/CustomSchema";

import axios from "axios";
import API from "../../../api";

class Partner extends Component {

    state = {
        page_data: [],
        yoast_meta: [],
        loaded_status: false
    };

    componentDidMount() {
        // Get company menu items
        axios.get(API.basePath+'/wp-json/wp/v2/pages?slug=partner')
            .then(response => {
                this.setState({
                    page_data: response.data[0].acf,
                    yoast_meta: response.data[0],
                    loaded_status: true
                });
            })
            .catch(error => {
                console.log(error);
            });
        this.props.setContactElements();
    }


    render() {

        if (this.state.loaded_status) {

            const page_data = this.state.page_data.partners_page_content;

            return (
                <>
                    <PageMeta metaData={this.state.yoast_meta || null} hreflangs={this.state.page_data['hreflang_list'] || null} />
                    <CustomSchema schema={this.state.page_data.schema} />
                    <div className="partner-page">
                        <Banner content={this.state.page_data.main_banner_content}  />
                        <div className="partner-page__content" id="scrollPosition">
                            <div className="partner-page__top-bg"></div>
                            <div className="partner-page__bottom-bg"></div>

                            <div className="partner-page__title">
                                <h1 className="partner-page__title-text">{page_data.page_title}</h1>
                                <div className="partner-page__title-bg">
                                    <span>{page_data.page_title_bg}</span>
                                </div>
                            </div>

                            <div className="partner-page__list">
                                {
                                    page_data.partners_list.map((item, key) => {
                                        return (
                                            <div className="partner-page__item" key={key}>
                                                <div className="partner-page__item-logo">
                                                    {
                                                        item.partner.link ? (
                                                            <a href={item.partner.link} target="_blank" rel={item.partner.isnofollowlink ? "nofollow noopener" : "noopener"}>
                                                                <img src={item.partner.logo.url} alt={item.partner.title} />
                                                            </a>
                                                        ) : (
                                                            <>
                                                                <img src={item.partner.logo.url} alt={item.partner.title} />
                                                            </>
                                                        )
                                                    }
                                                </div>
                                                <div className="partner-page__item-text-container">
                                                    <h2 className="partner-page__item-title">
                                                        {
                                                            item.partner.link ? (
                                                                <a href={item.partner.link} target="_blank" rel={item.partner.isnofollowlink ? "nofollow noopener" : "noopener"}>
                                                                    {item.partner.title}
                                                                </a>
                                                            ) : (
                                                            item.partner.title
                                                            )
                                                        }
                                                    </h2>
                                                    <div className="partner-page__item-description" dangerouslySetInnerHTML={{ __html: item.partner.description}} />
                                                    {item.partner.custom_links.length && (
                                                        item.partner.custom_links.map((link, key) => {
                                                            return (
                                                                <a className="partner-page__item-button" target="_blank" rel="nofollow noopener" href={link.custom_link.url} key={`partners-link--${key}`}>
                                                                    <span className="base-btn">{link.custom_link.title}</span>
                                                                </a>
                                                            )
                                                        }))
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </>
            );

        } else {
            return (
                <>
                    <PageMeta metaData={this.state.yoast_meta || null} />
                    <div className="partner-page partner-page--preloader">
                        <div className="partner-page__content">
                            <div className="partner-page__top-bg"></div>
                            <div className="partner-page__bottom-bg"></div>
                        </div>
                        <Loader />
                    </div>
                </>
            );
        }
    }
}

export default Partner;
