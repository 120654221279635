import React, { useContext } from 'react';
import ThemeGeneralSettingsContext from '../../../../ThemeGeneralSettingsContext';
import Particles from 'react-particles-js';

const Systems = (props) => {
    const content = props.content;
    const { certificates } = useContext(ThemeGeneralSettingsContext);
    const { shopware_developer, shopware_designer } = certificates;

    const particles = {
        particles: {
            number: { value: 15, },
            color: { value: '#3E3E53' },
            opacity: {
                value: 0.5,
                anim: { enable: true }
            },
            size: {
                value: 12,
                random: true,
                anim: {
                    enable: true,
                    speed: 3
                }
            },
            line_linked: {
                enable: false
            },
            move: {
                speed: 1
            }
        }
    }

    return (
        <section className='systems anim-item'>
            <div className='number systems__number'>4</div>
                <div className='systems__textHolder'>
                    <div className='e-commerce-page__title systems__title'>
                        <h2 className='e-commerce-page__title-text' dangerouslySetInnerHTML={{ __html: content.title.text }} />
                        <div className='e-commerce-page__title-bg' dangerouslySetInnerHTML={{ __html: content.title.background_text }} />
                    </div>
                    <div className="e-commerce-page__text" dangerouslySetInnerHTML={{ __html: content.text }} />
                    <img className="e-commerce-page__certificate" src={shopware_developer.url} alt={shopware_developer.alt} />
                    <img className="e-commerce-page__certificate" src={shopware_designer.url} alt={shopware_designer.alt} />
                </div>
                <div className='systems__image e-commerce-page__image technologies-image fadeInLeft'>
                    <Particles className="tsparticles" params={particles} style={{ width: '100%' }} />
                    {
                        content.gears.map((item, key) => {
                            return (
                                <div className={`gear gear--${key+1}`} key={key}>
                                    <img className="gear__image" src={item.gear.url} alt={item.gear.alt} />
                                    <img className="gear__text"  src={item.text.url} alt={item.text.alt} />
                                </div>
                            )
                        })
                    }
                </div>
        </section>
    )
};

export default Systems;
