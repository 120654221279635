import React, { useEffect, useState, useRef, useCallback } from 'react'

import SecondLevelTitle from '../SecondLevelTitle/SecondLevelTitle';

import Particles from 'react-particles-js'

import Text from '../Text/Text'

import Gear from './Gear/Gear';

import MockupDevices from '../MockupDevices/MockupDevices';

import Swiper from 'react-id-swiper';

import useMobile from "../../../hooks/useMobile";

import TechnologyContent from '../TechnologyContent/TechnologyContent';

import { LazyLoadImage } from 'react-lazy-load-image-component';

import { ReactComponent as Wire } from '../images/Page-On-Screen/alkima-erfolgsgeschichte-bw-online-shop-hinweis-linie.svg';

import 'swiper/swiper.scss'

import './PageOnScreen.scss';

const PageOnScreen = (props) => {

    const { title, text, technology, reverse_proxi, page_screen, message } = props.content;

    const { canAnimate } = props;

    const isMobile = useMobile();

    const [activeIndex, setActiveIndex] = useState(0);
    const [imageAreReady, setImageAreReady] = useState(false);



    const [isAnimationGear1, setIsAnimationGear1] = useState(false);

    const [isAnimationGear2, setIsAnimationGear2] = useState(false);

    const [hoverClassName, setHoverClassName] = useState(null);

    const gear1Block = useRef(null);

    const gear2Block = useRef(null);

    const scrollElement = document.querySelector(".screen-page__items-magazin");

    const handleScroll = useCallback(
        () => {

            const gear1 = gear1Block.current.getBoundingClientRect();
            const gear2 = gear2Block.current.getBoundingClientRect();

            if (canAnimate(gear1)) {
                setIsAnimationGear1(true)
            }

            if (canAnimate(gear2)) {
                setIsAnimationGear2(true)
            }
        },
        [canAnimate],
    )


    useEffect(() => {
        technology && window.addEventListener("scroll", handleScroll);

        return () => {
            technology && window.removeEventListener("scroll", handleScroll);
        }
    }, [handleScroll]);

    const mockup = {
        laptop: require("../images/Page-On-Screen/alkima-erfolgsgeschichte-bw-online-shop-laptop-mockup.svg").default,
        tablet: require("../images/Page-On-Screen/alkima-erfolgsgeschichte-bw-online-shop-tablet-mockup.svg").default,
        phone: require("../images/Page-On-Screen/alkima-erfolgsgeschichte-bw-online-shop-phone-mockup.svg").default,
        desktop: require("../images/Page-On-Screen/desktop.svg").default,
        desktopForDesktop: require("../images/Page-On-Screen/alkima-erfolgsgeschichte-bw-online-shop-desktop-mockup.svg").default
    }

    const particles = {

        particles: {
            number: { value: 15, },
            color: { value: '#3E3E53' },
            opacity: {
                value: 0.5,
                anim: { enable: true }
            },
            size: {
                value: 12,
                random: true,
                anim: {
                    enable: true,
                    speed: 3
                }
            },
            line_linked: {
                enable: false
            },
            move: {
                speed: 1
            }
        }
    }


    const activeMenu = (idx) => {
        setActiveIndex(idx);
    }

    const onMockup = (className) => {
        if (window.innerWidth > 992) {
            setHoverClassName(className)
        }
    }
    const onMockupLeave = () => {
        if (window.innerWidth > 992) {
            setHoverClassName(null)
        }
    }
    const ref = useRef(null);
    const params = {
        slidesPerView: 'auto',
        spaceBetween: 0,

        on: {
            imagesReady: function () {

                setImageAreReady(true);
            }
        }
    }

    const OnTabChange = (idx) => {
        setImageAreReady(false);
        activeMenu(idx)
    }

    const MockupWrapper = isMobile ? React.forwardRef((props, ref) => <Swiper ref={ref} {...params} {...props} />) : "div"

    return (
        <section className="screen-page">

            {!isMobile &&
                <>
                    <SecondLevelTitle title={title} />

                    <div className="screen-page__items screen-page__items--top">
                        <div className="screen-page__items-text">
                            <Text text={text} />
                        </div>
                        <ul className="screen-page__items-menu">
                            {page_screen && page_screen.map((item, idx) => {
                                return <li className="screen-page__items-menu-item" key={idx}>
                                    <span className={`screen-page__items__button ${idx === activeIndex ? "screen-page__items__button--active" : ""}`} onClick={() => OnTabChange(idx)}>{item.menu_name}</span>
                                </li>
                            })}
                        </ul>
                    </div>
                    <MockupWrapper className="screen-page__items screen-page__items__mockup" ref={ref}  >
                        <div className={`mockup__wrapper mockup__wrapper--1 ${hoverClassName ? hoverClassName : ""}`}
                            onMouseEnter={() => onMockup("laptop")}
                            onMouseLeave={onMockupLeave}
                        >
                            <MockupDevices
                                mobileImg={mockup["laptop"]}
                                mockupImg={mockup["laptop"]}
                                mockupImgSizes={{width: 1011, height: 548}}
                                alt={"mockup"}
                                contentScreen={page_screen[activeIndex].image_laptop.url}
                                contentScreenSizes={{width: page_screen[activeIndex].image_laptop.width, height: page_screen[activeIndex].image_laptop.height}}
                                device={"laptop"}
                                noScroll
                                imageAreReady={imageAreReady}
                            />
                            <div className="mobile-first__message">
                                <div className="mobile-first__message--text">
                                    <div>{message}</div>
                                </div>
                                <div className="mobile-first__message--wire">
                                    <Wire />
                                </div>
                            </div>
                        </div>
                        <div className={`mockup__wrapper mockup__wrapper--2 ${hoverClassName ? hoverClassName : ""}`}
                            onMouseEnter={() => onMockup("desktop")}
                            onMouseLeave={onMockupLeave}
                        >
                            <MockupDevices
                                mobileImg={mockup["laptop"]}
                                mockupImg={mockup[`${window.innerWidth < 992 ? "desktop" : "desktopForDesktop"}`]}
                                mockupImgSizes={{width: 1650, height: 1141}}
                                alt={"mockup"}
                                contentScreen={page_screen[activeIndex].image_laptop.url}
                                contentScreenSizes={{width: page_screen[activeIndex].image_laptop.width, height: page_screen[activeIndex].image_laptop.height}}
                                device={"desktop"}
                                noScroll
                                imageAreReady={imageAreReady}
                            />
                            <div className="mobile-first__message">
                                <div className="mobile-first__message--text">
                                    <div>{message}</div>
                                </div>
                                <div className="mobile-first__message--wire">
                                    <Wire />
                                </div>
                            </div>
                        </div>
                        <div className={`mockup__wrapper mockup__wrapper--3 ${hoverClassName ? hoverClassName : ""}`}
                            onMouseEnter={() => onMockup("tablet")}
                            onMouseLeave={onMockupLeave}
                        >
                            <MockupDevices
                                mobileImg={mockup["laptop"]}
                                mockupImg={mockup["tablet"]}
                                mockupImgSizes={{width: 466, height: 653}}
                                alt={"mockup"}
                                contentScreen={page_screen[activeIndex].image_tablet.url}
                                contentScreenSizes={{width: page_screen[activeIndex].image_tablet.width, height: page_screen[activeIndex].image_tablet.height}}
                                device={"tablet"}
                                noScroll
                                imageAreReady={imageAreReady}
                            />

                            <div className="mobile-first__message">
                                <div className="mobile-first__message--text">
                                    <div>{message}</div>
                                </div>
                                <div className="mobile-first__message--wire">
                                    <Wire />
                                </div>
                            </div>
                        </div>
                        <div className={`mockup__wrapper mockup__wrapper--4 ${hoverClassName ? hoverClassName : ""}`}
                            onMouseEnter={() => onMockup("phone")}
                            onMouseLeave={onMockupLeave}
                        >
                            <MockupDevices
                                mobileImg={mockup["laptop"]}
                                mockupImg={mockup["phone"]}
                                mockupImgSizes={{width: 191, height: 373}}
                                alt={"mockup"}
                                contentScreen={page_screen[activeIndex].image_mobile.url}
                                contentScreenSizes={{width: page_screen[activeIndex].image_mobile.width, height: page_screen[activeIndex].image_mobile.height}}
                                device={"phone"}
                                noScroll
                                imageAreReady={imageAreReady}
                            />
                            <div className="mobile-first__message">
                                <div className="mobile-first__message--text">
                                    <div>{message}</div>
                                </div>
                                <div className="mobile-first__message--wire">
                                    <Wire />
                                </div>
                            </div>
                        </div>
                    </MockupWrapper>
                </>
            }

            {technology &&
                <div className="screen-page__items">
                    <div className={`screen-page__items-content screen-page__items-content-img ${isAnimationGear1 ? 'screen-page__items-content-img-animation' : ' '}`} ref={gear1Block}>
                        <Particles className="tsparticles" params={particles} style={{ width: '100%' }} />
                        <Gear gears={technology.images} />
                    </div>
                    <div className="screen-page__items-content right order">
                        <TechnologyContent title={technology.content.first_block.title} text={technology.content.first_block.text} />
                    </div>
                    <div className="screen-page__items-content">
                        <TechnologyContent title={technology.content.second_block.title} text={technology.content.second_block.text} />
                    </div>
                    <div className={`screen-page__items-content secondGearWrapper screen-page__items-content-img right ${isAnimationGear2 ? 'screen-page__items-content-img-animation' : ' '}`} ref={gear2Block}>
                        <Particles className="tsparticles" params={particles} style={{ width: '100%' }} />
                        <Gear gears={technology.images} />
                    </div>
                </div>
            }
            {reverse_proxi &&
                <div className="screen-page__items screen-page__items-magazin">
                    <div className="scrolling-phone">
                        <div className="scrolling-inner">
                            <LazyLoadImage
                                className="journal"
                                src={reverse_proxi.magazine.url}
                                alt={reverse_proxi.magazine.name}
                                width={reverse_proxi.magazine.width}
                                height={reverse_proxi.magazine.height}
                            />

                            {isMobile
                                ?
                                <img
                                    className="screen-page__items__img"
                                    src={reverse_proxi.phone.mobile_phone.url}
                                    alt="phone"
                                    width={reverse_proxi.phone.mobile_phone.width}
                                    height={reverse_proxi.phone.mobile_phone.height}
                                />
                                :
                                <>
                                    <MockupDevices
                                        mobileImg={reverse_proxi.phone.mobile_phone.url}
                                        alt={reverse_proxi.phone.mobile_phone.name}
                                        mockupImg={reverse_proxi.phone.mockup.url}
                                        mockupImgSizes={{width: reverse_proxi.phone.mockup.width, height: reverse_proxi.phone.mockup.height}}
                                        contentScreen={reverse_proxi.phone.content_screen.url}
                                        contentScreenSizes={{width: reverse_proxi.phone.content_screen.width, height: reverse_proxi.phone.content_screen.height}}
                                        scrollElement={scrollElement}
                                    />
                                    <div className="mobile-first__message">
                                        <div className="mobile-first__message--text">
                                            <div>{message}</div>
                                        </div>
                                        <div className="mobile-first__message--wire">
                                            <Wire />
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                    <div className="screen-page__items-content order">
                        <SecondLevelTitle title={technology.content.third_block.title} />
                        <Text text={technology.content.third_block.text} />
                    </div>
                </div>
            }

        </section>
    )
}
export default PageOnScreen;
