import React, { useState, useEffect } from 'react';
import './Archive.scss';
import axios from 'axios';
import API from '../../../../api';
import Loader from "../../../Loader/Loader";
import PageMeta from "../../../PageMeta/PageMeta";
import CustomSchema from "../../../CustomSchema/CustomSchema";
import { Link } from 'react-router-dom';
import useMobile from "../../../../hooks/useMobile";

const Archive = (props) => {
    const [pageData, setPageData] = useState([]);
    const [yoastMeta, setYoastMeta] = useState([]);
    const [loadedStatus, setLoadedStatus] = useState(false);
    const [page, setPage] = useState(1);
    const [posts, setPosts] = useState([]);
    const [activeLetter, setActiveLetter] = useState('');
    const isMobile = useMobile();

    const fetchData = async (page) => {
        try {
            const pageData = await axios.get(API.basePath + '/wp-json/wp/v2/pages?slug=glossar')
            setPageData(pageData.data[0]);
            setYoastMeta(pageData.data[0]);

            const fetchPosts = async () => {
                const archiveData = await axios.get(API.basePath + `/wp-json/wp/v2/glossar?per_page=100&page=${page}&orderby=title&order=asc`)
                setPosts([...posts, ...archiveData.data])

                const totalPages = archiveData.headers['x-wp-totalpages']

                if (archiveData.data.length > 0 && page < totalPages) {
                    setPage(page => page + 1)
                } else {
                    setLoadedStatus(true)
                }
            };

            await fetchPosts();
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        fetchData(page)
        props.setContactElements()
    }, [page]);

    // group posts
    const groupedPosts = posts.reduce((acc, post) => {
        let firstLetter = post.title.rendered[0]

        if (isNaN(firstLetter)) {
            if (!['Ä', 'Ö', 'Ü'].includes(firstLetter)) {
                firstLetter = firstLetter.toUpperCase();
            } else if (firstLetter === 'Ä') {
                firstLetter = 'A'
            } else if (firstLetter === 'Ö') {
                firstLetter = 'O'
            } else {
                firstLetter = 'U'
            }
        } else {
            firstLetter = '0-9'
        }

        if (!acc[firstLetter]) {
            acc[firstLetter] = []
        }
        acc[firstLetter].push(post)

        return acc
    }, {})

    let groupedPostsArray = Object.entries(groupedPosts)
        .map(([letter, posts]) => {
            return {
                letter: letter.toUpperCase(),
                posts: Object.values(posts)
            }
        })
        .sort(function (current, next) {

            // console.log(current.letter, next.letter)
            if (current.letter === '0-9') {
                return 1
            } else if (next.letter === '0-9') {
                return -1
            } else if (current.letter < next.letter) {
                return -1;
            } else {
                return 1;
            }
        })

    const filteredPosts = groupedPostsArray.filter(group => {
        if (!activeLetter) {
            return true
        } else {
            return group.letter === activeLetter
        }
    })

    // alphabet
    const alphabet = [...Array.from({length: 26}, (_, i) => String.fromCharCode(i + 65)), '0-9'];

    const availableLetters = groupedPostsArray.map(item => {
        return item.letter
    })
    
    if (loadedStatus) {
        const { banner, info } = pageData.acf

        const breadcrumbsStructuredData = {
            "@context": "https://schema.org/",
            "@type": "BreadcrumbList",
            "@id": `${window.origin}/glossar#breadcrumb`,
            "itemListElement": [
                {
                    "@type": "ListItem",
                    "position": 1,
                    "item": {
                        "@id": window.origin,
                        "name": "Startseite",
                        "@type": "WebPage"
                    } 
                },
                {
                    "@type": "ListItem",
                    "position": 2,
                    "item": {
                        "@id": `${window.origin}/glossar`,
                        "name": banner.title,
                        "@type": "WebPage"
                    } 
                }
            ]
        }

        return (
            <>
                <PageMeta metaData={yoastMeta || null} hreflangs={pageData.acf['hreflang_list'] || null} />
                <CustomSchema schema={pageData.acf.schema} />
                <script className='structured-data-list' type="application/ld+json">
                    {JSON.stringify(breadcrumbsStructuredData)}
                </script>

                <div className="glossary-archive">
                    <section className="glossary-banner">
                        <div className="glossary-banner__text-container">
                            <div className="glossary-banner__title">
                                <h1>{banner.title}</h1>
                                <span className="glossary-banner__title--bg">
                                    {banner.title}
                                </span>
                            </div>
                            <p dangerouslySetInnerHTML={{ __html: banner.text }} />
                        </div>

                        {isMobile ? (
                            <select
                                value={activeLetter}
                                onChange={(e) => {
                                    if (e.target.value !== 'all') {
                                        setActiveLetter(e.target.value)
                                    } else {
                                        setActiveLetter('')
                                    }
                                }}
                            >
                                <option value="all">A-Z</option>

                                {alphabet.map(letter => {
                                    const isAvailable = availableLetters.includes(letter) ? true : false

                                    return (
                                        <option
                                            key={`alphabet-${letter}`}
                                            value={letter}
                                            disabled={!isAvailable}
                                        >
                                            {letter}
                                        </option>
                                    )
                                })}
                            </select>
                        ) : (
                            <div className="alphabet">
                                {alphabet.map(letter => {
                                    const isAvailable = !availableLetters.includes(letter) ? 'not-available' : ''
                                    const isActive = activeLetter === letter ? 'active' : ''

                                    return (
                                        <button
                                            key={`alphabet-${letter}`}
                                            className={`
                                                ${isAvailable}
                                                ${isActive}
                                            `}
                                            onClick={() => {
                                                if (letter !== activeLetter) {
                                                    setActiveLetter(letter)
                                                } else {
                                                    setActiveLetter('')
                                                }
                                            }}
                                        >
                                            {letter}
                                        </button>
                                    )
                                })}
                            </div>
                        )}
                    </section>

                    <section className="glossary-archive__content">
                        <div className="breadcrumbs">
                            <Link to='/' className="breadcrumbs__item">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="12" cy="12" r="12" fill="url(#paint0_linear_10126_3637)"/>
                                    <path d="M18.9692 17.4411C18.9729 17.6282 18.9393 17.8141 18.8705 17.9881C18.8016 18.162 18.6989 18.3205 18.5683 18.4542C18.4377 18.588 18.2818 18.6943 18.1098 18.767C17.9377 18.8397 17.7529 18.8774 17.5662 18.8777C17.3779 18.878 17.1914 18.841 17.0173 18.7689C16.8433 18.6968 16.6851 18.591 16.552 18.4575C16.4188 18.3241 16.3132 18.1656 16.2413 17.9912C16.1693 17.8167 16.1324 17.6298 16.1327 17.4411V16.8297C15.4922 17.8996 13.7232 19 11.7406 19C8.05005 19 5 15.8821 5 12C5.00066 11.0784 5.18279 10.1659 5.53594 9.31497C5.8891 8.464 6.40633 7.69123 7.05802 7.04096C7.7097 6.39068 8.48301 5.87568 9.33365 5.52546C10.1843 5.17524 11.0955 4.99669 12.0151 5.00005C16.3462 5.00005 19.0302 8.24021 18.9997 12L18.9692 17.4411ZM12.0151 7.64924C9.57508 7.64924 7.98905 9.60556 7.98905 11.9898C7.98905 14.3741 9.57508 16.3304 12.0151 16.3304C14.4552 16.3304 16.0412 14.3741 16.0412 11.9898C16.0412 9.60556 14.4552 7.64924 12.0151 7.64924Z" fill="white"/>
                                    <defs>
                                    <linearGradient id="paint0_linear_10126_3637" x1="24" y1="24" x2="0" y2="0" gradientUnits="userSpaceOnUse">
                                    <stop stopColor="#FC6076"/>
                                    <stop offset="0.484375" stopColor="#FF4D66"/>
                                    <stop offset="1" stopColor="#FF6826"/>
                                    </linearGradient>
                                    </defs>
                                </svg>
                            </Link>
                            <div className="breadcrumbs__item">
                                <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M7.18436 11.3545C7.62418 10.9476 7.62418 10.2524 7.18436 9.84554L3.02703 6L7.18436 2.15446C7.62418 1.74763 7.62418 1.05237 7.18436 0.645537C6.79045 0.281168 6.18252 0.281169 5.78861 0.645537L6.91406e-07 6L5.78861 11.3545C6.18252 11.7188 6.79045 11.7188 7.18436 11.3545Z" fill="#3E3E53" />
                                </svg>
                                <span>{banner.title}</span>
                            </div>
                        </div>

                        <div className="glossary-archive__list">
                            {filteredPosts.length ? (
                                filteredPosts.map((item) => {
                                    return (
                                        <PostsGroup item={item} key={`posts-group-${item.letter}`} />
                                    )
                                })
                            ) : (
                                <p>Keine Beiträge</p>
                            )}
                            
                        </div>
                        <div className="glossary-archive__info">
                            <h2>{info.title}</h2>
                            <div>
                                <div dangerouslySetInnerHTML={{ __html: info.left_text }} />
                                <div dangerouslySetInnerHTML={{ __html: info.right_text }} />
                            </div>
                        </div>
                    </section>
                </div>
            </>
        );
    } else {
        return (
            <>
                <div className="glossary-archive glossary-archive--preloader">
                    <Loader />
                </div>
            </>
        );
    }
};

const PostsGroup = ({ item }) => {
    return (
        <div className="glossary-archive__item">
            <div>
                <span className={`letter--${item.letter}`}>
                    {item.letter}
                </span>
            </div>
            <ul>
                {item.posts.map(post => (
                    <li key={post.title.rendered}>
                        <Link to={`/glossar/${post.slug}`}>
                            <i>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.774289 0.0284424C0.360711 0.0284424 0.0283203 0.366645 0.0283203 0.780224V20.4382C0.0283203 22.4132 1.62498 23.9925 3.58257 23.9925H20.4323C22.4986 23.9925 23.9924 22.314 23.9924 20.4382C23.9924 20.4382 23.9924 15.3209 23.9924 12.7622C23.9924 12.3463 23.6565 12.0104 23.2406 12.0104H18.3758V0.78027C18.3758 0.385395 18.0583 0.0284893 17.6064 0.0284893L0.774289 0.0284424ZM4.52307 5.26911H13.8811C14.8806 5.26911 14.8806 6.76832 13.8811 6.76832H4.52307C3.5236 6.76836 3.5236 5.26911 4.52307 5.26911ZM4.52307 9.01789H13.8811C14.883 9.01789 14.883 10.5156 13.8811 10.5156H4.52307C3.52824 10.5156 3.52824 9.01789 4.52307 9.01789ZM4.52307 12.7594H13.8811C14.883 12.7594 14.883 14.2586 13.8811 14.2586H4.52307C3.52824 14.2586 3.52824 12.7594 4.52307 12.7594ZM18.3758 13.5082H22.4946V20.4383C22.4946 21.5916 21.5656 22.4948 20.4323 22.4948C19.299 22.4948 18.3758 21.5916 18.3758 20.4383V13.5082H18.3758ZM4.52307 16.5023H13.8811C14.883 16.5023 14.883 18 13.8811 18H4.52307C3.52824 18 3.52824 16.5023 4.52307 16.5023Z" fill="#3E3E53"/>
                                </svg>
                            </i>
                            <span>{post.title.rendered}</span>
                        </Link>
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default Archive;
