import React, { useEffect, useState, useRef, useCallback } from 'react'

import ScrollIcon from '../ScrollIcon/ScrollIcon';

import VideoPlayer from '../../VideoPlayer';

import useMobile from "../../../hooks/useMobile";

import "./Header.scss"

const Header = (props) => {
    const { title, type, client_name, client_position, text, rotate_image, video_left } = props.contentLeft;
    const { founder_photo, background_image, logo, rotate_image_right, video_right } = props.contentRight;
    const { video, video_image, icon } = props.video || {};
    const sectionHeader = useRef(null);
    const [transform, setTransform] = useState('none');
    const [width, setWidth] = useState(50);
    const [transition, setTransition] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const isMobile = useMobile();
    const { isSuccessStoryShort } = props;
    const [isScrolledToEnd, setIsScrolledToEnd] = useState(false);
    const [isScrollable, setIsScrollable] = useState(false);
    const textDescriptionRef = useRef(null);

    useEffect(() => {
        const currentRef = textDescriptionRef.current;
        handleScroll();
        if (currentRef) {
            currentRef.addEventListener('scroll', handleScroll);
            if (currentRef.scrollHeight > currentRef.clientHeight) {
                setIsScrollable(true);
            } else {
                setIsScrollable(false);
            }
        }
        return () => {
            if (currentRef) {
                currentRef.removeEventListener('scroll', handleScroll);
            }
        };
    }, [textDescriptionRef.current]);

    useEffect(() => {
        const mouseMove = (e) => {
            let w, h
            w = window.innerWidth
            h = window.innerHeight
            if (w > 992) {
                sectionHeader.current.classList.remove('transition');

                let x,
                    y,
                    rotateX,
                    moveY,
                    boxPos = sectionHeader.current.offsetTop
                if (e.pageX || e.pageY) {
                    x = e.pageX
                    y = e.pageY
                } else if (e.clientX || e.clientY) {
                    x =
                        e.clientX +
                        document.body.scrollLeft +
                        document.documentElement.scrollLeft
                    y =
                        e.clientY +
                        document.body.scrollTop +
                        document.documentElement.scrollTop
                }
                y = -(boxPos - y)
                rotateX = (w / 2 - x) / 1000 / 4
                moveY = (y - h / 2) / 25
                let movePercent = ((w / 2 - x) * 100) / w / 3
                setTransform(`matrix3d(${Math.cos(rotateX)}, 0, ${Math.sin(rotateX)}, 0, 0, 1, 0, 0, ${Math.sin(rotateX)}, 0, ${Math.cos(rotateX)}, 0 , 0, ${moveY}, 0, 1)`);
                let changedWidth = 50 + movePercent;
                setWidth(changedWidth);
                if (transition) {
                    setTransition(true)
                }
            }
        }
        if (sectionHeader.current && !isSuccessStoryShort) {
            sectionHeader.current.addEventListener("mousemove", mouseMove);
        }

        return () => {
            sectionHeader.current.removeEventListener("mousemove", mouseMove);
        };
    }, [transition])

    const openModal = () => {
        setModalOpen(true)
    }

    const closeModal = () => {
        setModalOpen(false);
    }

    const handleScroll = () => {
        if (textDescriptionRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = textDescriptionRef.current;
            if (scrollTop + clientHeight >= scrollHeight - 1) {
                setIsScrolledToEnd(true);
            } else {
                setIsScrolledToEnd(false);
            }
        }
    };


    return (
        <section ref={sectionHeader} className={`success-story${transition ? ' transition' : ''}`}>
            {video &&
                <div className={`modal__video ${modalOpen ? "modal__video--open" : ""}`} >
                    <div className="modal__video--event" onClick={closeModal}></div>
                    <VideoPlayer
                        video={video}
                        poster={video_image}
                        playIcon={icon}
                        allowPlay={modalOpen}
                        className="modal__video__open"
                    />
                    <div className="modal__video__close" onClick={closeModal}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M23.954 21.03l-9.184-9.095 9.092-9.174-2.832-2.807-9.09 9.179-9.176-9.088-2.81 2.81 9.186 9.105-9.095 9.184 2.81 2.81 9.112-9.192 9.18 9.1z" /></svg>
                    </div>
                </div>
            }
            <div className="success-story__bg-elements view">
                {!isSuccessStoryShort &&
                    <div className="view__wrapper view__wrapper--before" id="viewBefore" style={{ width: width + "%" }}>
                        <div className="view__content">
                            <div className="view__image loading-block">
                                <img
                                    alt={title}
                                    id="imgBefore"
                                    style={{ transform: transform }}
                                    src={rotate_image.url}
                                    width={rotate_image.width}
                                    height={rotate_image.height}
                                />
                                <img
                                    className="view__video--img"
                                    src={video_left.video_icon.url}
                                    width={video_left.video_icon.width}
                                    height={video_left.video_icon.height}
                                    alt={video_left.video_title}
                                    style={{ transform: transform }}
                                    onClick={openModal}
                                />
                                <p className="view__video--title" style={{ transform: transform }}>{video_left.video_title}</p>
                            </div>
                            {!isMobile && !isSuccessStoryShort && <ScrollIcon color={`#afca1e`} borderColor={`#afca1e`} />}
                        </div>
                    </div>
                }
                {isMobile &&
                    <div className="success-story__text-head">
                        <h1 className="success-story__text-title--mobile" dangerouslySetInnerHTML={{ __html: title }} />
                        {type && <div className="success-story__type" dangerouslySetInnerHTML={{ __html: type }} />}
                    </div>
                }
                <div className="view__wrapper view__wrapper--after">
                    <div className="view__content view__content--rotated-bg" style={{backgroundImage: `url(${background_image.url})`}}>
                        {isSuccessStoryShort
                            ?
                                logo.url && <img className="success-story__logo" src={logo.url} width={logo.width} height={logo.height} alt={logo.alt || 'logo'} />
                            :
                            <>
                                <div className="view__image loading-block">
                                    <img
                                        alt={title}
                                        id="imgAfter"
                                        style={{ transform: transform }}
                                        src={rotate_image_right.url}
                                        width={rotate_image_right.width}
                                        height={rotate_image_right.height}
                                    />
                                    <img
                                        className="view__video--img"
                                        src={video_right.video_icon.url}
                                        width={video_right.video_icon.width}
                                        height={video_right.video_icon.height}
                                        style={{ transform: transform, zIndex: 3 }}
                                        alt={video_left.video_title}
                                        onClick={openModal}
                                    />
                                    <p className="view__video--title view__video--title-white" style={{ transform: transform }}>{video_left.video_title}</p>
                                </div>
                                <ScrollIcon color={`#ffffff`} borderColor={`#ffffff`} />
                            </>
                        }
                    </div>
                </div>
            </div>
            <div className="success-story__content">
                <div className="success-story__item success-story__item--left-col loading-block loading-block--2">
                    {isMobile && !isSuccessStoryShort &&
                        <div className="success-story__item-image">
                            <img src={rotate_image.url} id="imgBefore" alt="logo" />
                        </div>
                    }
                </div>
                {isMobile && !isSuccessStoryShort &&
                    <div className="success-story___item success-story__item--right-col loading-block loading-block--2">
                        <div className="success-story___item-body">
                            <div className="success-story__item-image">
                                <img src={rotate_image_right.url} id="imgBefore" alt="logo" />
                            </div>
                        </div>
                    </div>
                }
                {isMobile ?
                    <div className="success-story__item-body">
                        <div className="success-story__item-description" dangerouslySetInnerHTML={{ __html: text }} />
                        {founder_photo &&
                            <>
                                <div className="success-story__item-client">
                                    <p className="client-position" dangerouslySetInnerHTML={{ __html: client_position }} />
                                    <p className="client-name" dangerouslySetInnerHTML={{ __html: client_name }} />
                                </div>
                                <div className="success-story__founder-photo">
                                    <img
                                        src={founder_photo.url}
                                        alt={founder_photo.alt}
                                        width={founder_photo.width}
                                        height={founder_photo.height}
                                    />
                                </div>
                            </>
                        }
                    </div>
                    :
                    null
                }
            </div>
            {!isMobile &&
                <div className="success-story__text">
                    <h1 className="success-story__text-title" dangerouslySetInnerHTML={{__html: title}}/>
                    {type && <div className="success-story__type" dangerouslySetInnerHTML={{__html: type}}/>}
                    <div className={`success-story__text-description-wrapper ${isScrolledToEnd ? 'scrolled-to-end' : ''}`}>
                        <div
                            className="success-story__text-description"
                            dangerouslySetInnerHTML={{__html: text}}
                            ref={textDescriptionRef}
                        />
                    </div>
                    {founder_photo &&
                        <>
                            <div className="success-story__founder-photo">
                                <img
                                    src={founder_photo.url}
                                    alt={founder_photo.alt}
                                    width={founder_photo.width}
                                    height={founder_photo.height}
                                />
                            </div>
                            <div className="success-story__text-client">
                                <p className="client-position" dangerouslySetInnerHTML={{__html: client_position}}/>
                                <p className="client-name" dangerouslySetInnerHTML={{__html: client_name}}/>
                            </div>
                        </>
                    }
                </div>
            }
        </section>
    )
}

export default Header
