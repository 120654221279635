import React from 'react'

import ServicesTitle from '../ServicesTitle/ServicesTitle';

import VideoPlayer from '../../VideoPlayer';

import useMobile from "../../../hooks/useMobile";

import './ServicesSuccess.scss'

import { LazyLoadImage } from 'react-lazy-load-image-component';

const ServicesSuccess = (props) => {

    const isMobile = useMobile();


    const {
        page_fragments,
        statistic,
        page_mobile,
        delivered_services,
        system,
        features,
        interfaces,
        project_description,
        video,
        links,
        toggle_media,
        main_video
    } = props.content;

    const pageFragments = !isMobile && toggle_media !== 'video' && page_fragments && page_fragments.length && page_fragments[0].fragment && page_fragments;

    const isPageMobile = isMobile && toggle_media !== 'video' && page_mobile.url;
    const isMainVideo = toggle_media === 'video' && main_video && main_video.video_file;
    const mainVideoRatioHorizontal = main_video && main_video.video_ratio && main_video.video_ratio.horizontal;
    const mainVideoRatioVertical = main_video && main_video.video_ratio && main_video.video_ratio.vertical;

    const mainVideoFile = main_video && main_video.video_file  || {}
    const mainVideoPoster = main_video && main_video.video_poster || {}
    const playIconPoster = main_video && main_video.video_icon || {}

    const linkStartPage = !isMobile ? links.start_page.desktop.title : links.start_page.mobile.title;
    const linkBlogPage = !isMobile ? links.blog.desktop.title : links.blog.mobile.title;

    return (
        <section className="success__services">
            <div className="success__services__wrapper">
                <div className={"items items--left_side" + (isMainVideo ? " items--video" : "")}>
                    {isPageMobile && (
                        <img src={page_mobile.url} className="items__img" alt="mobile" />
                    )}

                    {pageFragments && pageFragments.map(item => {
                        if (item.fragment)
                            return (
                                <LazyLoadImage
                                    className="items__img"
                                    key={item.fragment.ID}
                                    src={item.fragment.url}
                                    alt={item.fragment.alt || "mobile"}
                                    width={item.fragment.width}
                                    height={item.fragment.height}
                                />
                            );
                    })}

                    {isMainVideo &&
                        <VideoPlayer
                            video={mainVideoFile}
                            poster={mainVideoPoster}
                            playIcon={playIconPoster}
                            ratioVertical={mainVideoRatioVertical}
                            ratioHorizontal={mainVideoRatioHorizontal}
                        />
                    }
                </div>

                <div className="items items--right_side" >
                    <div className="item item__statistics">
                        {statistic && statistic.map((item, key) => {
                            return (
                                <div className="item__statistics__wrapper" key={key}>
                                    <div className="item__statistics-number">{item.number}</div>
                                    <div className="item__statistics-title">{item.title}</div>
                                </div>
                            )
                        })}
                    </div>
                    
                    {delivered_services.services &&
                        <div className="item__services">
                            {delivered_services.title &&
                                <ServicesTitle title={delivered_services.title} />
                            }
                            <div className="item__services-services">
                                {delivered_services.services.map((item) => {
                                    return (
                                        <div className="item__services-services-group" key={item.icon.id}>
                                            <img src={item.icon.url} alt={item.text} />
                                            <p>{item.text}</p>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    }

                    {system.technology && 
                        <div className="item__services">
                            <ServicesTitle title={system.title} />
                            <div className="item__services-services">
                                {system.technology.map((item) => {
                                    return <div className="item__services-services-group" key={item.icon.id}>
                                        <img src={item.icon.url} alt={item.text} />
                                        <p>{item.text}</p>
                                    </div>
                                })}
                            </div>
                        </div>
                    }

                    {features && features.list &&
                    <div className="item__services">
                        <ServicesTitle title={features.title} />
                        <ul className="item__services-list" dangerouslySetInnerHTML={{ __html: features.list }}></ul>
                    </div>
                    }

                    {interfaces && interfaces.list &&
                        <div className="item__services">
                            <ServicesTitle title={interfaces.title} />
                            <ul className="item__services-list" dangerouslySetInnerHTML={{ __html: interfaces.list }}></ul>
                        </div>
                    }

                    {project_description &&
                        <div className="item__services item__services-services-project">
                            {project_description.map((item, key) => {
                                return (
                                    <div className="project-description" key={key}>
                                        <ServicesTitle title={item.title} />
                                        <p className="project-description-text">{item.description}</p>
                                    </div>
                                )
                            })}
                        </div>
                    }

                    {video && video.video &&
                        <div className="item__services">
                            <ServicesTitle title={video.title} />
                            <VideoPlayer
                                video={video.video}
                                poster={video.video_image}
                                playIcon={video.icon}
                                className="video"
                            />
                        </div>
                    }

                    <div className="item__services">
                        {linkStartPage &&
                            <a className="item__services-link" href={links.start_page.desktop.url} target="_blank" rel="nofollow noopener">
                                <span>{linkStartPage}</span>
                            </a>
                        }
                        {linkBlogPage &&
                            <a className="item__services-link" href={links.blog.desktop.url} target="_blank" rel="nofollow noopener">
                                <span>{linkBlogPage}</span>
                            </a>
                        }
                    </div>
                </div>
            </div>
        </section>
    )
}


export default ServicesSuccess