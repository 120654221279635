import React, { Component } from "react";
import './Projects.scss';
import { Link } from "react-router-dom";

class Projects extends Component {

    render() {

        if (this.props.content) {

            // const mobileBanner = this.props.content.mobile_banner;
            const desktopBanner = this.props.content.desktop_banner;
            const title = this.props.content.title;
            const titleBg = this.props.content.title_bg;
            const allProjectsLink = this.props.content.link;
            const projects = this.props.content.projects;

            return (
                <>
                    <div className="support-projects">
                        <div className="support-projects__list">
                            <div className="support-projects__list-inner">
                                <div className="support-projects__list-item support-projects__title">
                                    <div className="support-projects__title-bg" dangerouslySetInnerHTML={{ __html: titleBg }} />
                                    <h2 className="support-projects__title-text">
                                        {title}
                                    </h2>
                                </div>
                                {
                                    projects.map((item, key) => {
                                        return (
                                            <div className="support-projects__list-item support-project" key={key}>
                                                {item.link ?
                                                    <a href={item.link}>
                                                        <img src={item.logotype.url} alt={item.logotype.alt} />
                                                    </a>
                                                    :
                                                    <a>
                                                        <img src={item.logotype.url} alt={item.logotype.alt} />
                                                    </a>
                                                }
                                            </div>
                                        )
                                    })
                                }
                                <div className="support-projects__list-item support-projects__to-all">
                                    <Link to={allProjectsLink.url} className="base-btn">
                                        {allProjectsLink.title}
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="support-projects__poster">
                            <img
                                src={desktopBanner.url}
                                alt={desktopBanner.alt}
                            />
                        </div>
                    </div>
                </>
            );
        } else {
            return (
                <div className="support-projects">
                </div>
            );
        }
    }
}

export default Projects;
