import React, {Component} from "react";
import FileSaver from "file-saver";

class VCardFileCreator extends Component {
    constructor(props) {
        super(props);
        this.state = {
            photo: props.cv_content.photo,
            firstName: props.cv_content.first_name,
            lastName: props.cv_content.last_name,
            position: props.cv_content.position,
            email: props.cv_content.email,
            phoneNumber: props.cv_content.phone_number,
            url: 'alkima.de',
            organization: 'alkima WEB & DESIGN',
        };
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(e) {
        e.preventDefault();
        var file = new Blob(
            [
                `BEGIN:VCARD
VERSION:3.0
N:${this.state.lastName};${this.state.firstName};;;
FN:${this.state.firstName} ${this.state.lastName}
PHOTO;TYPE=${this.state.photo.subtype}:${this.state.photo.url}
TITLE:${this.state.position};
URL;CHARSET=UTF-8;type=pref:${this.state.url}
ORG;CHARSET=UTF-8;type=pref:${this.state.organization}
EMAIL;type=INTERNET;type=pref:${this.state.email}
TEL;type=MAIN:${this.state.phoneNumber}
END:VCARD`
            ],
            {type: "text/vcard;charset=utf-8"}
        );
        FileSaver.saveAs(
            file,
            `${this.state.firstName}${this.state.lastName}.vcf`,
            true
        );
    }

    render() {
        return (
            <div onClick={this.handleClick} className={this.props.className}>
                <div className="cv-page__btn-icon">
                    <i className="icon-person-add"></i>
                </div>
                <div className="cv-page__btn-text">zu meinen Kontakten</div>
            </div>
        );
    }
}

export default VCardFileCreator;
