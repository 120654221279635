import React, { useEffect, useState, useRef, useCallback } from 'react';

import Gear from '../PageOnScreen/Gear/Gear';

import TechnologyContent from '../TechnologyContent/TechnologyContent';

import Plx from 'react-plx';

import paymentImage from '../images/Payment-methods/alkima-erfolgsgeschichte-bw-online-shop-grafik-zahlungsarten.svg';

import WrapperImplementation from '../WrapperImplementation/WrapperImplementation';

import useMobile from "../../../hooks/useMobile";

import { LazyLoadImage } from 'react-lazy-load-image-component';

import ScheduleOptimization from './ScheduleOptimization';

import { ReactComponent as Pillar } from '../images/Rings/alkima-erfolgsgeschichte-bw-online-shop-rakete-start-feuer-schatten.svg';

import { ReactComponent as Sparks } from '../images/Rings/alkima-erfolgsgeschichte-bw-online-shop-rakete-start-sterne.svg';

import HostingAnimation from './HostingAnimation'

import '../Animation/Animation.scss';

import './ProjectImplementation.scss';


const ProjectImplementation = (props) => {
    const { content } = props.content;
    const { technology } = props.gears;
    const { canAnimate } = props;
    const [isActiveRings, setIsActiveRings] = useState(false);
    const [isActiveEnvelope, setIsActiveEnvelope] = useState(false);
    const [isAnimationGear1, setIsAnimationGear1] = useState(false);
    const [isAnimationGear2, setIsAnimationGear2] = useState(false);
    const [cardsEffect, setCardsEffect] = useState(false);
    const [optimizationAnimate, setOptimizationAnimate] = useState(false);
    const [isAnimateGear3, setIsAnimateGear3] = useState(false);
    const isMobile = useMobile();

    const gearImage = [
        { img: require("../images/Gear/gear-blue.png"), className: "content__img", logo: require("../images/Gear/alkima-erfolgsgeschichte-bw-online-shop-pixi-logo.png"), classNameLogo: "content__img-logo" },
        { img: require("../images/Gear/gear-red.png"), className: "content__img", logo: require("../images/Gear/alkima-erfolgsgeschichte-bw-online-shop-landing-page-magento-logo-voll.png"), classNameLogo: "content__img-logo" }
    ]

    const productСards = [
        require("../images/Products-cards/alkima-erfolgsgeschichte-bw-online-shop-webdesign-farbfilter.svg").default,
        require("../images/Products-cards/alkima-erfolgsgeschichte-bw-online-shop-webdesign-produktkarte.svg").default,
        require("../images/Products-cards/alkima-erfolgsgeschichte-bw-online-shop-webdesign-farbkonfigurator.svg").default,
    ]

    const onlineMarketing = [
        require("../images/Online-Marketing/alkima-erfolgsgeschichte-bw-online-shop-online-marketing-rich-snippet.svg").default,
        require("../images/Online-Marketing/alkima-erfolgsgeschichte-bw-online-shop-online-marketing-suchergebniss-kategorie.svg").default,
        require("../images/Online-Marketing/alkima-erfolgsgeschichte-bw-online-shop-online-marketing-google-shopping.svg").default,
    ]

    const marketingTrackingGear = [
        {
            img: require("../images/Marketing-tracking/alkima-erfolgsgeschichte-bw-online-shop-zahnrad-voll-blau.png"),
            className: "content__img",
            logo: require("../images/Marketing-tracking/alkima-erfolgsgeschichte-bw-online-shop-gtm-logo.png"),
            classNameLogo: "content__img-logo"
        }, 
        {
            img: require("../images/Marketing-tracking/alkima-erfolgsgeschichte-bw-online-shop-zahnrad-rosa.png"),
            className: "content__img",
            logo: require("../images/Marketing-tracking/alkima-erfolgsgeschichte-bw-online-shop-alkima-logo-weiss.png"),
            classNameLogo: "content__img-logo"
        },
        {
            img: require("../images/Marketing-tracking/border.png"),
            className: "content__img",
            logo: require("../images/Marketing-tracking/alkima-erfolgsgeschichte-bw-online-shop-e-commerce-tracking-text.png"),
            classNameLogo: "content__img-logo--second",
            background: require("../images/Marketing-tracking/alkima-erfolgsgeschichte-bw-online-shop-investierung.png")
        },
    ]

    const conversionOptimization = [
        {
            img: require("../images/Conversion-optimization/alkima-erfolgsgeschichte-bw-online-shop-webdesign-prozess-testing.png"), class: "hand"
        },
        {
            img: require("../images/Conversion-optimization/alkima-erfolgsgeschichte-bw-online-shop-webdesign-prozess-mockup.png"), class: "phone"
        },
        {
            img: require("../images/Conversion-optimization/alkima-erfolgsgeschichte-bw-online-shop-webdesign-prozess-resultat.png"), class: "phone-done"
        }
    ]

    const landingPages = [
        require("../images/Landing/alkima-erfolgsgeschichte-bw-online-shop-landing-page-groessentabellen.png"),
        require("../images/Landing/alkima-erfolgsgeschichte-bw-online-shop-landing-page-katalog-bestellen.png"),
        require("../images/Landing/alkima-erfolgsgeschichte-bw-online-shop-landing-page-neues.png"), 
        require("../images/Landing/alkima-erfolgsgeschichte-bw-online-shop-landing-page-newsletter-bestellen.png"),
        require("../images/Landing/alkima-erfolgsgeschichte-bw-online-shop-landing-page-uebergroessen.png"),
    ]

    const RocketStart = [
        require('../images/Rings/alkima-erfolgsgeschichte-bw-online-shop-rakete-start-kreis-duenn-klein.svg').default,
        require('../images/Rings/alkima-erfolgsgeschichte-bw-online-shop-rakete-start-kreis-rot.svg').default,
        require('../images/Rings/alkima-erfolgsgeschichte-bw-online-shop-rakete-start-kreis-duenn.svg').default,
        require('../images/Rings/alkima-erfolgsgeschichte-bw-online-shop-rakete-start-item-4.svg').default,
        require('../images/Rings/alkima-erfolgsgeschichte-bw-online-shop-rakete-start-kreis-blau.svg').default,
        require('../images/Rings/alkima-erfolgsgeschichte-bw-online-shop-rakete-start-item-6.svg').default,
        require('../images/Rings/alkima-erfolgsgeschichte-bw-online-shop-rakete-start-kreis-striche.svg').default,
        require('../images/Rings/alkima-erfolgsgeschichte-bw-online-shop-rakete-start-item-8.svg').default,
        require('../images/Rings/alkima-erfolgsgeschichte-bw-online-shop-rakete-start-item-9.svg').default,
        require('../images/Rings/alkima-erfolgsgeschichte-bw-online-shop-rakete-start-item-10.svg').default,
        require('../images/Rings/alkima-erfolgsgeschichte-bw-online-shop-rakete-start-item-11.svg').default,
        require('../images/Rings/alkima-erfolgsgeschichte-bw-online-shop-rakete-start-kreis-hintergrund.svg').default,
    ];

    const Rings = [
        require('../images/Optimization/alkima-erfolgsgeschichte-bw-online-shop-umsatz-grafik-donat-grau.svg').default,
        require('../images/Optimization/alkima-erfolgsgeschichte-bw-online-shop-umsatz-grafik-donat-grau-gross.svg').default,
        require('../images/Optimization/alkima-erfolgsgeschichte-bw-online-shop-umsatz-grafik-schritte.svg').default,

    ];

    const OptimizationCards = [
        require('../images/Optimization/alkima-erfolgsgeschichte-bw-online-shop-umsatz-grafik-statistik-2.svg').default,
        require('../images/Optimization/alkima-erfolgsgeschichte-bw-online-shop-umsatz-grafik-kuchen.svg').default,
        require('../images/Optimization/alkima-erfolgsgeschichte-bw-online-shop-umsatz-grafik-statistik.svg').default,

    ]


    const dataRightTopParallax = [
        {
            start: '.project-implementation__product-cards',
            duration: 2000,
            properties: [
                {
                    startValue: 0,
                    endValue: -30,
                    unit: '%',
                    property: 'translateY',
                },
            ],
        },
    ];


    const envelopeBlock = useRef(null);
    const gearBlock1 = useRef(null);
    const gearBlock2 = useRef(null);
    const cards = useRef(null);
    const optimization = useRef(null);
    const gearBlock3 = useRef(null);
    const ring = useRef(null)

    const handleScroll = useCallback(
        () => {
            const envelope = envelopeBlock.current.getBoundingClientRect();
            const gear1 = gearBlock1.current.getBoundingClientRect();
            const gear2 = gearBlock2.current.getBoundingClientRect();
            const cardsEffect = cards.current.getBoundingClientRect();
            const optimizationBlock = optimization.current.getBoundingClientRect();
            const gear3 = gearBlock1.current.getBoundingClientRect();
            const ringTop = ring.current.getBoundingClientRect();

            if (canAnimate(envelope)) {
                setIsActiveEnvelope(true)
            }

            if (canAnimate(gear1)) {
                setIsAnimationGear1(true)
            }

            if (canAnimate(gear2)) {
                setIsAnimationGear2(true)
            }

            if (canAnimate(cardsEffect)) {
                setCardsEffect(true)
            }

            if (canAnimate(optimizationBlock)) {
                setOptimizationAnimate(true)
            }

            if (canAnimate(gear3)) {
                setIsAnimateGear3(true)
            }

            if (canAnimate(ringTop)) {
                setIsActiveRings(true)
            }
        },
        [canAnimate],
    )

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        }
    }, [handleScroll])

    const CardsWrapper = isMobile ? "div" : React.forwardRef((props, ref) => <Plx ref={ref} /* parallaxData={ dataRightTopParallax } */ {...props} />)

    return (
        <section className="project-implementation">
            {isMobile ?
                <div className="rocket__start" ref={ring}>
                    <img src={require('../images/Rings/alkima-erfolgsgeschichte-bw-online-shop-rakete-start-kreis-mobile.png').default} alt="rocket-start" />
                </div>
                :
                <div className="rocket__start" ref={ring}>
                    <div className={`rocket__start__wrapper ${isActiveRings ? "rocket__start__wrapper--animate" : ""}`}>
                        {RocketStart.map((item, key) => {
                            return (
                                <img src={item} alt="rocket-start" className={`rocket-start rocket-start--${key + 1} ${isActiveRings ? `rocket-start--${key + 1}--animate` : ''}`} key={key} />
                            )
                        })}
                        <img src={require('../images/Rings/alkima-erfolgsgeschichte-bw-online-shop-rakete-start-item-13.svg').default} alt="" className={`rocket-start rocket-start--14 ${isActiveRings ? `rocket-start--14--animate` : ''} `} />
                    </div>
                    <div className={`rocket-start rocket-start--13 ${isActiveRings ? `rocket-start--13--animate` : ''}`}>
                        <Pillar />
                        <Sparks />
                    </div>
                </div>
            }
            <div className="project-implementation__wrapper">
                <WrapperImplementation
                    textComponent={<TechnologyContent icon={content.first_block.icon} title={content.first_block.title} text={content.first_block.text} />}
                    imageComponent={
                        <div className={`project-implementation__gears ${isAnimationGear1 ? "project-implementation__gears-animate" : ""}`} ref={gearBlock1}>
                            <Gear gears={technology.images} />
                            {isMobile ?
                                <div className="pillar">
                                    <Pillar />
                                    <Sparks />
                                </div>
                                :
                                null
                            }
                        </div>
                    }
                />
                <WrapperImplementation
                    imageComponent={<div className="project-implementation__gear">
                        {gearImage.map((item, key) => {
                            return (
                                <div className={`content__img__wrapper content__img__wrapper--${key + 4} ${isAnimationGear2 ? "content__img__wrapper-animate" : ""}`} key={key} ref={gearBlock2}>
                                    <LazyLoadImage className={item.className} src={item.img} alt="gear" />
                                    <LazyLoadImage className={item.classNameLogo} src={item.logo} alt="gear" />
                                </div>
                            )
                        })}
                    </div>
                    }
                    textComponent={<TechnologyContent icon={content.second_block.icon} title={content.second_block.title} text={content.second_block.text} Right />}
                />
                <WrapperImplementation
                    textComponent={<TechnologyContent icon={content.third_block.icon} title={content.third_block.title} text={content.third_block.text} />}
                    imageComponent={
                        <div className="project-implementation__product-cards">
                            {productСards.map((item, key) => {
                                return (
                                    <CardsWrapper key={key} className={`product-cards product-cards--${key + 1}`}>
                                        <LazyLoadImage src={item} alt="product-cards" />
                                    </CardsWrapper>
                                )
                            })}</div>
                    }
                />
                <WrapperImplementation
                    imageComponent={
                        <div className="project-implementation__payment__methods">
                            <LazyLoadImage
                                src={paymentImage} alt={content.fourth_block.title.text} width="740" height="640"
                            />
                        </div>
                    }
                    textComponent={<TechnologyContent icon={content.fourth_block.icon} title={content.fourth_block.title} text={content.fourth_block.text} />}
                />
                <WrapperImplementation
                    textComponent={<TechnologyContent icon={content.fifth_block.icon} title={content.fifth_block.title} text={content.fifth_block.text} />}
                    imageComponent={
                        <div className="project-implementation__marketing-tracking thirdGearWrapper" ref={gearBlock3}>
                            <Gear gears={technology.images} />
                            {marketingTrackingGear.map((item, key) => {
                                return (
                                    <div className={`content__img__wrapper content__img__wrapper--${key + 6}`} key={key}>
                                        <img className={item.className} src={item.img} alt="gear" />
                                        <img className={item.classNameLogo} src={item.logo} alt="gear" />
                                        {item.background ? <img className={item.classNameLogo} src={item.background} alt="gear" /> : null}
                                    </div>
                                )
                            })}
                        </div>
                    }
                />
                <WrapperImplementation
                    imageComponent={
                        <div className="conversion-optimization" ref={optimization}>
                            {content.sixth_block.stage && content.sixth_block.stage.map((item, key) => {
                                return (
                                    <div className={`conversion-optimization__item conversion-optimization__item--i${key + 1} ${optimizationAnimate ? `conversion-optimization__item--i${key + 1}--animate` : ""}`} key={key}>
                                        <div className={`conversion-optimization__number ${optimizationAnimate ? `conversion-optimization__number--${key + 1}-animate` : ""}`}>{item.number}</div>
                                        <div className="conversion-optimization__text">{item.text}</div>
                                        <div className={`conversion-optimization__arrow conversion-optimization__arrow--${key + 1} ${optimizationAnimate ? `conversion-optimization__arrow--${key + 1}-animate` : ""}`} dangerouslySetInnerHTML={{ __html: item.arrow }}></div>
                                    </div>
                                )
                            })}
                            {conversionOptimization.map((item, key) => {
                                return <img className={`conversion-optimization__img conversion-optimization__img--${item.class} ${optimizationAnimate ? `conversion-optimization__img--${item.class}--animate` : ""} `} src={item.img} alt="phone" key={key} />
                            })}
                        </div>
                    }
                    textComponent={<TechnologyContent icon={content.sixth_block.icon} title={content.sixth_block.title} text={content.sixth_block.text} />}
                />
                <WrapperImplementation
                    textComponent={<TechnologyContent icon={content.seventh_block.icon} title={content.seventh_block.title} text={content.seventh_block.text} />}
                    imageComponent={
                        <div className="project-implementation__newsletter-marketing" ref={envelopeBlock}>
                            <div className={`envelope__message ${isActiveEnvelope ? "envelope__message-animate" : ""}`}>
                                <span>1</span>
                            </div>
                            <div className="wrapper-for-envelope">
                                <div className="envelope">
                                    <div className={`envelope__top ${isActiveEnvelope ? "envelope__top-animate" : ""}`}>
                                        <img src={require("../images/Envelope/alkima-erfolgsgeschichte-bw-online-shop-umschlag-unten.svg").default} alt="envelope" />
                                    </div>
                                    <div className={`envelope__paper ${isActiveEnvelope ? "envelope__paper-animate" : ""}`}>
                                        <img src={require("../images/Envelope/alkima-erfolgsgeschichte-bw-online-shop-newsletter-karte.svg").default} alt="envelope" />
                                    </div>
                                    <div className="envelope__body">
                                        <img src={require("../images/Envelope/alkima-erfolgsgeschichte-bw-online-shop-umschlag-hinten.svg").default} alt="envelope" className="envelope__back" />
                                        <img src={require("../images/Envelope/alkima-erfolgsgeschichte-bw-online-shop-umschlag-koerper.svg").default} alt="envelope" className="envelope__front" />
                                        <img src={require("../images/Envelope/alkima-erfolgsgeschichte-bw-online-shop-umschlag-unten.svg").default} alt="envelope" className="envelope__bottom" />
                                    </div>
                                    <div className="envelope__logo">
                                        <LazyLoadImage src={require("../images/Envelope/alkima-erfolgsgeschichte-bw-online-shop-logo-voll.png")} alt="envelope" />
                                    </div>
                                </div>
                            </div>
                        </div>}
                />
                <WrapperImplementation
                    imageComponent={<HostingAnimation />}
                    textComponent={<TechnologyContent icon={content.eighth_block.icon} title={content.eighth_block.title} text={content.eighth_block.text} />}
                />
                <WrapperImplementation
                    textComponent={<TechnologyContent icon={content.ninth_block.icon} title={content.ninth_block.title} text={content.ninth_block.text} />}
                    imageComponent={
                        <div className="project-implementation__process-optimization">
                            <CardsWrapper className="process-optimization process-optimization__wrapper">
                                {OptimizationCards.map((item, key) => {
                                    return (
                                        <LazyLoadImage className={`process-optimization process-optimization-cards process-optimization-cards--${key + 1}`} src={item} alt="process-optimization" key={key} />
                                    )
                                })}
                            </CardsWrapper>
                            <div className="process-optimization process-optimization-schedule"><ScheduleOptimization /></div>
                            {Rings.map((item, key) => {
                                return <LazyLoadImage className={`process-optimization process-optimization-rings process-optimization-rings--${key + 1}`} src={item} alt="process-optimization" key={key} />
                            })}
                        </div>
                    }
                />
                <WrapperImplementation
                    imageComponent={<LazyLoadImage className="project-implementation__search-engine" src={require("../images/Search-engine/alkima-erfolgsgeschichte-bw-online-shop-google-suchergebnisse.png")} alt="search" />}
                    textComponent={<TechnologyContent icon={content.tenth_block.icon} title={content.tenth_block.title} text={content.tenth_block.text} />}
                />
                <WrapperImplementation
                    textComponent={<TechnologyContent icon={content.eleventh_block.icon} title={content.eleventh_block.title} text={content.eleventh_block.text} />}
                    imageComponent={
                        <div className="project-implementation__landing" ref={cards}>
                            {isMobile
                                ?
                                <img src={require("../images/Landing/alkima-erfolgsgeschichte-bw-online-shop-landing-pages-zusammen.png")} alt="pages" className="project-implementation__landing__mobile" />
                                :
                                landingPages.map((item, key) => {
                                    return (
                                        <LazyLoadImage
                                            className={`project-implementation__landing__desktop project-implementation__landing--${
                                                key + 1
                                            } ${
                                                cardsEffect ? `project-implementation__landing--${key + 1}-animate` : ""
                                            }`}
                                            src={item}
                                            alt="landing-page"
                                            key={key}
                                        />
                                    )
                                })
                            }
                        </div>
                    }
                />
                <WrapperImplementation
                    imageComponent={
                        <div className="project-implementation__marketing-cards">
                            {onlineMarketing.map((item, key) => {
                                return (
                                    <CardsWrapper key={key} className={`marketing-cards marketing-cards--${key + 1}`}>
                                        <LazyLoadImage src={item} alt="marketing-cards" />
                                    </CardsWrapper>
                                )
                            })}</div>
                    }
                    textComponent={<TechnologyContent icon={content.twelfth_block.icon} title={content.twelfth_block.title} text={content.twelfth_block.text} />}
                />
            </div>
        </section>
    )
}

export default ProjectImplementation;