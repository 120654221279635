import styles from "./ReadingTime.module.scss";

export default function ReadingTime({ minutes = 0 , label = '', postfixPlural = '', postfixSingular = '' }) {
    if (!minutes) {
        return null;
    }

    const postfix = minutes % 10 > 1 ? postfixPlural : postfixSingular

    return (
        <div className={styles.wrap}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" fill="none">
                    <path  d="M8.991 0C4.023 0 0 4.032 0 9C0 13.968 4.023 18 8.991 18C13.968 18 18 13.968 18 9C18 4.032 13.968 0 8.991 0ZM9 16.2C5.022 16.2 1.8 12.978 1.8 9C1.8 5.022 5.022 1.8 9 1.8C12.978 1.8 16.2 5.022 16.2 9C16.2 12.978 12.978 16.2 9 16.2ZM9.45 4.5H8.1V9.9L12.825 12.735L13.5 11.628L9.45 9.225V4.5Z" fill="#3E3E53"/>
                </svg>
            <span className={styles.text}>{minutes} {postfix} {label} </span>
        </div>
    )
}