import React from "react";
import {LazyLoadImage} from "react-lazy-load-image-component";
import "../WebEntwicklung.scss"

const TechnologiesList = ({data}) => {
    return (
        data?.technologies_list && (<div className="software-page__list">
            {
                data?.technologies_list?.map((item, key) => {
                    let technology = item?.technology;
                    return (
                        <div className={`software-page__item software-page__item--${technology.slug}`} key={key}>
                            <div className="software-page__item-icon-bg"
                                 dangerouslySetInnerHTML={{__html: technology.icon}}/>
                            <div className="software-page__item-text-bg"
                                 dangerouslySetInnerHTML={{__html: technology.text_background}}/>
                            <div className="software-page__item-icon"
                                 dangerouslySetInnerHTML={{__html: technology.icon}}/>
                            <h3 className="software-page__item-title"
                                dangerouslySetInnerHTML={{__html: technology.title}}/>
                            <div className="software-page__item-subtitle"
                                 dangerouslySetInnerHTML={{__html: technology.subtitle}}/>
                            <div className="software-page__item-description"
                                 dangerouslySetInnerHTML={{__html: technology.description}}/>
                            {technology?.button && (
                                <a className="software-page__item-button"
                                   href={technology?.button?.url}>
                                    <span className="base-btn">{technology?.button?.title}</span>
                                </a>
                            )}
                            {technology?.certificates?.length > 0 && (
                                <div className="software-page__services">
                                    {technology.certificates.map((item) => {
                                            const certificate = item?.certificate;
                                            return certificate?.url &&
                                                <LazyLoadImage key={certificate?.url} alt={certificate?.alt}
                                                               src={certificate?.url}/>
                                        }
                                    )}
                                </div>
                            )}
                        </div>
                    )
                })
            }
        </div>)
    )
}

export default TechnologiesList;