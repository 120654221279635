import React from "react";
import { withRouter } from 'react-router-dom';
import { Html5Entities } from 'html-entities';
import { Helmet } from "react-helmet";

const htmlEntities = new Html5Entities();

function PageMeta({ metaData, hreflangs, location }) {
    const {
        yoast_title,
        yoast_meta,
    } = metaData;

    const microdata = {
        "@context": "https://schema.org",
        "@graph": [
            {
                "@type": "Organization",
                "@id": `${window.origin}#organization`,
                "name": "alkima WEB & DESIGN ®",
                "url": `${window.origin}`,
                "sameAs": [
                    "https://www.linkedin.com/company/alkima-web/about/",
                    "https://twitter.com/alkima_web"
                ],
                "logo": {
                    "@type": "ImageObject",
                    "inLanguage": "de-DE",
                    "@id": `${window.origin}#/schema/logo/image/`,
                    "url": "https://backend.alkima.de/app/uploads/2019/10/footer-logo.png",
                    "contentUrl": "https://backend.alkima.de/app/uploads/2019/10/footer-logo.png",
                    "width": 245,
                    "height": 91,
                    "caption": "alkima WEB & DESIGN"
                },
                "image": { "@id": `${window.origin}#/schema/logo/image/` }
            },
            {
                "@type": "WebSite",
                "@id": `${window.origin}#website`,
                "url": `${window.origin}`,
                "name": "alkima WEB & DESIGN ®",
                "description": "Webagentur",
                "publisher": { "@id": `${window.origin}#organization` },
                "inLanguage": "de-DE"
            },
            {
                "@type": "WebPage",
                "@id": `${window.origin}`,
                "url": `${window.origin}`,
                "name": "Webagentur | alkima WEB & DESIGN ®",
                "isPartOf": { "@id": `${window.origin}#website` },
                "description": "Wir bringen Ihr Web-Projekt zum Erfolg! Ob ✅ Online-Shop ✅ Webseite ✅ Web-App. Wir begleiten Sie auf dem gesamten Weg zum digitalen Erfolg!",
                "inLanguage": "de-DE",
                "potentialAction": [{ "@type": "ReadAction", "target": [`${window.origin}`] }]
            },
            {
                "@type": "LocalBusiness",
                "name": "alkima WEB & DESIGN ®",
                "image": [
                    "https://backend.alkima.de/app/uploads/2021/08/logo_for_META1.png",
                    "https://backend.alkima.de/app/uploads/2021/08/logo_for_META2.png",
                    "https://backend.alkima.de/app/uploads/2021/08/logo_for_META3.png"
                ],
                "url": `${window.origin}`,
                "telephone": "+49 611 9458 64 00",
                "email": "info@alkima.de",
                "address": {
                    "@type": "PostalAddress",
                    "streetAddress": "Colmarer Str. 12",
                    "addressLocality": "Wiesbaden",
                    "postalCode": "65203",
                    "addressCountry": "DE"
                },
                "geo": {
                    "@type": "GeoCoordinates",
                    "latitude": 50.04628,
                    "longitude": 8.22883
                },
                "openingHoursSpecification": {
                    "@type": "OpeningHoursSpecification",
                    "dayOfWeek": [
                        "Monday",
                        "Tuesday",
                        "Wednesday",
                        "Thursday",
                        "Friday"
                    ],
                },
                "opens": "10:00",
                "closes": "17:00",
                "aggregateRating": {
                    "@type": "AggregateRating",
                    "ratingValue": 5,
                    "bestRating": 5,
                    "reviewCount": 11
                }
            }
        ]
    }

    if (yoast_meta && yoast_title) {
        return (
            <Helmet>
                <script className='structured-data-list' type="application/ld+json">
                    {JSON.stringify(microdata)}
                </script>

                <link rel="alternate" hrefLang="de" href={`${window.location.origin}${location.pathname}`} />

                {
                    hreflangs && hreflangs.length > 0 ? (
                        hreflangs.map(({ hreflang }, key) => {
                            return (
                                <link rel="alternate" hrefLang={hreflang.lang_code} href={hreflang.page_url} key={key} />
                            )
                        })
                    ) : ''
                }

                {
                    yoast_meta.map(function (item, key) {
                        let tadKeys = Object.keys(item);
                        let opts = {};

                        if (item[tadKeys[0]] === 'og:url') {
                            item.content = `${window.location.origin}${location.pathname}`;
                        }

                        opts[tadKeys[0]] = item[tadKeys[0]];
                        opts[tadKeys[1]] = htmlEntities.decode(item.content);

                        return (
                            <meta key={key} {...opts}></meta>
                        );
                    })
                }
                <title>{htmlEntities.decode(yoast_title)}</title>
                <script src="https://apps.elfsight.com/p/platform.js" defer></script>
            </Helmet>
        );
    } else {
        return (
            <></>
        );
    }
};

export default withRouter(PageMeta)
