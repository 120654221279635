import React, {Component} from "react";
import Field from "./Field";

import "./Form.scss";

import axios from "axios";
import API from "../../../../api";
import { gtmPush } from "../../../../helpers/gtm";

class Form extends Component {

    state = {
        loaded_status: false,
        transform: "none",
        width: 50,
        formData: [],
        fieldsValue: [],
        fieldsError: [],
        formSuccess: false,
        loader: false,
        checkboxAgree: false,
        activeField: null,
        transition: false
    };

    componentDidMount() {

        const fields = this.props.content.form_fields.field;
        let fieldsValue = [];
        for (let i = 0; i < fields.length; i++) {
            fieldsValue[fields[i].field_name_attribute] = '';
        }

        this.setState({
            fieldsValue: fieldsValue,
            loaded_status: true
        });
    }

    mouseMove(e) {
        let w, h;
        w = window.innerWidth;
        h = window.innerHeight;

        if (w > 992) {
            document.getElementById("animationBox").classList.remove("transition");

            let x, y, rotateX, moveY,
                boxPos = document.getElementById("animationBox").offsetTop;

            if (e.pageX || e.pageY) {
                x = e.pageX;
                y = e.pageY;
            } else if (e.clientX || e.clientY) {
                x = e.clientX + document.body.scrollLeft + document.documentElement.scrollLeft;
                y = e.clientY + document.body.scrollTop + document.documentElement.scrollTop;
            }

            y = -(boxPos - y);

            rotateX = ((w / 2 - x) / 1000) / 4;
            moveY = (y - h / 2) / 25;

            let movePercent = (((w / 2 - x) * 100) / w) / 4;

            this.setState({
                transform: "matrix3d(" + Math.cos(rotateX) + ", 0, " + Math.sin(rotateX) + ", 0, 0, 1, 0, 0, " + Math.sin(rotateX) + ", 0, " + Math.cos(rotateX) + ", 0 , 0, " + moveY + ", 0, 1)",
                width: 50 + movePercent,
            });

            if(this.state.transition) {
                this.setState({
                    transition: !this.state.transition,
                });
            }
        }
    }

    mouseLive() {
        this.setState({
            transform: "none",
            width: 50,
            transition: !this.state.transition,
        });
    }

    sendForm = (e) => {
        e.preventDefault();

        let bodyFormData = new FormData();

        if(this.state.formData) {
            const fields = this.state.formData;

            for (var field in fields) {
                bodyFormData.append(field, fields[field]);
            }
        }

        if(this.state.checkboxAgree) {
            bodyFormData.append('agree', this.state.checkboxAgree);
        }

        this.setState({
            loader: true,
        });

        const cf7id = 645;
        axios({
            method: 'post',
            url: API.basePath+'/wp-json/contact-form-7/v1/contact-forms/'+cf7id+'/feedback',
            data: bodyFormData,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
        })
            .then(response => {

                let validationTimeout = setTimeout(()=> {

                    this.setState({
                        loader: false,
                        fieldsError: []
                    });

                    if(response.data.status === 'validation_failed') {
                        let responseErrors = response.data.invalid_fields;

                        let invalidFields = [];

                        for (let key = 0; key < responseErrors.length; key++) {
                            let error = responseErrors[key];
                            let label = error.field;

                            if(error.message !== '') {
                                invalidFields[label] = error.message;
                            } else {
                                delete invalidFields[label];
                            }

                            this.setState({
                                fieldsError: invalidFields,
                            });

                            this.scrollToInvalidField();
                        }

                    } else if(response.data.status === 'mail_sent') {

                        const fields = this.state.fieldsValue;
                        let fieldsValue = [];

                        for (var field in fields) {
                            fieldsValue[field] = '';
                        }

                        this.setState({
                            checkboxAgree: !this.state.checkboxAgree,
                            fieldsError: [],
                            formData: [],
                            fieldsValue: fieldsValue,
                            formSuccess: true
                        });

                        gtmPush({
                            event: 'cf7submission',
                            formId: cf7id
                        });
                    }

                }, 1000 );

                if(response.data.status === 'mail_sent') {

                    let successTimeout = setTimeout(() => {
                        clearTimeout(validationTimeout);
                        clearTimeout(successTimeout);

                        this.setState({
                            formSuccess: false
                        });

                    }, 2500);
                }
            })
    };

    scrollToInvalidField = () => {
        if(this.state.fieldsError) {
            let errorField = document.getElementsByClassName('form-field--error')[0];

            if (errorField) {
                let position  = errorField.getBoundingClientRect();
                if(position.top < 0) {
                    errorField.scrollIntoView();
                }
            }
        }
    };

    fieldFocus = (e) => {
        this.setState({
            activeField: e.target.name
        });
    };

    fieldFocusOut = (e) => {

        this.setState({
            activeField: null,
        });
    };

    inputHandler = (e) => {

        let values = {...this.state.fieldsValue, [e.target.name]: e.target.value};
        let formFieldsData = {...this.state.formData, [e.target.name]: e.target.value};

        this.setState({
            fieldsValue: values,
            formData: formFieldsData
        });
    };

    agreeCheckbox = (e) => {
        this.setState({
            checkboxAgree: !this.state.checkboxAgree
        });
    };

    clearInput = (id) => {

        let values = {...this.state.fieldsValue, [id]: ''};
        let formFieldsData = {...this.state.formData, [id]: ''};

        this.setState({
            fieldsValue: values,
            formData: formFieldsData
        });
    }

    render() {

        if (this.state.loaded_status && this.props.content) {

            const form_data = this.props.content.form_fields;
            const invalidFields = this.state.fieldsError;

            const left_col = this.props.content.left_column;
            const right_col = this.props.content.right_column;

            return (
                <div className={`cookie-feedback${this.state.transition ? ' transition':""}`} id="animationBox" onMouseMove={this.mouseMove.bind(this)} onMouseLeave={this.mouseLive.bind(this)}>
                    <div className="cookie-feedback__animation">
                        <div className="cookie-feedback-animation cookie-feedback-animation__front" style={{width:this.state.width+"%"}}>
                            <div className="cookie-feedback-animation__content">
                                <div className="cookie-feedback-animation__image">
                                    <img src={left_col.image.url} alt="" style={{transform:this.state.transform}}/>
                                </div>
                            </div>
                        </div>
                        <div className="cookie-feedback-animation cookie-feedback-animation__back">
                            <div className="cookie-feedback-animation__content">
                                <div className="cookie-feedback-animation__image">
                                    <img src={right_col.image.url} alt="" style={{transform:this.state.transform}}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="cookie-feedback__content">
                        <div className="cookie-feedback__col cookie-feedback__col--info">
                            <div className="cookie-feedback-info">
                                <div className="cookie-feedback-info__title">
                                    <h2 className="cookie-feedback-info__title-text"
                                         dangerouslySetInnerHTML={{ __html: left_col.title}} />
                                    <div className="cookie-feedback-info__title-bg">
                                        <span dangerouslySetInnerHTML={{ __html: left_col.title}} />
                                    </div>
                                </div>
                                <div className="cookie-feedback-info__description">{left_col.description}</div>
                            </div>
                        </div>
                        <div className="cookie-feedback__col cookie-feedback__col--form">
                            {
                                this.props.content.mobile_image.url ? (
                                    <div className="cookie-feedback__col-im">
                                        <img src={this.props.content.mobile_image.url} alt=""/>
                                    </div>
                                ) : ('')
                            }
                            <div className="cookie-feedback-form">
                                <div className="cookie-feedback-form__form-wrapper">
                                    <div className="cookie-feedback-form__form">
                                        <form autoComplete="off">
                                            {
                                                form_data.field.map((item, key) => {
                                                    return (
                                                        <div className={`cookie-feedback-form__form-field form-field${(this.state.activeField === item.field_name_attribute) ? " form-field--active" : ''}${(this.state.fieldsValue[item.field_name_attribute] !== '') ? ' form-field--filled' : ''}${(invalidFields[item.field_name_attribute]) ? ' form-field--error' : ''}`} key={key}>
                                                            <Field fieldData={item} error={invalidFields[item.field_name_attribute]} value={this.state.fieldsValue[item.field_name_attribute]} change={this.inputHandler} blur={this.fieldFocusOut} focus={this.fieldFocus} click={this.clearInput} requiredLabel={form_data.required_label}/>
                                                        </div>
                                                    )
                                                })
                                            }

                                            <div className="cookie-feedback-form__form-checkbox">
                                                <div className={`checkbox-field${(invalidFields['agree']) ? ' checkbox-field--not-checked' : ''}`}>
                                                    <input id="agree" name="agree" checked={this.state.checkboxAgree} type="checkbox" onChange={this.agreeCheckbox}/>
                                                    <label htmlFor="agree">{form_data.agree_field_label}</label>
                                                    <i className="icon-error"></i>
                                                </div>
                                            </div>

                                            <div className="cookie-feedback-form__form-submit form-submit">
                                                <button onClick={this.sendForm} className={(this.state.loader) ? "loading" : ''}>
                                                    <div className={`form-submit__label${(this.state.loader || this.state.formSuccess) ? " form-submit__label--hidden" : ''}`}>
                                                        <div className="form-submit__label-text">{form_data.submit_label}</div>
                                                        <div className="form-submit__icon">
                                                            <i className="icon-send"></i>
                                                        </div>
                                                    </div>
                                                    <div className={`form-submit__loader${(this.state.loader) ? " form-submit__loader--visible" : ''}`}>
                                                        <div className="lds-dual-ring"></div>
                                                    </div>
                                                    <div className={`form-submit__success${(this.state.formSuccess) ? " form-submit__success--visible" : ''}`}>{form_data.submit_success_label}</div>
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className='cookie-feedback'>
                </div>
            );
        }
    }
}

export default Form;