import React, { useState, useEffect, useRef, useContext, Fragment } from 'react';
import ThemeGeneralSettingsContext from '../../../../ThemeGeneralSettingsContext';
import NotFound from '../../../Pages/NotFound/NotFound';
import PostCategory from './PostCategory';
import useMobile from "../../../../hooks/useMobile";
import PostList from './PostList';
import PostImages from '../Posts/PostImages';
import ContactForm from "../../../Forms/ContactForm";
import { withRouter } from 'react-router';
import axios from 'axios';
import API from '../../../../api';
import '../Posts/Post.scss';
import SocialLink from './SocialLink/SocialLink';
import PostSlider from './PostSlider';
import { Link } from 'react-router-dom';
import { animateScroll as scroll } from "react-scroll";
import PageMeta from "../../../PageMeta/PageMeta";
import Loader from '../../../Loader/Loader';
import { Html5Entities } from 'html-entities';
import leftImage from './images/post_left.png';
import rightImage from './images/post_right.png';
import useReadingTime from '../../../../hooks/useReadingTime'
import Info from "./Info/Info";

const htmlEntities = new Html5Entities();

const Post = (props) => {
    const { populartagsBreadcrumbs, authorBreadcrumbs, categoriesBreadcrumbs, onblogBreadcrumbs } = props;
    const { slug } = props.match.params;
    const contentRef = useRef(null);
    const postContentsRef = useRef(null);
    const [isActive, setIsActive] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [windowWidth, setWindowWidth] = useState(0);
    const [pageData, setPageData] = useState([]);
    const [tags, setTags] = useState([]);
    const [postOptions, setPostOptions] = useState([]);
    const [posts, setPosts] = useState([]);
    const [sliderGallery, setSliderGallery] = useState([]);
    const [sliderDescription, setSliderDescription] = useState([]);
    const [yoast_meta, setYoast_meta] = useState([]);
    const [categories, setCategories] = useState([]);
    const [dataStatus, setDataStatus] = useState(false);
    const [lefSideBarHeight, setLefSideBarHeight] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const isMobile = useMobile();

    const { certificates, contact_form } = useContext(ThemeGeneralSettingsContext);
    const { shopware_developer, shopware_designer, usercentrics } = certificates;

    const changedScrollPosition = () => {
        if (contentRef.current) {
            const distanceToTop = contentRef.current.getBoundingClientRect().top;
            if (distanceToTop > 0) {
                setIsActive(false)
            } else {
                setIsActive(true)
                setIsVisible(true)
            }
        }
    }

    const getWindowWidth = () => {
        setWindowWidth(window.innerWidth);
    }

    document.querySelectorAll('.contents a').forEach((link) => {
        link.onclick = function (e) {
            e.preventDefault()
            let section = document.querySelector(link.getAttribute('href'))?.offsetTop;

            if (section !== undefined) {
                scroll.scrollTo(section, {
                    duration: 700,
                    delay: 0,
                    smooth: "easeOutCubic",
                    offset: -50,
                });
            }
        };
    })

    const loadData = async () => {
        setDataStatus(false)
        const API_POST_PER_PAGE = 4;
        const offset = currentPage * API_POST_PER_PAGE;

        const responseCategories = await axios
            .get(API.basePath + '/wp-json/wp/v2/categories?slug')
        setCategories(responseCategories.data);

        const responsecontent = await axios.get(API.basePath + '/wp-json/acf/v3/options/post-general-settings')
        setPostOptions(responsecontent.data);

        const responsePost = await axios
            .get(API.basePath + '/wp-json/wp/v2/posts?_embed=true&slug=' + slug)
        setPageData(responsePost.data);
        setYoast_meta(responsePost.data[0]);
        const responseRecentPosts = await axios
            .get(
                API.basePath +
                '/wp-json/wp/v2/posts?per_page=' +
                API_POST_PER_PAGE +
                '&offset=' +
                offset
            )
        const recentPosts = [];
        responseRecentPosts.data.forEach((item) => {
            if (responsePost.data[0] !== undefined) {
                if (responsePost.data[0].id !== item.id) {
                    recentPosts.push(item)
                }
            }
        })
        const newRecentPosts = recentPosts.slice(0, 3)
        setPosts(newRecentPosts);

        try {
            const tagsResult = await axios.get(API.basePath + "/wp-json/wp/v2/tags?post=" + responsePost.data[0].id);
            setTags(tagsResult.data)
        } catch (e) {
            console.log(e);
        }

        setDataStatus(true);

        if (responsePost.data[0]) {
            if (responsePost.data[0].acf.sliders) {
                responsePost.data[0].acf.sliders.map((item, key) => {
                    setSliderGallery(sliderGallery => [...sliderGallery, item.slider.gallery]);
                    setSliderDescription(sliderDescription => [...sliderDescription, item.slider.all_images_without_description]);
                })
            }
        }

        props.setHeaderOptions('', true, true)
    }

    function contentsHeight() {
        if (postContentsRef.current !== null) {
            setLefSideBarHeight(postContentsRef.current.offsetHeight);
        }
    }

    useEffect(() => {
        loadData();

        window.addEventListener("scroll", changedScrollPosition);
        window.addEventListener("resize", getWindowWidth);
        return () => {
            window.removeEventListener("scroll", changedScrollPosition);
            window.removeEventListener("resize", getWindowWidth);

        };
    }, [slug]);

    useEffect(() => {
        getWindowWidth()
    }, [])

    useEffect(() => {
        contentsHeight()
    }, [postContentsRef.current])

    if (!dataStatus) {
        return <div className="blog-page-loader">
            <Loader />
        </div>
    } else {
        if (pageData && pageData.length) {
            const item = pageData[0]
            const postDate = new Date(item.date);
            const editDate = new Date(item.modified);
            const formattedDate = `${postDate.getDate() < 10 ? '0' + postDate.getDate() : postDate.getDate()}.${(postDate.getMonth() + 1) < 10 ? '0' + (postDate.getMonth() + 1) : (postDate.getMonth() + 1)}.${postDate.getFullYear()}`;
            const editedDate = `${editDate.getDate() < 10 ? '0' + editDate.getDate() : editDate.getDate()}.${(editDate.getMonth() + 1) < 10 ? '0' + (editDate.getMonth() + 1) : (editDate.getMonth() + 1)}.${editDate.getFullYear()}`;
            const title = item.title.rendered

            let div = document.createElement('div');
            div.innerHTML = item.content.rendered;
            const contents = div.querySelector('.contents');

            if (contents) {
                div.removeChild(contents);
            }

            let allContent = item.content.rendered;
            let contentArr = allContent.split('<p>Slider</p>')

            let contentPart1 = document.createElement('div');
            contentPart1.innerHTML = contentArr[0];

            const contents1 = contentPart1.querySelector('.contents');

            if (sliderGallery[0]) {
                if (contents1) {
                    contentPart1.removeChild(contents1);
                }
            }

            const postMicrodata = {
                "@context": "https://schema.org/",
                "@graph": [
                    {
                        "@type": "BreadcrumbList",
                        "@id": `${window.origin}/blog#breadcrumb`,
                        "itemListElement": [
                            { "@type": "ListItem", "position": 1, "name": "Startseite", "item": `${window.origin}` },
                            { "@type": "ListItem", "position": 2, "name": "Blog", "item": `${window.origin}/blog` },
                            { "@type": "ListItem", "position": 3, "name": `${title}` }
                        ]
                    },
                    {
                        "@type": "Article",
                        "@id": `${window.location.href}#article`,
                        "isPartOf": { 
                            "@id": `${window.location.href}#webpage` },
                        "author": {
                            "@type": "Person",
                            "name": `${item._embedded.author[0].name}`,
                            "@id": `${window.origin}/blog/autoren/${item._embedded.author[0].slug}#Person`,
                            "url": `${window.origin}/blog/autoren/${item._embedded.author[0].slug}`
                        },
                        "headline": `${title}`,
                        "datePublished": `${item.date}`,
                        "dateModified": `${item.modified}`,
                        "mainEntityOfPage": { "@id": `${window.location.href}#webpage` },
                        "publisher": { "@id": `${window.origin}#organization` },
                        "inLanguage": "de-DE"
                    }
                ]
            }

            const { calculateEstimation } = useReadingTime({
                show: postOptions?.acf?.reading_time?.show,
                wordsPerMinute: postOptions?.acf?.reading_time?.words_per_minute,
            })

            return (
                <>
                    <PageMeta metaData={yoast_meta} />
                    <script className='structured-data-list' type="application/ld+json">
                        {JSON.stringify(postMicrodata)}
                    </script>
                    <section className="post" id="post">
                        <img src={leftImage} className="background_left" alt="" />
                        <img src={rightImage} className="background_right" alt="" />
                        <Fragment key={`post__header__${item.id}`}>
                            <div className="post__content" id="post__content" ref={contentRef}>
                                <div className={lefSideBarHeight <= window.innerHeight - 100 && lefSideBarHeight !== 0 ? "post__content-left small" : "post__content-left"} ref={postContentsRef}>
                                    {window.innerWidth >= 992 && contents &&
                                        <div className="post-contents">
                                            <span>{postOptions.acf.contents_title}</span>
                                            <ol className="contents" dangerouslySetInnerHTML={{ __html: contents.innerHTML }} />
                                        </div>
                                    }
                                </div>
                                <div className={contents ? "post__content-inner post__content-inner-withcontent" : "post__content-inner"}>
                                    <div className="post__breadcrumbs">
                                        <Link to='/' className="home-link">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="12" cy="12" r="12" fill="url(#paint0_linear_10126_3637)"/>
                                                <path d="M18.9692 17.4411C18.9729 17.6282 18.9393 17.8141 18.8705 17.9881C18.8016 18.162 18.6989 18.3205 18.5683 18.4542C18.4377 18.588 18.2818 18.6943 18.1098 18.767C17.9377 18.8397 17.7529 18.8774 17.5662 18.8777C17.3779 18.878 17.1914 18.841 17.0173 18.7689C16.8433 18.6968 16.6851 18.591 16.552 18.4575C16.4188 18.3241 16.3132 18.1656 16.2413 17.9912C16.1693 17.8167 16.1324 17.6298 16.1327 17.4411V16.8297C15.4922 17.8996 13.7232 19 11.7406 19C8.05005 19 5 15.8821 5 12C5.00066 11.0784 5.18279 10.1659 5.53594 9.31497C5.8891 8.464 6.40633 7.69123 7.05802 7.04096C7.7097 6.39068 8.48301 5.87568 9.33365 5.52546C10.1843 5.17524 11.0955 4.99669 12.0151 5.00005C16.3462 5.00005 19.0302 8.24021 18.9997 12L18.9692 17.4411ZM12.0151 7.64924C9.57508 7.64924 7.98905 9.60556 7.98905 11.9898C7.98905 14.3741 9.57508 16.3304 12.0151 16.3304C14.4552 16.3304 16.0412 14.3741 16.0412 11.9898C16.0412 9.60556 14.4552 7.64924 12.0151 7.64924Z" fill="white"/>
                                                <defs>
                                                <linearGradient id="paint0_linear_10126_3637" x1="24" y1="24" x2="0" y2="0" gradientUnits="userSpaceOnUse">
                                                <stop stopColor="#FC6076"/>
                                                <stop offset="0.484375" stopColor="#FF4D66"/>
                                                <stop offset="1" stopColor="#FF6826"/>
                                                </linearGradient>
                                                </defs>
                                            </svg>
                                        </Link>
                                        <div className="post__breadcrumbs_item post__breadcrumbs_item--page">
                                            <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M7.18436 11.3545C7.62418 10.9476 7.62418 10.2524 7.18436 9.84554L3.02703 6L7.18436 2.15446C7.62418 1.74763 7.62418 1.05237 7.18436 0.645537C6.79045 0.281168 6.18252 0.281169 5.78861 0.645537L6.91406e-07 6L5.78861 11.3545C6.18252 11.7188 6.79045 11.7188 7.18436 11.3545Z" fill="#3E3E53" />
                                            </svg>
                                            <Link to='/blog' onClick={() => { onblogBreadcrumbs() }}><span>Blog</span></Link>
                                        </div>
                                        {(title) &&
                                            <div className="post__breadcrumbs_item">
                                                <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M7.18436 11.3545C7.62418 10.9476 7.62418 10.2524 7.18436 9.84554L3.02703 6L7.18436 2.15446C7.62418 1.74763 7.62418 1.05237 7.18436 0.645537C6.79045 0.281168 6.18252 0.281169 5.78861 0.645537L6.91406e-07 6L5.78861 11.3545C6.18252 11.7188 6.79045 11.7188 7.18436 11.3545Z" fill="#3E3E53" />
                                                </svg>
                                                <span>{htmlEntities.decode(title.replace('<br/>', ''))}</span>
                                            </div>
                                        }
                                    </div>
                                    <h1 className="post-title" dangerouslySetInnerHTML={{ __html: title }} />
                                    {item.acf.subtitle &&
                                        <p className="post-subtitle">{item.acf.subtitle}</p>
                                    }
                                    <div className="post-featured-image">
                                        <PostImages postId={item.featured_media} />
                                    </div>
                                    <Info
                                        postOptions={postOptions}
                                        allContent={allContent}
                                        formattedDate={formattedDate}
                                        editedDate={editedDate}
                                        item={item}
                                        authorBreadcrumbs={authorBreadcrumbs}
                                    />
                                    {window.innerWidth < 992 && contents &&
                                        <div className="post-contents">
                                            <span>{postOptions.acf.contents_title}</span>
                                            <ol className="contents" dangerouslySetInnerHTML={{ __html: contents.innerHTML }} />
                                        </div>
                                    }
                                    {sliderGallery[0] ?
                                        <>
                                            {contentArr.map((item, key) => {
                                                if (key < 1) {
                                                    return <div className="posting" dangerouslySetInnerHTML={{ __html: contentPart1.innerHTML }} key={key} />
                                                } else {
                                                    return (
                                                        <>
                                                            <PostSlider gallery={sliderGallery[key - 1]} descriptionStatus={sliderDescription[key - 1]} />
                                                            <div className="posting" dangerouslySetInnerHTML={{ __html: item }} />
                                                        </>
                                                    )
                                                }
                                            })
                                            }
                                        </>
                                        :
                                        <>
                                            <div className="posting" dangerouslySetInnerHTML={{ __html: div.innerHTML }} />
                                        </>
                                    }

                                    {item.acf.show_contact_button && <ContactForm form_data={contact_form} />}

                                    {
                                        tags.some(e => e.name === 'shopware') ? (
                                            <div className="post-certificates">
                                                <div className="post-certificates--inner">
                                                    <img src={shopware_developer.url} alt={shopware_developer.alt} />
                                                    <img src={shopware_designer.url} alt={shopware_designer.alt} />
                                                </div>
                                            </div>	
                                        ) : tags.some(e => e.name === 'usercentrics') && (
                                            <div className="post-certificates">
                                                <div className="post-certificates--inner">
                                                    <img src={usercentrics.url} alt={usercentrics.alt} />
                                                </div>
                                            </div>	
                                        )
                                    }

                                    {!isMobile &&
                                        <>
                                            <div className="socials">
                                                <span>{postOptions.acf.share.title}</span>
                                                <div className="post-socials">
                                                    {postOptions.acf && postOptions.acf !== undefined ?
                                                        postOptions.acf.share.socials.map((link, key) => {
                                                            return <SocialLink postTitle={title} link={link} key={key} />
                                                        }) : null
                                                    }
                                                </div>
                                            </div>
                                            <div className="linkedin-link">
                                                <a href={postOptions.acf.follow_us.link.url} target="_blank" rel="nofollow noopener">
                                                    <span>{postOptions.acf.follow_us.text}</span>
                                                    <img src={postOptions.acf.follow_us.image.url} alt={postOptions.acf.follow_us.image.alt} />
                                                </a>
                                            </div>
                                        </>
                                    }
                                </div>
                                <div className="post__content-right">
                                    <div className="post__content-right-socials">
                                        <h2 className="post__content-right-title title-socials">
                                            <span>{postOptions.acf.share.title}</span>
                                        </h2>
                                        <div className="post-socials">
                                            {postOptions.acf.share ?
                                                postOptions.acf.share.socials.map((link, key) => {
                                                    return <SocialLink postTitle={title} link={link} key={key} />
                                                }) : null
                                            }
                                        </div>
                                    </div>
                                    <div className="post__content-right-categories">
                                        <h2 className="post__content-right-title title-categories">
                                            <span>{postOptions.acf.sublist.categories_title}</span>
                                        </h2>
                                        <PostCategory post={item.id} isBig categoriesBreadcrumbs={categoriesBreadcrumbs} />
                                    </div>
                                    {item._embedded.author[0].description &&
                                        <div className="post__content-right-autor">
                                            <h2 className="post__content-right-title title-author">
                                                <span>{postOptions.acf.sublist.about_author_title} </span>
                                                <span>{htmlEntities.decode(item._embedded.author[0].name)}:</span>
                                            </h2>
                                            <p>{htmlEntities.decode(item._embedded.author[0].description)}</p>
                                        </div>
                                    }
                                    <div className="post__content-right-tags">
                                        <h2 className="post__content-right-title title-tags">
                                            <span>{postOptions.acf.sublist.tags_title}</span>
                                        </h2>
                                        <div className="post-tags">
                                            {tags.map((tag) => {
                                                return (
                                                    <Link key={tag.id} className="tag" to={`/blog?tag=${tag.slug}`} onClick={() => populartagsBreadcrumbs(tag.name, tag.count)}>
                                                        <span>{`#${tag.name}`}</span>
                                                    </Link>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>

                                {isMobile &&
                                    <div className="linkedin-link">
                                        <a href={postOptions.acf.follow_us.link.url} target="_blank" rel="nofollow noopener">
                                            <span>{postOptions.acf.follow_us.text}</span>
                                            <img src={postOptions.acf.follow_us.image.url} alt={postOptions.acf.follow_us.image.alt} />
                                        </a>
                                    </div>
                                }
                            </div>
                            <div className="post__content__last-posts">
                                <h2 className="post__content-right-title title-post">{postOptions.acf.recent_posts_title}</h2>
                                <PostList post={posts} postType={'posts'} categoriesBreadcrumbs={categoriesBreadcrumbs} readMoreButton={postOptions.acf.read_more_button_text} />
                            </div>
                        </Fragment>
                    </section>
                </>
            );
        } else {
            return (
                <NotFound setContactElements={props.setContactElements} />
            )
        }

    }
};
export default withRouter(Post);
