import React, {useEffect, useState} from "react";

import "./TextPage.scss";

import axios from "axios";
import API from "../../../api";
import Loader from "../../../components/Loader/Loader";

import PageMeta from "../../PageMeta/PageMeta";
import CustomSchema from "../../CustomSchema/CustomSchema";
import {decodeHtmlEntity} from "../../../helpers/decodeHtmlEntity";

const TextPage = (props) => {

    const [page_data, setPage_data] = useState([]);
    const [yoast_meta, setYoast_meta] = useState([]);
    const [loaded_status, setLoaded_status] = useState(false);

    useEffect(() => {
        const slug = props.location.pathname;

        axios.get(API.basePath+'/wp-json/wp/v2/pages?slug='+slug.substr(1))
            .then(response => {
                setPage_data(response.data[0]);
                setYoast_meta(response.data[0]);
                setLoaded_status(true);
            })
            .catch(error => {
                console.log(error);
            });

        props.setContactElements();
    }, [props.match.params.slug]);

    if (loaded_status) {

        return (
            <>
                <PageMeta metaData={yoast_meta || null} hreflangs={page_data.acf['hreflang_list'] || null} />
                <CustomSchema schema={page_data.acf.schema} />
                <div className="text-page">
                    <div className="text-page__top-bg"></div>
                    <div className="text-page__bottom-bg"></div>
                    <div className="text-page__container">
                        <div className="text-page__title">
                            <h1 className="text-page__title-text">{decodeHtmlEntity(page_data.title.rendered)}</h1>
                            <div className="text-page__title-bg">{decodeHtmlEntity(page_data.title.rendered)}</div>
                        </div>
                        <div className="text-page__content" dangerouslySetInnerHTML={{__html:page_data.content.rendered}}/>
                    </div>
                </div>
            </>
        );
    } else {
        return (
            <>
                <div className="text-page text-page--preloader">
                    <div className="text-page__top-bg"></div>
                    <div className="text-page__bottom-bg"></div>
                    <Loader />
                </div>
            </>
        );
    }

};

export default TextPage;