import React from "react";

const ThemeGeneralSettingsContext = React.createContext({
    certificates: {
        shopware_developer: {},
        shopware_designer: {},
        usercentrics: {},
        erecht24: {},
    },
    contact_form: {}
});

export default ThemeGeneralSettingsContext