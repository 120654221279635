import React, { useState, useEffect, useRef } from 'react';
import cn from 'classnames';

import "./ContactForm.scss";

import axios from "axios";
import API from "../../api";
import { gtmPush } from "../../helpers/gtm";
import Field from "./Field";
import Button from "./Button";

const ContactForm = (props) => {
    const { form_data, fieldsValueInit, heading, isContactPage } = props
    const stepsAmount = form_data.steps && form_data.steps.length

    const [isFormSuccess, setIsFormSuccess] = useState(false)
    const [fieldsValue, setFieldsValue] = useState(fieldsValueInit || [])
    const [isLoading, setIsLoading] = useState(false)
    const [isCheckboxAgree, setIsCheckboxAgree] = useState(false)
    const [fieldsError, setFieldsError] = useState({})
    const [step, setStep] = useState(1)

    const contactFormRef = useRef(null)

    const firstStepFields = form_data.field && form_data.field.filter(item => item.step === 'first')
    const secondStepFields = form_data.field && form_data.field.filter(item => item.step === 'second')

    // steps
    const stepStart = stepsAmount - 3
    const stepFieldsFirst = stepsAmount - 2
    const stepFieldsSecond = stepsAmount - 1
    const stepSuccess = stepsAmount

    const stepTitle = form_data.steps && step !== stepSuccess && form_data.steps[step - 1].step_title
    const stepImage = form_data.steps && form_data.steps[step - 1].step_image

    useEffect(() => {
        let isErrorPrevStep = false
        for (let key in fieldsError) {
            if (firstStepFields.some(item => item.field_name_attribute === key)) {
                isErrorPrevStep = true
                break
            }
        }

        isErrorPrevStep && setStep(stepFieldsFirst)

    }, [fieldsError])

    const inputHandler = (id, value) => {
        const values = {...fieldsValue, [id]: value};

        setFieldsValue(values)
    };

    const checkboxAgreeHandler = () => {
        setIsCheckboxAgree(isCheckboxAgree => !isCheckboxAgree)
    };

    const scrollToInvalidField = () => {
        if(fieldsError) {
            let errorField = document.getElementsByClassName('field--error')[0];

            if (errorField) {
                let position  = errorField.getBoundingClientRect();
                if(position.top < 0) {
                    errorField.scrollIntoView();
                }
            }
        }
    };

    const prevStep = () => {
        setStep(step => step < 2 ? 1 : step - 1)
    }

    const resetSteps = () => {
        setStep(1)
        setIsFormSuccess(false)
    }

    useEffect(() => {
        isFormSuccess && setStep(stepsAmount)
    }, [isFormSuccess])

    

    const sendForm = (e) => {
        e.preventDefault();

        if(step !== stepFieldsSecond) {
            setStep(step => step + 1)

            return false
        }

        let bodyFormData = new FormData();

        if(fieldsValue) {
            const fields = fieldsValue;
            
            for (var field in fields) {
                bodyFormData.append(field, fields[field]);
            }
        }
        
        if(isCheckboxAgree) {
            bodyFormData.append('url', window.location.href);
            bodyFormData.append('agree', isCheckboxAgree);
        }

        setIsLoading(true)

        const cf7id = 10;
        axios({
            method: 'post',
            url: API.basePath+'/wp-json/contact-form-7/v1/contact-forms/'+cf7id+'/feedback',
            data: bodyFormData,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
        })
            .then(response => {

                let validationTimeout = setTimeout(()=> {

                    setIsLoading(false)
                    setFieldsError({})
                    

                    if(response.data.status === 'validation_failed') {
                        let responseErrors = response.data.invalid_fields;

                        let invalidFields = [];

                        for (let key = 0; key < responseErrors.length; key++) {
                            let error = responseErrors[key];
                            let label = error.field;

                            if(error.message !== '') {
                                invalidFields[label] = error.message;
                            } else {
                                delete invalidFields[label];
                            }
                            
                            setFieldsError(prevState => ({
                                ...prevState,
                                ...invalidFields
                            }));

                        }
                        scrollToInvalidField();

                    } else if(response.data.status === 'mail_sent') {

                        const fields = fieldsValue;
                        let fieldsValue = [];
                        let w = window.innerWidth;

                        for (var field in fields) {
                            fieldsValue[field] = '';
                        }

                        setIsFormSuccess(true)
                        setFieldsValue(fieldsValue)
                        setIsCheckboxAgree(!isCheckboxAgree)
                        setFieldsError({})

                        gtmPush({
                            event: 'cf7submission',
                            formId: cf7id
                        });

                        if (w < 992) {
                            window.scrollTo({
                                top: contactFormRef.current.getBoundingClientRect().top + window.pageYOffset,
                                behavior: "smooth"
                            });
                        }

                        clearTimeout(validationTimeout);
                    }

                }, 1000 );
            })
            .catch(error => {
                console.error(error);

                setIsLoading(false)
            });
    };

    return (
        <div
            className={cn(
                'contact-form',
                {
                    'loading-block loading-block--2 contact-form--page': isContactPage,
                    'contact-form--block'   : !isContactPage,
                    'contact-form--start'   : step === stepStart,
                    'contact-form--fields-1': step === stepFieldsFirst,
                    'contact-form--fields-2': step === stepFieldsSecond,
                    'contact-form--success' : isFormSuccess
                }
            )}
            ref={contactFormRef}
        >
            <div className="contact-form__subtitle">{form_data.steps && form_data.steps[step - 1].step_name}</div>
            <div className="contact-form__glow"></div>
            <div className="contact-form__triangle"></div>
            <div className="contact-form__lines contact-form__lines--1"></div>
            <div className="contact-form__lines contact-form__lines--2"></div>
            
            {heading && <div className="contact-form__heading contact-form__heading--desktop-hidden" dangerouslySetInnerHTML={{ __html: heading}} />}
            <form
                className="contact-form__form"
                onSubmit={sendForm}
                autoComplete="off"
            >
                {form_data.steps && <div className="contact-form__count" dangerouslySetInnerHTML={{__html: form_data.steps[step - 1].step_counter}} />}

                {step !== 1 && step !== stepsAmount &&
                    <>
                        <div className="contact-form__back">
                            <Button
                                type="button"
                                buttonStyle="flat"
                                text={form_data.back_button_label}
                                clickHandler={prevStep}
                                iconName="icon-angle-left"
                            />
                        </div>
                        
                        {!isContactPage &&
                            <div className="contact-form__close">
                                <Button
                                    type="button"
                                    buttonStyle="flat"
                                    clickHandler={resetSteps}
                                    iconName="icon-close"
                                />
                            </div>
                        }
                    </>
                }

                {(stepImage || stepTitle) &&
                    <div className="contact-form__head">
                        {stepTitle && <div className="contact-form__title" dangerouslySetInnerHTML={{__html: stepTitle}} />}

                        {stepImage &&
                            <div className="contact-form__image">
                                <Image {...stepImage} />
                            </div>
                        }
                    </div>
                }

                {step === stepStart && (
                    <>
                        <div className="contact-form__body">
                            <Button
                                buttonStyle="secondary"
                                text={form_data.start_button_label}
                            />
                        </div>
                    </>
                )}

                {step === stepFieldsFirst && (
                    <>
                        <div className="contact-form__body">
                            {firstStepFields && firstStepFields.map(item => {
                                return (
                                    <Field
                                        fieldData={item}
                                        error={fieldsError[item.field_name_attribute]}
                                        value={fieldsValue[item.field_name_attribute]}
                                        changeHandler={inputHandler}
                                        requiredLabel={form_data.required_label}
                                        key={item.field_name_attribute}
                                    />
                                )
                            })}

                            <Button
                                buttonStyle="primary"
                                text={form_data.next_button_label}
                                iconName="icon-angle-right"
                                iconAfter
                            />
                        </div>
                    </>
                )}

                {step === stepFieldsSecond &&
                    <>
                        <div className="contact-form__body">
                            {secondStepFields && secondStepFields.map(item => {
                                return (
                                    <Field
                                        fieldData={item}
                                        error={fieldsError[item.field_name_attribute]}
                                        value={fieldsValue[item.field_name_attribute]}
                                        changeHandler={inputHandler}
                                        requiredLabel={form_data.required_label}
                                        key={item.field_name_attribute}
                                    />
                                )
                            })}

                            <div className="contact-form__checkbox">
                                <div className={`checkbox${(fieldsError['agree']) ? ' checkbox--not-checked' : ''}`}>
                                    <input required id="agree" name="agree" checked={isCheckboxAgree} type="checkbox" onChange={checkboxAgreeHandler}/>
                                    <label htmlFor="agree">{form_data.agree_field_label}</label>
                                    <i className="icon-error"></i>
                                </div>
                            </div>

                            <Button
                                buttonStyle="submit"
                                isLoading={isLoading}
                                text={form_data.submit_label}
                                isFormSuccess={isFormSuccess}
                            />
                        </div>
                    </>
                }

                {step === stepSuccess &&
                    <>
                        <div className="contact-form__success">
                            <div className="success__head">
                                <div className="success__title" dangerouslySetInnerHTML={{__html: form_data.steps[stepSuccess - 1].step_title}} />
                                <div className="success__img" dangerouslySetInnerHTML={{__html: form_data.success_image}}/>
                            </div>
                            <div className="success__text" dangerouslySetInnerHTML={{__html: form_data.success_text}}/>
                        </div>

                        {form_data.success_button_label &&
                            <div className="contact-form__body">
                                <Button
                                    type="button"
                                    buttonStyle="primary"
                                    text={form_data.success_button_label}
                                    clickHandler={resetSteps}
                                />
                            </div>
                        }
                    </>
                }
            
            </form>
        </div>
    )
};

export default ContactForm;

const Image = ({ url, alt, width, height }) => {
    const [isImageLoaded, setIsImageLoaded] = useState(false)
    const [newUrl, setNewUrl] = useState(url)

    useEffect(() => {
        setNewUrl(url)
        setIsImageLoaded(false)
    }, [url])

    const onLoad = () => {
        setIsImageLoaded(true);
    };

    return (
        <img
            src={newUrl}
            onLoad={onLoad}
            alt={alt}
            width={width}
            height={height}
            style={{ display: isImageLoaded ? '' : 'none' }}
        />
    );
}
