import React, { useEffect, useState } from "react";

import axios from "axios";

import API from "../../../../api";

import { Html5Entities } from 'html-entities';

const PostImages = (props) => {
  const [media, setMedia] = useState([]);
  const { postId, isBig } = props;
  const htmlEntities = new Html5Entities();

  useEffect(() => {
    if (postId) {
      (async () => {
        try {
          const res = await axios.get(
            API.basePath + "/wp-json/wp/v2/media/" + postId
          );

          if (res.data) {
            setMedia(res.data);
          } else {
            console.log(res);
          }
        } catch (e) {
          console.log(e);
        }
      })();
    }
    
  }, [postId]);

  if (media.media_details) {
    if(media.media_details.sizes.medium_large) {
      const img = media.media_details.sizes.medium_large.source_url;
      return <img className={isBig ? "post__img" : "articles__img"} style={{ display: "block", background: "#eee" }} src={img} alt={media.alt_text} title={htmlEntities.decode(media.title.rendered)} />
    } else {
      const img = media.link;
      return <img className={isBig ? "post__img" : "articles__img"} style={{ display: "block", background: "#eee" }} src={img} alt={media.alt_text} title={htmlEntities.decode(media.title.rendered)} />
    }
  } else {
    return null
  }
};

export default PostImages;
