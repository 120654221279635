import React, { useState, useEffect } from 'react';
import './RelevantPostsBlock.scss';
import axios from 'axios';
import API from '../../../api';
import { Link } from 'react-router-dom'
import PostImages from '../../Blog/Categories/Posts/PostImages'

const RelevantPostsBlock = ({ tag }) => {
    const [posts, setPosts] = useState([]);
    const [loadedStatus, setLoadedStatus] = useState(false);

    const fetchData = async () => {
        try {
            const postsData = await axios.get(API.basePath + `/wp-json/wp/v2/posts?tags=${tag}`)
            setPosts(postsData.data);
        } catch (e) {
            console.log(e);
        } finally {
            setLoadedStatus(true)
        }
    }

    useEffect(() => {
        fetchData()
    }, []);

    const recentPosts = posts.slice(0, 3);

    if (loadedStatus) {
        return (
            <ul>
                {recentPosts.map((post) => {
                    const postDate = new Date(post.date);
                    const postEditedDate = `${postDate.getDate() < 10 ? '0' + postDate.getDate() : postDate.getDate()}.${(postDate.getMonth() + 1) < 10 ? '0' + (postDate.getMonth() + 1) : (postDate.getMonth() + 1)}.${postDate.getFullYear()}`;

                    return (
                        <li className="relevant-post" key={post.title.rendered}>
                            <Link to={`/blog/${post.slug}`} className="relevant-post__image">
                                <PostImages postId={post.featured_media} />
                            </Link>
                            <div className="relevant-post__text-container">
                                <h3 className="relevant-post__title">
                                    <Link to={`/blog/${post.slug}`}>
                                        <span dangerouslySetInnerHTML={{ __html: post.title.rendered }}></span>
                                    </Link>
                                </h3>
                                <div
                                    className="relevant-post__excerpt"
                                    dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }}
                                />
                                <div className="relevant-post__bottom">
                                    <div className="relevant-post__date">
                                        <i>
                                            <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M15.75 2.5H15V0.833282C15 0.373383 14.664 0 14.25 0H13.5C13.086 0 12.75 0.373383 12.75 0.833282V2.5H5.24995V0.833282C5.24995 0.373383 4.91405 0 4.5 0H3.75005C3.336 0 2.99995 0.373383 2.99995 0.833282V2.5H2.25C1.00951 2.5 0 3.62167 0 5V17.5C0 18.8783 1.00951 20 2.25 20H15.75C16.9905 20 18 18.8783 18 17.5V5C18 3.62167 16.9905 2.5 15.75 2.5ZM16.5 17.5C16.5 17.9591 16.1632 18.3333 15.75 18.3333H2.25C1.83678 18.3333 1.50005 17.9591 1.50005 17.5V8.3667H16.5V17.5Z" fill="#FF4D66" />
                                            </svg>
                                        </i>
                                        <span>{postEditedDate}</span>
                                    </div>
                                    <Link to={`/blog/${post.slug}`} className="relevant-post__button">
                                        <span>Weiter lesen</span>
                                    </Link>
                                </div>
                            </div>
                        </li>
                    )
                })}
            </ul>
        );
    } else {
        return ''
    }
};
export default RelevantPostsBlock;
