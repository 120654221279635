import React, { useState, useRef, useEffect } from 'react';
import Swiper from 'react-id-swiper';
import FsLightbox from "fslightbox-react";


const PostSlider = (props) => {
    const [activeSlide, setActiveSlide] = useState(0);
    const gallerySwiperRef = useRef(null);
    const thumbnailSwiperRef = useRef(null);

    useEffect(() => {
        if (gallerySwiperRef.current !== null && thumbnailSwiperRef.current !== null) {
            const gallerySwiper = gallerySwiperRef.current.swiper;
            const thumbnailSwiper = thumbnailSwiperRef.current.swiper;

            if (gallerySwiper.controller && thumbnailSwiper.controller) {
                gallerySwiper.controller.control = thumbnailSwiper;
                thumbnailSwiper.controller.control = gallerySwiper;
            }
        }
    }, [gallerySwiperRef.current, thumbnailSwiperRef.current]);


    // Slider options
    const gallerySwiperParams = {
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
        },
        spaceBetween: 10,
        containerClass: "gallery-swiper swiper-container",
    };

    const thumbnailSwiperParams = {
        containerClass: "thumbnail-swiper swiper-container",
        centeredSlides: true,
        slidesPerView: 'auto',
        slideToClickedSlide: true,
    };

    //lightbox options
    const [lightboxController, setLightboxController] = useState({
        toggler: false,
        slide: 1
    });

    function openLightboxOnSlide(number) {
        setLightboxController({
            toggler: !lightboxController.toggler,
            slide: number
        });
    }

    if (props.gallery) {
        return (
            <>
                <Swiper {...gallerySwiperParams} ref={gallerySwiperRef}>
                    {props.gallery.map((photo, key) => {
                        return (
                            <div key={key} onClick={() => openLightboxOnSlide(key + 1)} className={props.descriptionStatus === true ? "without-description" : ""}>
                                <img src={photo.url} alt={photo.alt} />
                                <div className="photo-description">
                                    {photo.description &&
                                        <span>{photo.description}</span>
                                    }
                                </div>
                            </div>
                        )
                    })}
                </Swiper>

                <Swiper {...thumbnailSwiperParams} ref={thumbnailSwiperRef}>
                    {props.gallery.map((photo, key) => {
                        return (
                            <div key={key}>
                                <img src={photo.url} alt={photo.alt} />
                            </div>
                        )
                    })}
                </Swiper>

                <FsLightbox
                    toggler={lightboxController.toggler}
                    sources={
                        props.gallery.map((item, key) => {
                            return (
                                <div className="lightbox-image-container">
                                    <img src={item.url} alt={item.alt} key={key}></img>
                                    {item.description &&
                                        <span>{item.description}</span>
                                    }
                                </div>
                            )
                        })
                    }
                    slide={lightboxController.slide}
                />
            </>
        );
    } else {
        return <></>
    }

};

export default PostSlider;