import React, { useState, useEffect, useCallback } from 'react';
import cn from 'classnames';
import Swiper from "react-id-swiper";
import axios from 'axios';
import API from '../../../../api';
import useMobile from "../../../../hooks/useMobile";
import useElementOnScreen from "../../../../hooks/useElementOnScreen";
import Loader from "../../../Loader/Loader";
import PageMeta from "../../../PageMeta/PageMeta";
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom'
import NotFound from '../../NotFound/NotFound';
import PostImages from "../../../Blog/Categories/Posts/PostImages";
import RelevantPostsBlock from '../RelevantPostsBlock'
import './Post.scss';
import '../../ECommerce/Design/DesignAnimation.scss';

const Post = (props) => {
    const { slug } = props.match.params;
    const [pageData, setPageData] = useState([]);
    const [yoastMeta, setYoastMeta] = useState([]);
    const [loadedStatus, setLoadedStatus] = useState(false);
    const isMobile = useMobile();
    const [designSliderRef, setDesignSliderRef] = useState(null);

    const designSliderObserverOptions = {
        root: null,
        rootMargin: '0px',
        threshold: 0.5
    }

    const designSliderRefCallback = useCallback(node => {
        if (node !== null) {
            setDesignSliderRef(node)
        }
    }, [])

    const isVisible = useElementOnScreen(designSliderRef, designSliderObserverOptions, true);

    const fetchData = async () => {
        try {
            const pageData = await axios.get(API.basePath + `/wp-json/wp/v2/glossar?slug=${slug}`)
            setPageData(pageData.data);
            setYoastMeta(pageData.data[0]);
        } catch (e) {
            console.log(e);
        } finally {
            setLoadedStatus(true)
        }
    }

    useEffect(() => {
        fetchData()
    }, [slug]);

    if (loadedStatus) {
        if (pageData && pageData.length) {
            const post = pageData[0]
            const editedDate = new Date(post.date);
            const formattedEditedDate = `${editedDate.getDate() < 10 ? '0' + editedDate.getDate() : editedDate.getDate()}.${(editedDate.getMonth() + 1) < 10 ? '0' + (editedDate.getMonth() + 1) : (editedDate.getMonth() + 1)}.${editedDate.getFullYear()}`;
            const relevantGlossaryEntries = post.acf.relevant_glossary_entries
            const relevantPosts = post.acf.relevant_posts

            const postMicrodata = {
                "@context": "https://schema.org/",
                "@graph": [
                    {
                        "@type": "BreadcrumbList",
                        "@id": `${window.origin}/blog#breadcrumb`,
                        "itemListElement": [
                            {
                                "@type": "ListItem",
                                "position": 1,
                                "item": {
                                    "@id": window.origin,
                                    "name": "Startseite",
                                    "@type": "WebPage"
                                } 
                            },
                            {
                                "@type": "ListItem",
                                "position": 2,
                                "item": {
                                    "@id": window.location.origin + "/" + window.location.pathname.split('/')[1],
                                    "name": 'Glossar',
                                    "@type": "WebPage"
                                } 
                            },
                            {
                                "@type": "ListItem",
                                "position": 3,
                                "item": {
                                    "@id": window.location.origin + window.location.pathname,
                                    "name": post.title.rendered,
                                    "@type": "WebPage"
                                } 
                            }
                        ]
                    },
                    {
                        "@type": "Article",
                        "@id": `${window.location.origin + window.location.pathname}#article`,
                        "isPartOf": { 
                            "@id": `${window.location.origin + window.location.pathname}#webpage` },
                        "headline": post.title.rendered,
                        "datePublished": `${post.date}`,
                        "dateModified": `${post.modified}`,
                        "mainEntityOfPage": { "@id": `${window.location.origin + window.location.pathname}#webpage` },
                        "publisher": { "@id": `${window.origin}#organization` },
                        "inLanguage": "de-DE"
                    }
                ]
            }

            const animationBlockType = post.acf?.animation_block?.animation_type
            const isDesignSliderAnimation = animationBlockType === 'design_slider'
            const designSliderAnimationContent = isDesignSliderAnimation ? post.acf?.animation_block?.design_slider_content : {}

            const designSliderAnimationParams = {
                slidesPerView: 'auto',
                speed: 400,
                autoplay: true,
                loop: true,
            }

            return (
                <>
                    <PageMeta metaData={yoastMeta} />

                    <script className='structured-data-list' type="application/ld+json">
                        {JSON.stringify(postMicrodata)}
                    </script>
                    
                    <section className="glossary-post">
                        <div className="breadcrumbs">
                            <Link to='/' className="breadcrumbs__item">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="12" cy="12" r="12" fill="url(#paint0_linear_10126_3637)"/>
                                    <path d="M18.9692 17.4411C18.9729 17.6282 18.9393 17.8141 18.8705 17.9881C18.8016 18.162 18.6989 18.3205 18.5683 18.4542C18.4377 18.588 18.2818 18.6943 18.1098 18.767C17.9377 18.8397 17.7529 18.8774 17.5662 18.8777C17.3779 18.878 17.1914 18.841 17.0173 18.7689C16.8433 18.6968 16.6851 18.591 16.552 18.4575C16.4188 18.3241 16.3132 18.1656 16.2413 17.9912C16.1693 17.8167 16.1324 17.6298 16.1327 17.4411V16.8297C15.4922 17.8996 13.7232 19 11.7406 19C8.05005 19 5 15.8821 5 12C5.00066 11.0784 5.18279 10.1659 5.53594 9.31497C5.8891 8.464 6.40633 7.69123 7.05802 7.04096C7.7097 6.39068 8.48301 5.87568 9.33365 5.52546C10.1843 5.17524 11.0955 4.99669 12.0151 5.00005C16.3462 5.00005 19.0302 8.24021 18.9997 12L18.9692 17.4411ZM12.0151 7.64924C9.57508 7.64924 7.98905 9.60556 7.98905 11.9898C7.98905 14.3741 9.57508 16.3304 12.0151 16.3304C14.4552 16.3304 16.0412 14.3741 16.0412 11.9898C16.0412 9.60556 14.4552 7.64924 12.0151 7.64924Z" fill="white"/>
                                    <defs>
                                    <linearGradient id="paint0_linear_10126_3637" x1="24" y1="24" x2="0" y2="0" gradientUnits="userSpaceOnUse">
                                    <stop stopColor="#FC6076"/>
                                    <stop offset="0.484375" stopColor="#FF4D66"/>
                                    <stop offset="1" stopColor="#FF6826"/>
                                    </linearGradient>
                                    </defs>
                                </svg>
                            </Link>
                            <div className="breadcrumbs__item">
                                <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M7.18436 11.3545C7.62418 10.9476 7.62418 10.2524 7.18436 9.84554L3.02703 6L7.18436 2.15446C7.62418 1.74763 7.62418 1.05237 7.18436 0.645537C6.79045 0.281168 6.18252 0.281169 5.78861 0.645537L6.91406e-07 6L5.78861 11.3545C6.18252 11.7188 6.79045 11.7188 7.18436 11.3545Z" fill="#3E3E53" />
                                </svg>
                                <Link to='/glossar'>Glossar</Link>
                            </div>
                            <div className="breadcrumbs__item">
                                <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M7.18436 11.3545C7.62418 10.9476 7.62418 10.2524 7.18436 9.84554L3.02703 6L7.18436 2.15446C7.62418 1.74763 7.62418 1.05237 7.18436 0.645537C6.79045 0.281168 6.18252 0.281169 5.78861 0.645537L6.91406e-07 6L5.78861 11.3545C6.18252 11.7188 6.79045 11.7188 7.18436 11.3545Z" fill="#3E3E53" />
                                </svg>
                                <span>{post.title.rendered}</span>
                            </div>
                        </div>
                        <div className="glossary-post__content">
                            <article>
                                <h1>
                                    {post.acf.title ?
                                        post.acf.title :
                                        post.title.rendered
                                    }
                                </h1>
                                <div className="post-featured-image">
                                    <PostImages postId={post.featured_media}/>
                                </div>
                                <div className="glossary-post__date">
                                    <i>
                                        zuletzt aktualisiert: <strong>{formattedEditedDate}</strong>
                                    </i>
                                </div>

                                <div
                                    className="glossary-post__info"
                                    dangerouslySetInnerHTML={{ __html: post.content.rendered }}
                                />
                            </article>

                            {isDesignSliderAnimation &&
                                <div className={cn('design_slider', 'anim-item', isVisible && 'active')}  ref={designSliderRefCallback}>
                                    {isMobile ?
                                        <div className="slider-holder">
                                            <img className="e-commerce-page__image frame" src={designSliderAnimationContent.slider_frame.url} alt={designSliderAnimationContent.slider_frame.alt} />
                                            <Swiper {...designSliderAnimationParams}>
                                                {
                                                    designSliderAnimationContent.steps.map((item) => {
                                                        return (
                                                            <img
                                                                src={item.url}
                                                                alt={item.alt}
                                                                key={item.id}
                                                            />
                                                        )
                                                    })
                                                }
                                            </Swiper>
                                        </div>
                                        :
                                        <div className="design__phoneHolder">
                                            <img className="design__frame" src={designSliderAnimationContent.slider_frame.url} alt={designSliderAnimationContent.slider_frame.alt} />
                                            <div className="design__screensHolder">
                                                {!!designSliderAnimationContent.steps.length && designSliderAnimationContent.steps.map((item, key) => {
                                                    return (
                                                        <img
                                                            className={`design__screensHolder_item design__screensHolder_item--${key + 1}`}
                                                            src={item.url}
                                                            alt={item.alt}
                                                            key={item.id}
                                                        />
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    }
                                </div>
                            }

                            {relevantGlossaryEntries && relevantGlossaryEntries.list && (
                                <div className="relevats-words">
                                    <h2>{relevantGlossaryEntries.title}</h2>
                                    <ul>
                                        {relevantGlossaryEntries.list.map((item) => {
                                            return (
                                                <li key={item.post_title}>
                                                    <a href={`/glossar/${item.post_name}`}>
                                                        <i>
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M0.774289 0.0284424C0.360711 0.0284424 0.0283203 0.366645 0.0283203 0.780224V20.4382C0.0283203 22.4132 1.62498 23.9925 3.58257 23.9925H20.4323C22.4986 23.9925 23.9924 22.314 23.9924 20.4382C23.9924 20.4382 23.9924 15.3209 23.9924 12.7622C23.9924 12.3463 23.6565 12.0104 23.2406 12.0104H18.3758V0.78027C18.3758 0.385395 18.0583 0.0284893 17.6064 0.0284893L0.774289 0.0284424ZM4.52307 5.26911H13.8811C14.8806 5.26911 14.8806 6.76832 13.8811 6.76832H4.52307C3.5236 6.76836 3.5236 5.26911 4.52307 5.26911ZM4.52307 9.01789H13.8811C14.883 9.01789 14.883 10.5156 13.8811 10.5156H4.52307C3.52824 10.5156 3.52824 9.01789 4.52307 9.01789ZM4.52307 12.7594H13.8811C14.883 12.7594 14.883 14.2586 13.8811 14.2586H4.52307C3.52824 14.2586 3.52824 12.7594 4.52307 12.7594ZM18.3758 13.5082H22.4946V20.4383C22.4946 21.5916 21.5656 22.4948 20.4323 22.4948C19.299 22.4948 18.3758 21.5916 18.3758 20.4383V13.5082H18.3758ZM4.52307 16.5023H13.8811C14.883 16.5023 14.883 18 13.8811 18H4.52307C3.52824 18 3.52824 16.5023 4.52307 16.5023Z" fill="#3E3E53"/>
                                                            </svg>
                                                        </i>
                                                        <span>{item.post_title}</span>
                                                    </a>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            )}
                            {relevantPosts && relevantPosts.tag && (
                                <div className="relevant-posts">
                                    <h2>{relevantPosts.title}</h2>
                                    <RelevantPostsBlock tag={relevantPosts.tag} />
                                </div>
                            )}
                        </div>
                    </section>
                </>
            );
        } else {
            return (
                <NotFound setContactElements={props.setContactElements} />
            )
        }
    } else {
        return (
            <>
                <div className="glossary-post glossary-post--preloader">
                    <Loader />
                </div>
            </>
        );
    }
};
export default withRouter(Post);