import React, {Component} from "react";

import "./NotFound.scss";

import axios from "axios";
import API from "../../../api";
import Loader from "../../../components/Loader/Loader";
import {Link} from "react-router-dom";

import PageMeta from "../../PageMeta/PageMeta";
import CustomSchema from "../../CustomSchema/CustomSchema";

class NotFound extends Component {

    state = {
        page_data: [],
        yoast_meta: [],
        loaded_status: false,
        transform: "none",
        width: 50,
        transition: false
    };

    componentDidMount() {
        // Get company menu items
        axios.get(API.basePath+'/wp-json/wp/v2/pages?slug=404-2')
            .then(response => {
                this.setState({
                    page_data: response.data[0].acf,
                    yoast_meta: response.data[0],
                    loaded_status: true,
                });
            })
            .catch(error => {
                console.log(error);
            });
        this.props.setContactElements(false, true, false);
    }

    mouseMove(e) {
        let w, h;
        w = window.innerWidth;
        h = window.innerHeight;

        if (w > 992) {
            document.getElementById("scrollPosition").classList.remove("transition");

            let x, y, rotateX, moveY,
                boxPos = document.getElementById("scrollPosition").offsetTop;

            if (e.pageX || e.pageY) {
                x = e.pageX;
                y = e.pageY;
            } else if (e.clientX || e.clientY) {
                x = e.clientX + document.body.scrollLeft + document.documentElement.scrollLeft;
                y = e.clientY + document.body.scrollTop + document.documentElement.scrollTop;
            }

            y = -(boxPos - y);

            rotateX = ((w / 2 - x) / 1000) / 4;
            moveY = (y - h / 2) / 25;

            let movePercent = (((w / 2 - x) * 100) / w) / 3.5;

            this.setState({
                transform: "matrix3d(" + Math.cos(rotateX) + ", 0, " + Math.sin(rotateX) + ", 0, 0, 1, 0, 0, " + Math.sin(rotateX) + ", 0, " + Math.cos(rotateX) + ", 0 , 0, " + moveY + ", 0, 1)",
                width: 50 + movePercent,
            });

            if(this.state.transition) {
                this.setState({
                    transition: !this.state.transition,
                });
            }
        }
    }

    mouseLive() {
        this.setState({
            transform: "none",
            width: 50,
            transition: !this.state.transition,
        });
    }

    render() {

        if (this.state.loaded_status) {
            return (
                <>
                    <PageMeta metaData={this.state.yoast_meta || null} hreflangs={this.state.page_data['hreflang_list'] || null} />
                    <CustomSchema schema={this.state.page_data.schema} />
                    <div className={`not-found-page${this.state.transition ? ' transition':""}`} id="scrollPosition" onMouseMove={this.mouseMove.bind(this)} onMouseLeave={this.mouseLive.bind(this)}>
                        <div className="not-found-page__animation">
                            <div className="animation animation__front" style={{width:this.state.width+"%"}}>
                                <div className="animation__content">
                                    <div className="animation__image">
                                        <span style={{transform:this.state.transform}}>404</span>
                                    </div>
                                </div>
                            </div>
                            <div className="animation animation__back">
                                <div className="animation__content">
                                    <div className="animation__image">
                                        <span style={{transform:this.state.transform}}>404</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="not-found-page__content">
                            <div className="not-found-page__col not-found-page__col--left">
                                <div className="not-found-page__title">
                                    <h1 className="not-found-page__title-text">
                                        {this.state.page_data.not_found_content.page_title}
                                    </h1>
                                    <div className="not-found-page__title-bg"  dangerouslySetInnerHTML={{__html: this.state.page_data.not_found_content.page_title_background}} />
                                </div>
                                <div className="not-found-page__description">
                                    <div className="not-found-page__description-text">
                                        {this.state.page_data.not_found_content.page_description}
                                    </div>
                                    <div className="not-found-page__description-link">
                                        <Link to="/" className="base-btn">
                                            {this.state.page_data.not_found_content.link_title}
                                        </Link>
                                    </div>
                                </div>
                                <div className="not-found-page__number">
                                    <span>
                                        404
                                    </span>
                                </div>
                                <div className="not-found-page__bg-cloud not-found-page__bg-cloud--top"></div>
                                <div className="not-found-page__bg-gears not-found-page__bg-gears--left"></div>
                            </div>
                            <div className="not-found-page__col not-found-page__col--right">
                                <div className="not-found-page__description">
                                    <div className="not-found-page__description-text">
                                        {this.state.page_data.not_found_content.page_description}
                                    </div>
                                    <div className="not-found-page__description-link">
                                        <Link to="/" className="base-btn">
                                            {this.state.page_data.not_found_content.link_title}
                                        </Link>
                                    </div>
                                </div>
                                <div className="not-found-page__number">
                                    <span>
                                        404
                                    </span>
                                </div>
                                <div className="not-found-page__bg-cloud not-found-page__bg-cloud--bottom"></div>
                                <div className="not-found-page__bg-gears not-found-page__bg-gears--right"></div>
                            </div>
                        </div>
                    </div>
                </>

            );
        } else {
            return (
                <>
                    <PageMeta metaData={this.state.yoast_meta || null} />
                    <div className="not-found-page">
                        <div className="not-found-page__content">
                            <Loader />
                        </div>
                    </div>
                </>
            );
        }
    }
}

export default NotFound;