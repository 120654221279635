import React, {Component} from "react";

import MainBanner from '../../MainBanner/MainBanner';
import Projects from './Projects/Projects';
import Numbers from './Numbers/Numbers';
import Advantages from './Advantages/Advantages';

import PageMeta from "../../PageMeta/PageMeta";
import CustomSchema from "../../CustomSchema/CustomSchema";

import axios from "axios";
import API from "../../../api";

class ItBetreuung extends Component {

    state = {
        page_data: [],
        yoast_meta: [],
        loaded_status: false
    };

    componentDidMount() {
        // Get company menu items
        axios.get(API.basePath+'/wp-json/wp/v2/pages/25')
            .then(response => {
                this.setState({
                    page_data: response.data.acf,
                    yoast_meta: response.data,
                    loaded_status: true
                });
            })
            .catch(error => {
                console.log(error);
            });
        this.props.setContactElements();
    }

    render() {

        return (
            <>
                <PageMeta metaData={this.state.yoast_meta || null} hreflangs={this.state.page_data['hreflang_list'] || null} />
                <CustomSchema schema={this.state.page_data.schema} />
                <div className="it-betreuung-page">
                    <MainBanner content={this.state.page_data.main_banner_content || null} id='it-betreuung'/>
                    <Advantages content={this.state.page_data.advantages_section || null}/>
                    <Numbers content={this.state.page_data.numbers_section || null}/>
                    <Projects content={this.state.page_data.projects_section || null}/>
                </div>
            </>
        );
    }
}

export default ItBetreuung;