import React, { useContext } from 'react';
import ThemeGeneralSettingsContext from '../../../../ThemeGeneralSettingsContext';

const Datenschutz = (props) => {
    const content = props.content;
    const { parallax } = props;
    const { certificates } = useContext(ThemeGeneralSettingsContext);
    const { usercentrics } = certificates;

    return (
        <section className='datenschutz anim-item'>
            <div className='number datenschutz__number'>9</div>
            <div className='datenschutz__textHolder'>
                <div className='e-commerce-page__title datenschutz__title'>
                    <h2 className='e-commerce-page__title-text' dangerouslySetInnerHTML={{ __html: content.title.text }} />
                    <div className='e-commerce-page__title-bg' dangerouslySetInnerHTML={{ __html: content.title.background_text }} />
                </div>
                <div className="e-commerce-page__text" dangerouslySetInnerHTML={{ __html: content.text }} />
                <img className="e-commerce-page__certificate" src={usercentrics.url} alt={usercentrics.alt} />
            </div>
            <div className="datenschutz__image e-commerce-page__image">
                <div className="leftPhone fadeInLeft">
                    <img style={{transform: parallax(-1, -1)}} src={content.left_phone_image.url} alt={content.left_phone_image.alt} />
                </div>
                <div className="rightPhone fadeInLeft">
                    <img style={{transform: parallax(1, 1)}} src={content.right_phone_image.url} alt={content.right_phone_image.alt} />
                </div>
            </div>
        </section>
    )
};

export default Datenschutz;