import React from 'react'

import { Link } from 'react-router-dom';

import PostImages from '../Posts/PostImages';

import PostCategory from '.././Posts/PostCategory';

import { Html5Entities } from 'html-entities';

const htmlEntities = new Html5Entities();


const PostList = (props) => {
    if (props.postType === 'posts') {
        return (
            <ul className="articles__wrapper" /* {props.loadedStatus ? "articles__wrapper" : "articles__wrapper loading"} */>
                {props.post.map((post, key) => {
                    const title = post.title.rendered;
                    const postDate = new Date(post.date);
                    const editDate = new Date(post.modified);

                    const formattedDate = `${postDate.getDate() < 10 ? '0' + postDate.getDate() : postDate.getDate()}.${(postDate.getMonth() + 1) < 10 ? '0' + (postDate.getMonth() + 1) : (postDate.getMonth() + 1)}.${postDate.getFullYear()}`;
                    const editedDate = `${editDate.getDate() < 10 ? '0' + editDate.getDate() : editDate.getDate()}.${(editDate.getMonth() + 1) < 10 ? '0' + (editDate.getMonth() + 1) : (editDate.getMonth() + 1)}.${editDate.getFullYear()}`;
                    // const titleRender = (title.length > 40) ? title.substr(0, 40) + "..." : title
                    return (
                        <li className="articles__item" key={post.id} style={{ animationDelay: `${key * 100}ms` }}>
                            <div className="articles__item-link">
                                <Link className="articles__item-link" to={`/blog/${post.slug}`}>
                                    <PostImages postId={post.featured_media} />
                                </Link>
                                <span className="articles__content">
                                    <span className="articles__content-item">
                                        <PostCategory post={post.id} titlingBlog={props.titlingBlog} categoriesBreadcrumbs={props.categoriesBreadcrumbs} />
                                        <Link className="articles__item-link" to={`/blog/${post.slug}`}>
                                            <h2 className="articles__content-title">{htmlEntities.decode(title.replace('<br/>', ''))}</h2>
                                        </Link>
                                        <div className="articles__content-excerpt" dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }} />
                                        <div className='articles__content-bottom'>
                                            <div className="dates">
                                                <span className="post-date">
                                                    <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M15.75 2.5H15V0.833282C15 0.373383 14.664 0 14.25 0H13.5C13.086 0 12.75 0.373383 12.75 0.833282V2.5H5.24995V0.833282C5.24995 0.373383 4.91405 0 4.5 0H3.75005C3.336 0 2.99995 0.373383 2.99995 0.833282V2.5H2.25C1.00951 2.5 0 3.62167 0 5V17.5C0 18.8783 1.00951 20 2.25 20H15.75C16.9905 20 18 18.8783 18 17.5V5C18 3.62167 16.9905 2.5 15.75 2.5ZM16.5 17.5C16.5 17.9591 16.1632 18.3333 15.75 18.3333H2.25C1.83678 18.3333 1.50005 17.9591 1.50005 17.5V8.3667H16.5V17.5Z" fill="#FF4D66" />
                                                    </svg>
                                                    <span>{formattedDate}</span>
                                                </span>
                                                <span className="edited-date">
                                                    <span>zuletzt aktualisiert: </span>
                                                    <span>{editedDate}</span>
                                                </span>
                                            </div>
                                            <span className="articles__content-btn" >
                                                <Link className="articles__item-link" to={`/blog/${post.slug}`}>
                                                    <span>{props.readMoreButton}</span>
                                                </Link>
                                            </span>
                                        </div>
                                    </span>
                                </span>
                            </div>
                        </li>
                    )
                })}

            </ul>
        )
    } else if (props.postType === 'autoren') {
        return (
            <ul className="articles__wrapper">
                {props.autorenPost.map((post, key) => {
                    const title = post.name;
                    const Ids = [];

                    props.authorIds.forEach((post) => {
                        Ids.push(post.author)
                    })

                    const uniq = Ids.reduce(function (a, b) {
                        if (a.indexOf(b) < 0) a.push(b);
                        return a;
                    }, []);

                    if (uniq.includes(post.id)) {
                        return (
                            <li className="articles__author" key={post.id} style={{ animationDelay: `${key * 100}ms` }}>
                                <div className="articles__author_wrapper">
                                    <Link className="articles__author-link" to={`/blog/autoren/${post.slug}`}>
                                        <img src={post.acf.avatar.url} />
                                    </Link>
                                    <span className="articles__author_content">
                                        <span className="articles__author_content-item">
                                            <Link className="articles__author-link" to={`/blog/autoren/${post.slug}`}>
                                                <h3 className="articles__author_content-title">{htmlEntities.decode(title)}</h3>
                                            </Link>
                                            <p className="articles__author_content-workplace">{post.acf.author_inform.work_place}</p>
                                            <div className="articles__author_content-text" dangerouslySetInnerHTML={{ __html: post.description }} />
                                            <div className='articles__author_content-bottom'>
                                                <span className="articles__author_content-btn" >
                                                    <Link className="articles__author-link" to={`/blog/autoren/${post.slug}`}>
                                                        <span>{props.readMoreAuthorsButton}</span>
                                                    </Link>
                                                </span>
                                            </div>
                                        </span>
                                    </span>
                                </div>
                            </li>
                        )
                    }
                })}
            </ul>
        )
    }
}

export default PostList;
