import React from 'react'
import { Html5Entities } from 'html-entities';

import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
} from "react-share";

import "./SocialLink.scss"


const SocialLink = (props) => {
    const {link, postTitle} = props;
    const url = window.location.href
    const htmlEntities = new Html5Entities();
    let shareButton = (<></>);

    if (link.name === 'linkedin') {
        shareButton = (<LinkedinShareButton title={link.name} url={url} className="social-icon linkedin" >
            <span dangerouslySetInnerHTML={{ __html: link.icon }}></span>
        </LinkedinShareButton>);
    } else if (link.name === 'xing2') {
        shareButton = (<a href={link.link.url + '/spi/shares/new?url=' + url} target="_blank" rel="noopener nofollow" className="social-icon xing2">
            <span dangerouslySetInnerHTML={{ __html: link.icon }}></span>
        </a>)
    } else if (link.name === 'facebook') {
        shareButton = (<FacebookShareButton title={link.name} url={url} className="social-icon facebook">
            <span dangerouslySetInnerHTML={{ __html: link.icon }}></span>
        </FacebookShareButton>);
    } else if (link.name === 'twitter') {
        shareButton = (<TwitterShareButton title={htmlEntities.decode(`${postTitle} - alkima WEB & DESIGN ®`)} url={url} className="social-icon twitter">
            <span dangerouslySetInnerHTML={{ __html: link.icon }}></span>
        </TwitterShareButton>);
    } else if (link.name === 'instagram') {
        shareButton = (<a href={link.link.url + '/share?url=' + url} target="_blank" rel="noopener nofollow" className="social-icon instagram">
            <span dangerouslySetInnerHTML={{ __html: link.icon }}></span>
        </a>)
    }

    return shareButton;
}

export default SocialLink;