import React, {Component} from "react";
import ContactForm from "../../Forms/ContactForm";

import "./Contact.scss";

import axios from "axios";
import API from "../../../api";
import Loader from "../../../components/Loader/Loader";

import Plx from 'react-plx';

import PageMeta from "../../PageMeta/PageMeta";
import CustomSchema from "../../CustomSchema/CustomSchema";

class Contact extends Component {

    state = {
        page_data: [],
        yoast_meta: [],
        loaded_status: false,
        parallax_status: true,
        transform: "none",
        width: 50,
        formData: [],
        fieldsValue: [],
        activeField: null,
        transition: false
    };

    componentDidMount() {
        // Get company menu items
        axios.get(API.basePath+'/wp-json/wp/v2/pages/49')
            .then(response => {
                const fields = response.data.acf.form_fields.field;
                let fieldsValue = [];
                for (let i = 0; i < fields.length; i++) {
                    fieldsValue[fields[i].field_name_attribute] = '';
                }

                this.setState({
                    page_data: response.data.acf,
                    loaded_status: true,
                    yoast_meta: response.data,
                    fieldsValue: fieldsValue
                });
            })
            .catch(error => {
                console.log(error);
            });

        let w = window.innerWidth;

        if (w > 992) {
            this.setState({
                parallax_status: false
            });
        }
        this.props.setContactElements(false, false, false);
    }

    mouseMove(e) {
        let w, h;
        w = window.innerWidth;
        h = window.innerHeight;

        if (w > 992) {
            document.getElementById("scrollPosition").classList.remove("transition");

            let x, y, rotateX, moveY,
                boxPos = document.getElementById("scrollPosition").offsetTop;

            if (e.pageX || e.pageY) {
                x = e.pageX;
                y = e.pageY;
            } else if (e.clientX || e.clientY) {
                x = e.clientX + document.body.scrollLeft + document.documentElement.scrollLeft;
                y = e.clientY + document.body.scrollTop + document.documentElement.scrollTop;
            }

            y = -(boxPos - y);

            rotateX = ((w / 2 - x) / 1000) / 4;
            moveY = (y - h / 2) / 25;

            let movePercent = (((w / 2 - x) * 100) / w) / 4;

            this.setState({
                transform: "matrix3d(" + Math.cos(rotateX) + ", 0, " + Math.sin(rotateX) + ", 0, 0, 1, 0, 0, " + Math.sin(rotateX) + ", 0, " + Math.cos(rotateX) + ", 0 , 0, " + moveY + ", 0, 1)",
                width: 50 + movePercent,
            });

            if(this.state.transition) {
                this.setState({
                    transition: !this.state.transition,
                });
            }
        }
    }

    mouseLive() {
        this.setState({
            transform: "none",
            width: 50,
            transition: !this.state.transition,
        });
    }

    render() {
        const wW = window.innerWidth;

        let leftColContent = '';
        let rightColContent = '';
        let animationBlock = '';

        if (!this.state.loaded_status) {
            leftColContent = rightColContent = <Loader />;
        } else {

            const info_col = this.state.page_data.contact_information_column;
            const form_col = this.state.page_data.form_column;
            const form_data = this.state.page_data.form_fields;
            const servicesListPreferred = info_col.services && info_col.services.slice(0, 2)
            const servicesList = info_col.services && info_col.services.slice(2)

            let phoneNumber = info_col.phone_number;
            phoneNumber = phoneNumber.replace(/\s/g, "");

            const dataLeftParallax = [
                {
                    start: 'self',
                    duration: 900,
                    properties: [
                        {
                            startValue: 0,
                            endValue: 30,
                            unit: '%',
                            property: 'translateY',
                        },
                    ],
                },
            ];

            leftColContent = <div className="contact-info loading-block loading-block--2">
                        {
                            wW > 992 ? (
                                <div className="contact-info__title">
                                    <h1 className="contact-info__title-text" dangerouslySetInnerHTML={{ __html: info_col.title}}/>
                                    <div className="contact-info__title-bg">
                                        <span dangerouslySetInnerHTML={{ __html: info_col.title_bg}}/>
                                    </div>
                                </div>
                            ) : ''
                        }

                        {info_col.services &&
                            <div className="contact-service">
                                {!!servicesListPreferred.length &&
                                    <ul className="contact-service__list">
                                        {servicesListPreferred.map((item, key) => (
                                            <li className="contact-service__item" key={key} style={{color: item.text_color}}>
                                                <img
                                                    className="contact-service__icon"
                                                    src={item.service_icon.url}
                                                    alt={item.service_icon.alt || item.service_name || 'service icon'}
                                                    width={item.service_icon.width}
                                                    height={item.service_icon.height}
                                                />
                                                <div className="contact-service__name">{item.service_name}</div>
                                            </li>
                                        ))}
                                    </ul>
                                }
                                
                                {!!servicesList.length &&
                                    <ul className="contact-service__list">
                                        {servicesList.map((item, key) => (
                                            <li className="contact-service__item" key={key} style={{color: item.text_color}}>
                                                <img
                                                    className="contact-service__icon"
                                                    src={item.service_icon.url}
                                                    alt={item.service_icon.alt || item.service_name || 'service icon'}
                                                    width={item.service_icon.width}
                                                    height={item.service_icon.height}
                                                />
                                                <div className="contact-service__name">{item.service_name}</div>
                                            </li>
                                        ))}
                                    </ul>
                                }
                            </div>
                        }

                        <div className="contact-info__contact-data">
                            <div className="contact-info__address">
                                <p>{info_col.address.title}</p>
                                {info_col.address.addresses.map((item, key) =>
                                    <div key={`address--${key}`}>
                                        <div className="contact-info__address-icon">
                                            <i className="icon-location"></i>
                                        </div>
                                        <div dangerouslySetInnerHTML={{__html: item.address}}/>
                                    </div>
                                )}
                            </div>
                            <div className="contact-info__hours">
                                <div className="contact-info__hours-icon">
                                    <i className="icon-clock"></i>
                                </div>
                                <div className="contact-info__hours-text" dangerouslySetInnerHTML={{ __html: info_col.hours}}/>
                            </div>
                            <div className="contact-info__phone">
                                <a href={`tel:${phoneNumber}`}>
                                    <i className="icon-phone"></i>
                                    <span>{info_col.phone_number}</span>
                                </a>
                            </div>
                            <div className="contact-info__email">
                                <a href={`mailto:${info_col.email}`}>
                                    <i className="icon-envelope"></i>
                                    <span>{info_col.email}</span>
                                </a>
                            </div>
                        </div>
                        <Plx className="contact-info__parallax" parallaxData={ dataLeftParallax } disabled={this.state.parallax_status}>
                            <img src={info_col.background_image.url} alt=""/>
                        </Plx>
                    </div>;

            rightColContent = (
                <ContactForm
                    form_data={form_data}
                    fieldsValueInit={this.state.fieldsValue}
                    heading={info_col.title}
                    isContactPage
                />
            )

            animationBlock = (
                <div className="contact-page__animation loading-block">
                    <div className="contact-animation contact-animation__front" style={{width:this.state.width+"%"}}>
                        <div className="contact-animation__content">
                            <div className="contact-animation__image">
                                <img src={info_col.logo.url} alt="" style={{transform:this.state.transform}}/>
                            </div>
                        </div>
                    </div>
                    <div className="contact-animation contact-animation__back">
                        <div className="contact-animation__content">
                            <div className="contact-animation__image">
                                <img src={form_col.logo.url} alt="" style={{transform:this.state.transform}}/>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <>
                <PageMeta metaData={this.state.yoast_meta || null} hreflangs={this.state.page_data['hreflang_list'] || null} />
                <CustomSchema schema={this.state.page_data.schema} />
                <div
                    className={`contact-page${this.state.transition ? " transition" : ""}`}
                    id="scrollPosition"
                    onMouseMove={this.mouseMove.bind(this)}
                    onMouseLeave={this.mouseLive.bind(this)}
                >
                    {animationBlock}
                    <div className="contact-page__content">
                        <div className="contact-page__backgrop" style={{width:this.state.width+"%"}}></div>
                        <div className="contact-page__col contact-page__col--info">
                            {leftColContent}
                        </div>
                        <div className="contact-page__col contact-page__col--form">
                            {rightColContent}
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Contact;
