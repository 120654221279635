import React from "react";
import "../WebEntwicklung.scss"

const Titling = ({data}) => {
    return (
        <div className="software-page__meta page__meta">
            <div className="page__meta-bg page__meta-bg--top"></div>
            <div className="page__meta-bg page__meta-bg--bottom"></div>
            <div className="page__meta-content">
                <div className="page__meta-title page__title">
                    <h1 className="page__title-text" dangerouslySetInnerHTML={{__html: data?.page_title}}/>
                    <div className="page__title-bg">
                        <div dangerouslySetInnerHTML={{__html: data?.page_title}}/>
                    </div>
                </div>
                <div className="page__meta-description" dangerouslySetInnerHTML={{__html: data?.page_description}}/>
            </div>
        </div>
    )
}

export default Titling;