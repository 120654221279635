import React, {Component} from "react";
import {Link} from "react-router-dom";

import DelayLink from '../DelayLink/DelayLink';

import axios from "axios";
import API from "../../api";

import './Menu.scss';

class Menu extends Component {

    state = {
        menu_data: [],
        data_load_status: false,
        subPanelStatus:false,
        subPanel:null
    };

    // Get footer data
    componentDidMount() {
        axios.get(API.basePath+'/wp-json/acf/v3/options/menu-general-settings')
            .then(response => {
                this.setState({
                    menu_data: response.data.acf.options,
                    data_load_status: true
                });
            })
            .catch(error => {
                console.log(error);
            });
    }

    showSubmenu = (subMenuId) => {
        this.setState({
            subPanel: subMenuId,
            subPanelStatus: !this.state.subPanelStatus
        });
    };
    hideSubmenu = () => {
        this.setState({
            subPanelStatus: !this.state.subPanelStatus
        });
    };

    render() {

        if (!this.state.data_load_status) {
            return (
                <></>
            );
        }

        const {
            linkedin_page_url,
            logotypes,
            navigation_title,
            menu_background_image,
            navigation_links
        } = this.state.menu_data;


        let mainMenuClasses = 'main-nav';

        if(this.state.subPanelStatus) {
            mainMenuClasses += ' main-nav--submenu-opened';
        }

        let menuClasses = 'page__menu';
        let linkDelay = 0;

        if(this.props.status) {
            menuClasses += ' page__menu--visible';
            linkDelay = 500;
        }

        return (
            <div className={menuClasses}>
                <div className="page__menu-close-layer" onClick={this.props.toggleHeaderMenu}></div>
                <div className="page__menu-inner">
                    <div className="page__menu-bg">
                        {this.props.status && <img src={menu_background_image.url} alt={menu_background_image.title}/>}
                    </div>
                    <div className="page__menu-nav">
                        <div className={mainMenuClasses}>
                            <div className='main-nav__panel'>
                                <div className="main-nav__panel-title">{navigation_title}</div>
                                <ul>
                                    {
                                        navigation_links.map((item, key) => {

                                            if(parseInt(item.link.status)) {
                                                if (item.link.submenu && parseInt(item.link.submenu_status)) {

                                                    return (
                                                        <li key={key}
                                                            className="has-submenu"
                                                            style={{transitionDelay: ((key+4)/10)+'s'}}>

                                                            {
                                                                item.link.url ? (
                                                                    <DelayLink delay={linkDelay} to={item.link.url} clickAction={this.props.toggleHeaderMenu}>
                                                                        {item.link.title}
                                                                    </DelayLink>
                                                                ) : (
                                                                    <a>{item.link.title}</a>
                                                                )
                                                            }

                                                            <button className="show-submenu" onClick={() =>this.showSubmenu("panel__" + item.link.slug)}>
                                                                <i className="icon-angle-right"></i>
                                                            </button>
                                                            <ul className={"submenu__" + item.link.slug}>
                                                                {
                                                                    item.link.submenu.map((item, key) => {

                                                                        if(parseInt(item.link.status)) {
                                                                            return(
                                                                                <li key={key}>
                                                                                    <DelayLink delay={linkDelay} to={item.link.url} clickAction={this.props.toggleHeaderMenu}>
                                                                                        <img src={item.link.icon.url} alt={item.link.icon.title}/>
                                                                                        <span>{item.link.title}</span>
                                                                                    </DelayLink>
                                                                                </li>
                                                                            )
                                                                        }
                                                                    })
                                                                }
                                                            </ul>
                                                        </li>
                                                    )

                                                } else {
                                                    return (
                                                        <li key={key} style={{transitionDelay: ((key+4)/10)+'s'}}>
                                                            <DelayLink delay={linkDelay} to={item.link.url} clickAction={this.props.toggleHeaderMenu}>
                                                                {item.link.title}
                                                            </DelayLink>
                                                        </li>
                                                    )
                                                }
                                            }
                                        })
                                    }
                                </ul>
                                <div className="main-nav__panel-bgImage">
                                    {this.props.status && <img src={menu_background_image.url} alt={menu_background_image.title}/>}
                                </div>
                            </div>
                            {
                                navigation_links.map((item, key) => {

                                    if (item.link.submenu && parseInt(item.link.submenu_status)) {

                                        let subPanelId = "panel__" + item.link.slug;

                                        return (
                                            <div className='main-nav__sub-panels'>
                                                <div className={`main-nav__sub-panel ${subPanelId} ${(this.state.subPanel === subPanelId) ? 'main-nav__sub-panel--active':''}`} key={key}>
                                                    <a className="main-nav__prev-panel" onClick={() =>this.hideSubmenu()}>
                                                        <i className="icon-angle-left"></i>
                                                        <span>{item.link.title}</span>
                                                    </a>
                                                    <ul>
                                                        {
                                                            item.link.submenu.map((item, key) => {
                                                                if(parseInt(item.link.status)) {
                                                                    return(
                                                                        <li key={key}>
                                                                            <DelayLink delay={linkDelay} to={item.link.url} clickAction={this.props.toggleHeaderMenu}>
                                                                                <img src={item.link.icon.url} alt={item.link.icon.title}/>
                                                                                <span>{item.link.title}</span>
                                                                            </DelayLink>
                                                                        </li>
                                                                    )
                                                                }
                                                            })
                                                        }
                                                    </ul>
                                                </div>
                                                    <div className="main-nav__panel-bgText">{item.link.title}</div>
                                            </div>
                                        )
                                    }
                                })
                            }
                        </div>
                    </div>
                    <div className="page__menu-icons">

                        {
                            parseInt(this.state.menu_data.show_logotypes) ? (
                                <div className="main-projects">
                                    <ul className="main-projects__logos">
                                        {
                                            logotypes.map((item, key) => {
                                                return (
                                                    <li key={key}>
                                                        <Link to={item.url}>
                                                            <img src={item.logo.url} alt={item.logo.title}/>
                                                        </Link>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                            ) : ('')
                        }
                        <div className="page__menu-controls">
                            <div className="controls-item linkedin">
                                <a href={linkedin_page_url} target="_blank" rel="nofollow noopener">
                                    <i className="icon-linkedin"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Menu;