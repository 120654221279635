import React, { useRef } from 'react';
import useMobile from "../../../../hooks/useMobile";
import useScrollAnimation from "../../../../hooks/useScrollAnimation";
import convertPxToRem from "../../../../helpers/convertPxToRem";
import Support from "../../../Support/Support";

const Implementation = (props) => {
    const { title, text, image, list, certified } = props.content;
    const {support} = props

    const isMobile = useMobile()
    const startAnimationRef = useRef(null)
    const scrollTop = useScrollAnimation(startAnimationRef.current)

    return (
        <section className='implementation'>
            <div className="implementation--top">
                    <div className="implementation__text-container">
                        <div className='usercentrics-page__title'>
                            <h2 className='usercentrics-page__title-text' dangerouslySetInnerHTML={{ __html: title.text }} />
                            <div className='usercentrics-page__title-bg' dangerouslySetInnerHTML={{ __html: title.background }} />
                        </div>
                        <div className="usercentrics-page__text" dangerouslySetInnerHTML={{ __html: text }} />
                    </div>
                    <img className="implementation__image" src={image.url} alt={image.alt} />
                    <div
                        ref={startAnimationRef}
                        className='implementation--top-triangle' 
                        style={{ borderTopWidth: !isMobile && (convertPxToRem(920) - scrollTop) / 2 }}
                    />
                </div>
                <div className="implementation--center">
                    <ul className="usercentrics-page__list">
                        {list.map((item, key) => {
                            return(
                                <li
                                    style={{ paddingTop: !isMobile && (convertPxToRem(320) * (key + 1) - scrollTop) / 2 }}
                                    key={`implementation-item--${item.title}`}
                                >
                                    <div>
                                        <div>8.<span>{key + 1}</span></div>
                                        <img src={item.icon.url} alt={item.icon.alt} />
                                        <h3 dangerouslySetInnerHTML={{ __html: item.title }} />
                                        <p>{item.text}</p>
                                    </div>
                                </li>
                            )
                        })}
                    </ul>
                    <div
                        className='implementation--center-triangle'
                        style={{ borderTopWidth: !isMobile && (convertPxToRem(920) - scrollTop) / 2 }}
                    />
                </div>
                {support && <Support data={{support}} />}
        </section>
    )
};

export default Implementation;
