import React from 'react';
import {withRouter} from 'react-router';

import Archive from './Archive/Archive'
import Post from './Post/Post'

const Glossary = ({match, setContactElements, setHeaderOptions}) => {

    const {slug} = match.params;
    const isArchive = !slug;

    return (
        isArchive ? (
            <Archive setContactElements={setContactElements}/>
        ) : (
            <Post
                setContactElements={setContactElements}
                setHeaderOptions={setHeaderOptions}
            />
        )
    );
};

export default withRouter(Glossary);