import React from 'react';

import './Articles.scss';

import { Link } from 'react-router-dom'

import useMobile from "../../../../hooks/useMobile";

import PostList from '../Posts/PostList';

const Articles = (props) => {
    const isMobile = useMobile();

    if (!props.loadedStatus && props.posts.length === 0) {
        return <div className="loading-posts"></div>
    } else {
        return (
            <div className="articles" id="scrollPosition">
                <PostList
                    authorIds={props.authorIds}
                    post={props.posts}
                    autorenPost={props.autorenPosts}
                    postType={props.postType}
                    postId={props.posts.featured_media}
                    categoriesBreadcrumbs={props.categoriesBreadcrumbs}
                    titlingBlog={props.titlingBlog}
                    loadedStatus={props.loadedStatus}
                    readMoreButton={props.readMoreButton}
                    readMoreAuthorsButton={props.readMoreAuthorsButton}
                />
            </div>
        )
    }
}

export default Articles;
