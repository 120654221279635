import React from 'react'
import "./ClientReview.scss"
const ClientReview = (props) => {
    const {
        client_text,
        client_photo,
        client_position,
        client_name,
        picture
    } = props.content
    if (!client_text) {
        return null;
    }
    return (
        <div className="client-review">
            {picture && (
                <div className="client-review__picture">
                    <img
                        src={picture.url}
                        alt={picture.alt}
                        width={picture.width}
                        height={picture.height}
                    />
                </div>
            )}
            <div className="client-review__content">
                {client_photo &&
                    <>
                        <div className="client-review__photo">
                            <img
                                src={client_photo.url}
                                alt={client_photo.alt}
                                width={client_photo.width}
                                height={client_photo.height}
                            />
                        </div>
                    </>
                }
                {client_name && (<div className="client-review__name" dangerouslySetInnerHTML={{ __html: client_name }} />)}
                {client_position && (<div className="client-review__position" dangerouslySetInnerHTML={{ __html: client_position }} />)}
                <div className="client-review__text" dangerouslySetInnerHTML={{ __html: client_text }} />
            </div>
        </div>
    )
}
export default ClientReview;