import React from "react";

import { Link } from 'react-scroll';

import './Description.scss'

import { Html5Entities } from 'html-entities';

const htmlEntities = new Html5Entities();

const Description = (props) => {
    return (
        <section className="blog__description">
            <div className="blog__description_header">
                {props.authorAvatar !== undefined &&
                    <div className="blog__description_header__img">
                        <img src={props.authorAvatar.url} alt={props.authorAvatar.alt} />
                    </div>
                }
                <h1 className="blog__description__title" dangerouslySetInnerHTML={{ __html: htmlEntities.decode(props.title) }} />
            </div>

            {props.description &&
                <p className="blog__description__text" dangerouslySetInnerHTML={{ __html: props.description }}></p>
            }

            <div className="blog__description__mobile_circles">
                <img src="/images/blog/banner/mobile/mobile.png" alt="" />
            </div>
            <div className="magento">
                <img className="blog__description__img--magento blog__description__img--magento-1" src="/images/blog/banner/magento/circle-1-magento.svg" alt="magento-circle" />
                <img className="blog__description__img--magento blog__description__img--magento-2" src="/images/blog/banner/magento/circle-2-magento.svg" alt="magento-circle" />
                <img className="blog__description__img--magento blog__description__img--magento-3" src="/images/blog/banner/magento/circle-3-magento.svg" alt="magento-circle" />
                <img className="blog__description__img--magento blog__description__img--magento-4" src="/images/blog/banner/magento/circle-4-magento.svg" alt="magento-circle" />
                <img className="blog__description__img--magento blog__description__img--magento-5" src="/images/blog/banner/magento/circle-5-magento.svg" alt="magento-circle" />
                <img className="blog__description__img--magento blog__description__img--magento-6" src="/images/blog/banner/magento/circle-6-magento.svg" alt="magento-circle" />
                <img className="blog__description__img--magento blog__description__img--magento-7" src="/images/blog/banner/magento/circle-7-magento.svg" alt="magento-circle" />
                <img className="magento__logo" src="/images/blog/banner/magento/logo-magento.svg" alt="magento-logo" />
            </div>
            <div className="react">
                <img className="blog__description__img--react blog__description__img--react-1" src="/images/blog/banner/react/circle-1-react.svg" alt="react-circle" />
                <img className="blog__description__img--react blog__description__img--react-2" src="/images/blog/banner/react/circle-2-react.svg" alt="react-circle" />
                <img className="blog__description__img--react blog__description__img--react-3" src="/images/blog/banner/react/circle-3-react.svg" alt="react-circle" />
                <img className="blog__description__img--react blog__description__img--react-4" src="/images/blog/banner/react/circle-4-react.svg" alt="react-circle" />
                <img className="blog__description__img--react blog__description__img--react-5" src="/images/blog/banner/react/circle-5-react.svg" alt="react-circle" />
                <img className="blog__description__img--react blog__description__img--react-6" src="/images/blog/banner/react/circle-6-react.svg" alt="react-circle" />
                <img className="blog__description__img--react blog__description__img--react-7" src="/images/blog/banner/react/circle-7-react.svg" alt="react-circle" />
                <img className="react__logo" src="/images/blog/banner/react/logo-react.svg" alt="react-logo" />
            </div>
            <div className="shopware">
                <img className="blog__description__img--shopware blog__description__img--shopware-1" src="/images/blog/banner/shopware/circle-1-shopware.svg" alt="shopware-circle" />
                <img className="blog__description__img--shopware blog__description__img--shopware-2" src="/images/blog/banner/shopware/circle-2-shopware.svg" alt="shopware-circle" />
                <img className="blog__description__img--shopware blog__description__img--shopware-3" src="/images/blog/banner/shopware/circle-3-shopware.svg" alt="shopware-circle" />
                <img className="blog__description__img--shopware blog__description__img--shopware-4" src="/images/blog/banner/shopware/circle-4-shopware.svg" alt="shopware-circle" />
                <img className="blog__description__img--shopware blog__description__img--shopware-5" src="/images/blog/banner/shopware/circle-5-shopware.svg" alt="shopware-circle" />
                <img className="blog__description__img--shopware blog__description__img--shopware-6" src="/images/blog/banner/shopware/circle-6-shopware.svg" alt="shopware-circle" />
                <img className="blog__description__img--shopware blog__description__img--shopware-7" src="/images/blog/banner/shopware/circle-7-shopware.svg" alt="shopware-circle" />
                <img className="shopware__logo" src="/images/blog/banner/shopware/logo-shopware.svg" alt="shopware-logo" />
            </div>
            <div className="typo3">
                <img className="blog__description__img--typo3 blog__description__img--typo3-1" src="/images/blog/banner/typo3/circle-1-typo3.svg" alt="typo3-circle" />
                <img className="blog__description__img--typo3 blog__description__img--typo3-2" src="/images/blog/banner/typo3/circle-2-typo3.svg" alt="typo3-circle" />
                <img className="blog__description__img--typo3 blog__description__img--typo3-3" src="/images/blog/banner/typo3/circle-3-typo3.svg" alt="typo3-circle" />
                <img className="blog__description__img--typo3 blog__description__img--typo3-4" src="/images/blog/banner/typo3/circle-4-typo3.svg" alt="typo3-circle" />
                <img className="blog__description__img--typo3 blog__description__img--typo3-5" src="/images/blog/banner/typo3/circle-5-typo3.svg" alt="typo3-circle" />
                <img className="blog__description__img--typo3 blog__description__img--typo3-6" src="/images/blog/banner/typo3/circle-6-typo3.svg" alt="typo3-circle" />
                <img className="blog__description__img--typo3 blog__description__img--typo3-7" src="/images/blog/banner/typo3/circle-7-typo3.svg" alt="typo3-circle" />
                <img className="typo3__logo" src="/images/blog/banner/typo3/logo-typo3.svg" alt="typo3-logo" />
            </div>
            <div className="woocomerce">
                <img className="blog__description__img--woocomerce blog__description__img--woocomerce-1" src="/images/blog/banner/woocomerce/circle-1-woocomerce.svg" alt="woocomerce-circle" />
                <img className="blog__description__img--woocomerce blog__description__img--woocomerce-2" src="/images/blog/banner/woocomerce/circle-2-woocomerce.svg" alt="woocomerce-circle" />
                <img className="blog__description__img--woocomerce blog__description__img--woocomerce-3" src="/images/blog/banner/woocomerce/circle-3-woocomerce.svg" alt="woocomerce-circle" />
                <img className="blog__description__img--woocomerce blog__description__img--woocomerce-4" src="/images/blog/banner/woocomerce/circle-4-woocomerce.svg" alt="woocomerce-circle" />
                <img className="blog__description__img--woocomerce blog__description__img--woocomerce-5" src="/images/blog/banner/woocomerce/circle-5-woocomerce.svg" alt="woocomerce-circle" />
                <img className="blog__description__img--woocomerce blog__description__img--woocomerce-6" src="/images/blog/banner/woocomerce/circle-6-woocomerce.svg" alt="woocomerce-circle" />
                <img className="blog__description__img--woocomerce blog__description__img--woocomerce-7" src="/images/blog/banner/woocomerce/circle-7-woocomerce.svg" alt="woocomerce-circle" />
                <img className="woocomerce__logo" src="/images/blog/banner/woocomerce/logo-woocomerce.svg" alt="woocomerce-logo" />
            </div>
            <div className="next">
                <img className="blog__description__img--next blog__description__img--next-1" src="/images/blog/banner/next/circle-1-next.svg" alt="next-circle" />
                <img className="blog__description__img--next blog__description__img--next-2" src="/images/blog/banner/next/circle-2-next.svg" alt="next-circle" />
                <img className="blog__description__img--next blog__description__img--next-3" src="/images/blog/banner/next/circle-3-next.svg" alt="next-circle" />
                <img className="blog__description__img--next blog__description__img--next-4" src="/images/blog/banner/next/circle-4-next.svg" alt="next-circle" />
                <img className="blog__description__img--next blog__description__img--next-5" src="/images/blog/banner/next/circle-5-next.svg" alt="next-circle" />
                <img className="blog__description__img--next blog__description__img--next-6" src="/images/blog/banner/next/circle-6-next.svg" alt="next-circle" />
                <img className="blog__description__img--next blog__description__img--next-7" src="/images/blog/banner/next/circle-7-next.svg" alt="next-circle" />
                <img className="next__logo" src="/images/blog/banner/next/logo-next.svg" alt="next-logo" />
            </div>
            <div className="php">
                <img className="blog__description__img--php blog__description__img--php-1" src="/images/blog/banner/php/circle-1-php.svg" alt="php-circle" />
                <img className="blog__description__img--php blog__description__img--php-2" src="/images/blog/banner/php/circle-2-php.svg" alt="php-circle" />
                <img className="blog__description__img--php blog__description__img--php-3" src="/images/blog/banner/php/circle-3-php.svg" alt="php-circle" />
                <img className="blog__description__img--php blog__description__img--php-4" src="/images/blog/banner/php/circle-4-php.svg" alt="php-circle" />
                <img className="blog__description__img--php blog__description__img--php-5" src="/images/blog/banner/php/circle-5-php.svg" alt="php-circle" />
                <img className="blog__description__img--php blog__description__img--php-6" src="/images/blog/banner/php/circle-6-php.svg" alt="php-circle" />
                <img className="blog__description__img--php blog__description__img--php-7" src="/images/blog/banner/php/circle-7-php.svg" alt="php-circle" />
                <img className="php__logo" src="/images/blog/banner/php/logo-php.svg" alt="php-logo" />
            </div>
            <div className="vue">
                <img className="blog__description__img--vue blog__description__img--vue-1" src="/images/blog/banner/vue/circle-1-vue.svg" alt="vue-circle" />
                <img className="blog__description__img--vue blog__description__img--vue-2" src="/images/blog/banner/vue/circle-2-vue.svg" alt="vue-circle" />
                <img className="blog__description__img--vue blog__description__img--vue-3" src="/images/blog/banner/vue/circle-3-vue.svg" alt="vue-circle" />
                <img className="blog__description__img--vue blog__description__img--vue-4" src="/images/blog/banner/vue/circle-4-vue.svg" alt="vue-circle" />
                <img className="blog__description__img--vue blog__description__img--vue-5" src="/images/blog/banner/vue/circle-5-vue.svg" alt="vue-circle" />
                <img className="blog__description__img--vue blog__description__img--vue-6" src="/images/blog/banner/vue/circle-6-vue.svg" alt="vue-circle" />
                <img className="blog__description__img--vue blog__description__img--vue-7" src="/images/blog/banner/vue/circle-7-vue.svg" alt="vue-circle" />
                <img className="vue__logo" src="/images/blog/banner/vue/logo-vue.svg" alt="vue-logo" />
            </div>
            <div className="bootstrap">
                <img className="blog__description__img--bootstrap blog__description__img--bootstrap-1" src="/images/blog/banner/bootstrap/circle-1-bootstrap.svg" alt="bootstrap-circle" />
                <img className="blog__description__img--bootstrap blog__description__img--bootstrap-2" src="/images/blog/banner/bootstrap/circle-2-bootstrap.svg" alt="bootstrap-circle" />
                <img className="blog__description__img--bootstrap blog__description__img--bootstrap-3" src="/images/blog/banner/bootstrap/circle-3-bootstrap.svg" alt="bootstrap-circle" />
                <img className="blog__description__img--bootstrap blog__description__img--bootstrap-4" src="/images/blog/banner/bootstrap/circle-4-bootstrap.svg" alt="bootstrap-circle" />
                <img className="blog__description__img--bootstrap blog__description__img--bootstrap-5" src="/images/blog/banner/bootstrap/circle-5-bootstrap.svg" alt="bootstrap-circle" />
                <img className="blog__description__img--bootstrap blog__description__img--bootstrap-6" src="/images/blog/banner/bootstrap/circle-6-bootstrap.svg" alt="bootstrap-circle" />
                <img className="blog__description__img--bootstrap blog__description__img--bootstrap-7" src="/images/blog/banner/bootstrap/circle-7-bootstrap.svg" alt="bootstrap-circle" />
                <img className="bootstrap__logo" src="/images/blog/banner/bootstrap/logo-bootstrap.svg" alt="bootstrap-logo" />
            </div>
            <div className="laravel">
                <img className="blog__description__img--laravel blog__description__img--laravel-1" src="/images/blog/banner/laravel/circle-1-laravel.svg" alt="laravel-circle" />
                <img className="blog__description__img--laravel blog__description__img--laravel-2" src="/images/blog/banner/laravel/circle-2-laravel.svg" alt="laravel-circle" />
                <img className="blog__description__img--laravel blog__description__img--laravel-3" src="/images/blog/banner/laravel/circle-3-laravel.svg" alt="laravel-circle" />
                <img className="blog__description__img--laravel blog__description__img--laravel-4" src="/images/blog/banner/laravel/circle-4-laravel.svg" alt="laravel-circle" />
                <img className="blog__description__img--laravel blog__description__img--laravel-5" src="/images/blog/banner/laravel/circle-5-laravel.svg" alt="laravel-circle" />
                <img className="blog__description__img--laravel blog__description__img--laravel-6" src="/images/blog/banner/laravel/circle-6-laravel.svg" alt="laravel-circle" />
                <img className="blog__description__img--laravel blog__description__img--laravel-7" src="/images/blog/banner/laravel/circle-7-laravel.svg" alt="laravel-circle" />
                <img className="laravel__logo" src="/images/blog/banner/laravel/logo-laravel.svg" alt="laravel-logo" />
            </div>
            <div className="symfony">
                <img className="blog__description__img--symfony blog__description__img--symfony-1" src="/images/blog/banner/symfony/circle-1-symfony.svg" alt="symfony-circle" />
                <img className="blog__description__img--symfony blog__description__img--symfony-2" src="/images/blog/banner/symfony/circle-2-symfony.svg" alt="symfony-circle" />
                <img className="blog__description__img--symfony blog__description__img--symfony-3" src="/images/blog/banner/symfony/circle-3-symfony.svg" alt="symfony-circle" />
                <img className="blog__description__img--symfony blog__description__img--symfony-4" src="/images/blog/banner/symfony/circle-4-symfony.svg" alt="symfony-circle" />
                <img className="blog__description__img--symfony blog__description__img--symfony-5" src="/images/blog/banner/symfony/circle-5-symfony.svg" alt="symfony-circle" />
                <img className="blog__description__img--symfony blog__description__img--symfony-6" src="/images/blog/banner/symfony/circle-6-symfony.svg" alt="symfony-circle" />
                <img className="blog__description__img--symfony blog__description__img--symfony-7" src="/images/blog/banner/symfony/circle-7-symfony.svg" alt="symfony-circle" />
                <img className="symfony__logo" src="/images/blog/banner/symfony/logo-symfony.svg" alt="symfony-logo" />
            </div>
        </section>
    )
}

export default Description
