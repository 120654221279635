import React from 'react'

import { Link } from 'react-router-dom'

import './AuthorPost.scss'

import { Html5Entities } from 'html-entities';

const htmlEntities = new Html5Entities();

const AuthorPost = ({author, insidePostPage = false}) => {

    const { acf, name } = author;

    return (
        <div className="post__author">
            <Link to={`/blog/autoren/${author.slug}`}>
                <div className={`post__author--wrapper ${insidePostPage ? 'post__author--wrapper-reverse' : ''}`}>
                    <div className="post__author-img">
                        {acf.avatar.url && <img src={acf.avatar.url} alt={name} />}
                    </div>
                    <div className={`post__author--info ${insidePostPage ? 'post__author--info-reverse' : ''}`}>
                        <span className="author-name">
                            {htmlEntities.decode(name)}
                        </span>
                        {' '}
                        <span className="author-work">{acf.author_inform.work_place}</span>
                    </div>
                </div>
            </Link>
        </div>
    )
}

export default AuthorPost
