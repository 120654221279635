import React from "react";
import './Info.scss'
import ReadingTime from "../../../../ReadingTime";
import AuthorPost from "../AuthorPost/AuthorPost";
import useReadingTime from "../../../../../hooks/useReadingTime";

const Info = ({
                  postOptions,
                  allContent,
                  formattedDate,
                  editedDate,
                  item,
                  authorBreadcrumbs
              }) => {

    const estimationReadingData = {
        label: postOptions?.acf?.reading_time?.label,
        postfixPlural: postOptions?.acf?.reading_time?.postfix,
        postfixSingular: postOptions?.acf?.reading_time?.postfix_singular,
    }

    const {calculateEstimation} = useReadingTime({
        show: postOptions?.acf?.reading_time?.show,
        wordsPerMinute: postOptions?.acf?.reading_time?.words_per_minute,
    })

    const estimationReadingTime = calculateEstimation(allContent)

    return (
        <div className="post__info">
            <div className="post__info--details">
                <ReadingTime minutes={estimationReadingTime} {...estimationReadingData} />
                <div className="post__date">
                    <svg xmlns="http://www.w3.org/2000/svg"
                         viewBox="0 0 18 18" fill="none">
                        <path
                            d="M15.75 2.25H15V0.749954C15 0.336044 14.664 0 14.25 0H13.5C13.086 0 12.75 0.336044 12.75 0.749954V2.25H5.24995V0.749954C5.24995 0.336044 4.91405 0 4.5 0H3.75005C3.336 0 2.99995 0.336044 2.99995 0.749954V2.25H2.25C1.00951 2.25 0 3.25951 0 4.5V15.75C0 16.9905 1.00951 18 2.25 18H15.75C16.9905 18 18 16.9905 18 15.75V4.5C18 3.25951 16.9905 2.25 15.75 2.25ZM16.5 15.75C16.5 16.1632 16.1632 16.5 15.75 16.5H2.25C1.83678 16.5 1.50005 16.1632 1.50005 15.75V7.53003H16.5V15.75Z"
                            fill="#3E3E53"/>
                    </svg>
                    <span>{formattedDate}</span>
                </div>
                <div className="post__date--edited">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 19" fill="none">
                        <path
                              d="M16.5792 15.9072C16.5785 16.541 16.0735 17.0547 15.4502 17.0555H2.5498C1.92651 17.0547 1.42155 16.5411 1.42077 15.9073L1.42077 3.72217C1.42155 3.08827 1.92645 2.57468 2.54972 2.57389H6.63186C7.02422 2.57389 7.34224 2.25045 7.34224 1.8514C7.34224 1.45235 7.02422 1.12891 6.63186 1.12891L2.54951 1.12891C1.14226 1.13053 0.00159356 2.29034 1.49371e-07 3.72158L0 15.9075C0.00159327 17.3387 1.14197 18.4989 2.54922 18.5005L15.4505 18.5005C16.8577 18.4989 17.9984 17.339 18 15.9078V11.7556C18 11.3567 17.6821 11.0329 17.2896 11.0329C16.8973 11.0329 16.5792 11.3563 16.5792 11.7554V15.9072Z"
                              fill="#3E3E53"/>
                        <path d="M7.1875 8.82599L13.9008 1.99805L16.066 4.20005L9.3526 11.028L7.1875 8.82599Z"
                              fill="#3E3E53"/>
                        <path d="M6.09766 12.1424L8.49032 11.4684L6.76039 9.70898L6.09766 12.1424Z" fill="#3E3E53"/>
                        <path
                            d="M16.6679 0.841809C16.2187 0.386064 15.4915 0.386064 15.0423 0.841809L14.5547 1.33774L16.7198 3.53974L17.2074 3.04381C17.6557 2.58697 17.6557 1.84737 17.2074 1.39053L16.6679 0.841809Z"
                            fill="#3E3E53"/>
                    </svg>
                    <span>zuletzt aktualisiert: </span>
                    <span>{editedDate}</span>
                </div>
            </div>
            <div
                 onClick={() => authorBreadcrumbs(item._embedded.author[0].name)}>
                <AuthorPost author={item._embedded.author[0]} insidePostPage/>
            </div>
        </div>
    )
}

export default Info;