import React, {Component} from "react";

import Loader from "../../../Loader/Loader";
import { LazyLoadImage } from 'react-lazy-load-image-component';

import './Solutions.scss';

class Solutions extends Component {

    state = {
        slider_data: [],
        transform: "none",
        width: 50,
        transition: false
    };

    mouseMove(e) {
        let w, h;
        w = window.innerWidth;
        h = window.innerHeight;

        if (w > 992) {
            document.getElementById("scrollPosition").classList.remove("transition");

            let x, y, rotateX, moveY,
                boxPos = document.getElementById("scrollPosition").offsetTop;

            if (e.pageX || e.pageY) {
                x = e.pageX;
                y = e.pageY;
            } else if (e.clientX || e.clientY) {
                x = e.clientX + document.body.scrollLeft + document.documentElement.scrollLeft;
                y = e.clientY + document.body.scrollTop + document.documentElement.scrollTop;
            }

            y = -(boxPos - y);

            rotateX = ((w / 2 - x) / 1000) / 4;
            moveY = (y - h / 2) / 25;

            let movePercent = (((w / 2 - x) * 100) / w) / 3;

            this.setState({
                transform: "matrix3d(" + Math.cos(rotateX) + ", 0, " + Math.sin(rotateX) + ", 0, 0, 1, 0, 0, " + Math.sin(rotateX) + ", 0, " + Math.cos(rotateX) + ", 0 , 0, " + moveY + ", 0, 1)",
                width: 50 + movePercent,
            });

            if(this.state.transition) {
                this.setState({
                    transition: !this.state.transition,
                });
            }
        }
    }

    mouseLive() {
        this.setState({
            transform: "none",
            width: 50,
            transition: !this.state.transition,
        });
    }

    render() {

        if (this.props.content) {

            const left_side = this.props.content.left_side;
            const right_side = this.props.content.right_side;

            return (
                <>
                    <div className={`solutions${this.state.transition ? ' transition':""}`} id="scrollPosition" onMouseMove={this.mouseMove.bind(this)}
                         onMouseLeave={this.mouseLive.bind(this)}>
                        <div className="solutions__bg-elements view">
                            <div className="view__wrapper view__wrapper--before" id="viewBefore" style={{width:this.state.width+"%"}}>
                                <div className="view__content">
                                    <div className="view__bg-gears"></div>
                                    <div className="view__title">
                                        <h1 dangerouslySetInnerHTML={{__html: this.props.content.page_title}} />
                                    </div>
                                    <div className="view__image loading-block">
                                        <LazyLoadImage
                                            alt={left_side.title}
                                            id="imgBefore"
                                            style={{transform:this.state.transform}}
                                            src={left_side.poster.url} />
                                    </div>
                                </div>
                            </div>
                            <div className="view__wrapper view__wrapper--after">
                                <div className="view__content view__content--rotated-bg">
                                    <div className="view__bg-gears view__bg-gears--top-position"></div>
                                    <div className="view__title">
                                        <p dangerouslySetInnerHTML={{__html: this.props.content.page_title}} />
                                    </div>
                                    <div className="view__image loading-block">
                                        <LazyLoadImage
                                            alt={right_side.title}
                                            id="imgAfter"
                                            style={{transform:this.state.transform}}
                                            src={right_side.poster.url} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="solutions__content">
                            <div className="solutions__item solutions__item--left-col loading-block loading-block--2">
                                <div className="solutions__item-body">
                                    <h2 className="solutions__item-title">{left_side.title}</h2>
                                    <div className="solutions__item-description" dangerouslySetInnerHTML={{__html: left_side.description}} />
                                    {
                                        left_side.link_to_page ? <a href={left_side.link_to_page.url} className="solutions__item-link base-btn">{left_side.link_to_page.title}</a> : ''
                                    }
                                    <div className="solutions__item-image">
                                        <img src={left_side.poster_mobile.url} id="imgBefore" alt=""/>
                                    </div>
                                </div>
                            </div>
                            <div className="solutions__item solutions__item--right-col loading-block loading-block--2">
                                <div className="solutions__item-body">
                                    <div className="solutions__item-image">
                                        <img src={right_side.poster_mobile.url} id="imgAfter" alt=""/>
                                    </div>
                                    <h2 className="solutions__item-title">{right_side.title}</h2>
                                    <div className="solutions__item-description" dangerouslySetInnerHTML={{__html: right_side.description}} />
                                    {
                                        right_side.link_to_page ? <a href={right_side.link_to_page.url} className="solutions__item-link base-btn">{right_side.link_to_page.title}</a> : ''
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            );

        } else {
            return (
                <>
                    <div className='solutions'>
                        <div className="solutions__bg-elements view">
                            <div className="view__wrapper view__wrapper--before" id="viewBefore" style={{width:this.state.width+"%"}}>
                                <div className="view__content">
                                </div>
                            </div>
                            <div className="view__wrapper view__wrapper--after">
                                <div className="view__content view__content--rotated-bg">
                                </div>
                            </div>
                        </div>
                        <div className="solutions__content">
                            <Loader />
                        </div>
                    </div>
                </>
            );
        }
    }
}

export default Solutions;