import { useEffect, useState } from 'react';

export default function useElementOnScreen(containerRef, options, once = false) {
  const [isVisible, setIsVisible] = useState(false);

  const callback = (entires) => {
    const [entry] = entires;

    if (once && entry.isIntersecting) {
      setIsVisible(true);
    } else if (!once) {
      setIsVisible(entry.isIntersecting)
    }
  }

  useEffect(() => {
    let observer = null;
    const current = containerRef?.current || containerRef;

    try {
      observer = new IntersectionObserver(callback, options);
      if (current) {
        observer.observe(current);
      }
    } catch (error) {
      console.error(error);
      setIsVisible(true);
    }
    return () => {
      if (current) {
        try {
          observer.unobserve(current);
        } catch (error) {
          console.error(error);
        }
      }
    };
  }, [containerRef, options]);

  return isVisible;
}
