import React, { useEffect, useState, useRef } from 'react';

import NotFound from '../../Pages/NotFound/NotFound';

import ReactPaginate from 'react-paginate';

import queryString from 'query-string'

import { scroller } from 'react-scroll';

import axios from 'axios';

import API from '../../../api';

import { Link } from 'react-router-dom'

import Description from '../Description/Description';

import Articles from './Articles/Articles';

import CategoriesList from './CategoriesList';

import useMobile from "../../../hooks/useMobile";

import { withRouter } from 'react-router-dom';

import './Categories.scss';

import "./Pagination/Pagination.scss"

import AuthorPost from './Posts/AuthorPost/AuthorPost';

import Loader from '../../Loader/Loader';

import Swiper from 'react-id-swiper';

import { Html5Entities } from 'html-entities';

const API_POST_PER_PAGE = 9;

const Categories = (props) => {

  const isDesktop = window.innerWidth > 992;
  const queryVars = queryString.parse(props.history.location.search);
  const { content, history, match, populartagsBreadcrumbs, authorBreadcrumbsBlog, categoriesBreadcrumbs, onblogBreadcrumbs, breadcrumbsTitle, allCategories, currentBreadcrumb } = props;
  const { page, tag, author } = queryVars;
  const { upload_button_name } = props.content;
  const [windowWidth, setWindowWidth] = useState(0);

  const [tags, setTags] = useState([]);
  const [populareTags, setPopulareTags] = useState([]);
  const [loadedStatus, setLoadedStatus] = useState(false);
  const [posts, setPosts] = useState([]);
  const [autorenPosts, setAutorenPosts] = useState([]);
  const [postType, setPostType] = useState(null);
  const [recentAuthors, setRecentAuthors] = useState([]);
  const [authors, setAuthors] = useState([]);
  const [authorsIds, setAuthorsIds] = useState([]);
  const [total, setTotal] = useState(0);
  const [tagName, setTagName] = useState('');
  const [pageNotFound, setPageNotFound] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [authorAvatar, setAuthorAvatar] = useState(undefined);
  const { slug } = match.params;
  const [isNotFirstRequest, setIsNotFirstRequest] = useState(false);
  const isMobile = useMobile();
  const accButton = useRef();
  const panel = useRef();
  const activeCategory = allCategories.filter(category => category.slug === slug)
  const activeAuthor = authors.filter(author => author.slug === slug)
  const { category_title, posts_content } = props.content
  const htmlEntities = new Html5Entities();

  function titlingBlog(heading, description, icon) {
    setTitle(heading)
    setDescription(description)
    setAuthorAvatar(icon)
  }

  const featchAuthorsIds = async () => {
    await axios
      .get(API.basePath + '/wp-json/wp/v2/posts/?per_page=100')
      .then((response) => {
        setAuthorsIds(response.data)
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const fetchRecentAuthors = async () => {
    let authorPosts = await axios
      .get(API.basePath + '/wp-json/wp/v2/posts/')
      .then((response) => {
        return recentAuthorPosts(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    await axios
      .get(API.basePath + '/wp-json/wp/v2/users/?include=' + authorPosts)
      .then((response) => {
        let arr = [];
        let i = 0;
        authorPosts.forEach((i) => {
          response.data.forEach((item) => {
            if (i === item.id) {
              arr.push(item)
            }
          })
        })
        setRecentAuthors(arr)
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function showInfo() {
    const offset = page ? API_POST_PER_PAGE * (page - 1) : 0;
    setLoadedStatus(false)
    if (props.history.location.pathname === "/blog/autoren/") {
      fetchAuthorsPosts();
    } else if (slug) {
      if (props.history.location.pathname.slice(6).includes('autoren/')) {
        axios.get(API.basePath + `/wp-json/wp/v2/users?slug=${slug}`).then((response) => {
          if (response.data[0]) {
            fetchPosts({ author: response.data[0].id, offset, page });
          }
        })
      } else if (slug === "autoren") {
        fetchAuthorsPosts();
      } else {
        axios.get(API.basePath + `/wp-json/wp/v2/categories?slug=${slug}`).then((response) => {
          if (response.data[0]) {
            fetchPosts({ categories: response.data[0].id, offset, page });
          }
        })
      }
    } else if (tag) {
      axios.get(API.basePath + `/wp-json/wp/v2/tags?slug=${tag}`).then((response) => {
        if (response.data[0]) {
          setTagName(response.data[0].name)
          fetchPosts({ tags: response.data[0].id, offset, page });
        }
      })
    } else {
      fetchPosts({ offset, page });
    }
  }

  useEffect(() => {
    setPostType(null)
    setPosts([])
    fetchRecentAuthors();
    featchAuthorsIds();
    axios
      .get(API.basePath + '/wp-json/wp/v2/tags/?per_page=200')
      .then((response) => {
        setPopulareTags(filterTags(response.data))
      })
      .catch((error) => {
        console.log(error);
      });
    showInfo()
  }, [fetchPosts, page, slug, tag, author]);

  useEffect(() => {
    if (props.history.location.pathname === "/blog/autoren/") {
      titlingBlog(content.sublist.all_authors_title, props.content.description.default_text, undefined);
      categoriesBreadcrumbs(content.sublist.authors_breadcrumbs_text, 'category.count');
    } else if (slug) {
      if (props.history.location.pathname.slice(6).includes('autoren/')) {
        authors.map((item) => {
          if (props.history.location.pathname.slice(6) === `autoren/${item.slug}`) {
            authorBreadcrumbsBlog(item.name);
            titlingBlog(`${content.description.authors_title} <br> ${item.name}`, item.description, item.acf.avatar);
          }
        })
      } else if (slug === "autoren") {
        titlingBlog(content.sublist.all_authors_title, props.content.description.default_text, undefined);
        categoriesBreadcrumbs(content.sublist.authors_breadcrumbs_text, 'category.count');
      } else {
        allCategories.map((item) => {
          if (slug === item.slug) {
            categoriesBreadcrumbs(item.name);
            titlingBlog(item.name, item.description, undefined);
          }
        })
      }
    } else if (tag) {
      let notFoundTrigger = 404;
      tags.map((item) => {
        if (tag === item.slug || tag === item.slug + '/') {
          populartagsBreadcrumbs(item.name);
          titlingBlog(`${content.description.tags_title} <br> #${item.name}`, item.description, undefined);
          setPageNotFound('200')
          notFoundTrigger = 200;
        }
        if (notFoundTrigger === 404) {
          setPageNotFound('404')
        }
      })
    } else {
      titlingBlog(props.content.description.default_title, props.content.description.default_text, undefined);
    }
  }, [tags, recentAuthors, allCategories, slug]);

  const filterTags = (data) => {
    if (data && data.length) {
      let tagsArray = [];
      data.map((item) => {
        if (item.count > 0) {
          tagsArray.push(item)
        }
      })
      sortByCount(tagsArray)

      function sortByCount(tagsArray) {
        tagsArray.sort((a, b) => a.count < b.count ? 1 : -1);
      }

      let popularTagsArray = tagsArray.slice(0, 6);
      return popularTagsArray;
    }
  }

  const recentAuthorPosts = (data) => {
    const arr = [];
    const firstAuthor = data[0].author;
    arr.push(firstAuthor)

    data.map((item, key) => {
      arr.push(item.author);
    })

    const secondAuthor = arr.find(element => element !== firstAuthor);
    const thirdAuthor = arr.find(element => element !== firstAuthor && element !== secondAuthor);

    const result = [];
    result.push(firstAuthor)
    result.push(secondAuthor)
    result.push(thirdAuthor)
    return result;
  }
  const getWindowWidth = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    fetchAuthors()
    fetchTags()
    window.addEventListener("resize", getWindowWidth);

    return () => {
      window.removeEventListener("resize", getWindowWidth);
    };
  }, [])


  const fetchAuthorsPosts = async () => {
    setLoadedStatus(false);

    const postsAuthorsUrl = `${API.basePath}/wp-json/wp/v2/users`;
    try {
      const postsAuthorsResult = await axios.get(postsAuthorsUrl);

      setPostType('autoren');
      setAutorenPosts(postsAuthorsResult.data);

    } catch (e) {
      console.log(e);


    } finally {
      setLoadedStatus(true);
    }

  }

  const fetchPosts = async (queryObj) => {
    setLoadedStatus(false);
    let offset = page ? API_POST_PER_PAGE * (page - 1) : 0;
    let per_page = API_POST_PER_PAGE;

    const queryData = {
      per_page,
      offset: offset,
      ...queryObj
    };

    const getQuery = queryString.stringify(queryData);
    const postsUrl = `${API.basePath}/wp-json/wp/v2/posts?${getQuery}`;
    try {
      const postsResult = await axios.get(postsUrl);

      setTotal(postsResult.headers["x-wp-total"]);

      if (window.innerWidth > 992) {

        setPostType('posts')
        setPosts(postsResult.data)

      } else if (window.innerWidth < 992 && postsResult.data.length > 0) {

        let result = [];

        if (queryObj.page) {
          result = isNotFirstRequest ? [...postsResult.data, ...posts] : [...posts, ...postsResult.data]
        }
        else {
          result = [...postsResult.data];
        }

        setPostType('posts')
        setPosts(result)
      }

    } catch (e) {
      console.log(e);


    } finally {
      setLoadedStatus(true);
    }

  }

  const fetchAuthors = async () => {
    try {
      const authorsResult = await axios.get(API.basePath + '/wp-json/wp/v2/users/?per_page=100');
      setAuthors(authorsResult.data)
      setIsNotFirstRequest(false);
    }
    catch (e) {
      console.log(e);
    }
  }

  const fetchTags = async () => {
    try {
      const authorsResult = await axios.get(API.basePath + '/wp-json/wp/v2/tags/?per_page=200');
      setTags(authorsResult.data)
      setIsNotFirstRequest(false);
    }
    catch (e) {
      console.log(e);
    }
  }

  const getAllPosts = () => {
    props.history.push(`/blog`);
    setIsNotFirstRequest(false);
    scroller.scrollTo("blog__description", {
      duration: 700,
      delay: 0,
      smooth: "easeOutCubic",
    });
  }

  const handlePageClick = ({ selected }) => {
    const query = { page: selected + 1 };
    if (tag) {
      query.tag = tag
    }
    history.push({
      search: `?${queryString.stringify(query)}`
    })
    if (isDesktop) {
      scrollOnChangeCategory()
    }
  }

  const handlePageClickTop = ({ selected }) => {
    setIsNotFirstRequest(true);
    handlePageClick({ selected })
  }

  let scrollOnChangeCategory = () => {
    scroller.scrollTo("blog__description", {
      duration: 700,
      delay: 0,
      smooth: "easeOutCubic",
    })
  }

  const pagesQuantity = Math.ceil(total / API_POST_PER_PAGE);

  let currentPageNumber = page === undefined && !isNotFirstPage ? 1 : page
  const isLastPage = pagesQuantity === parseInt(currentPageNumber);
  const hideMobilePagination = isLastPage && !isDesktop;
  const isNotFirstPage = page !== undefined && page != 1;
  const disableCondition = posts.length <= total / currentPageNumber && isLastPage;

  const showPrevLabel = () => {
    const postLength = (posts.length <= API_POST_PER_PAGE || (total - posts.length) > 0) && !(posts.length == API_POST_PER_PAGE * page)
    if (!isDesktop && isNotFirstPage && postLength) {
      return true
    }
  }

  const showNextLabel = () => {
    if (posts.length && !hideMobilePagination && !isNotFirstRequest) {
      return true
    }
  }

  const params = {
    slidesPerView: 'auto',
    freeMode: true,
    spaceBetween: 10,
  }

  const getBreadcrumbSchemaName = () => {
    let breadCrumbTitle = '';
    if (props.location.pathname === '/blog' || props.location.pathname === '/blog/') {
      breadCrumbTitle = 'Blog'
    }else if(currentBreadcrumb){
      breadCrumbTitle = currentBreadcrumb;
    }else{
      breadCrumbTitle = title
    }
    return breadCrumbTitle
  }

  if (pageNotFound === '404') {
    return (
      <NotFound setContactElements={props.setContactElements} />
    )
  } else {
    const breadcrumbsSecondListItem = getBreadcrumbSchemaName();

    const breadcrumbsStructuredData = {
      "@context": "https://schema.org/",
      "@type": "BreadcrumbList",
      "@id": `${window.origin}/blog#breadcrumb`,
      "itemListElement": [
        { "@type": "ListItem", "position": 1, "name": "Startseite", "item": `${window.origin}` },
        { "@type": "ListItem", "position": 2, "name": `${breadcrumbsSecondListItem}` }
      ]
    }

    return (
      <>
        <Description content={content} title={title} description={description} authorAvatar={authorAvatar} />
        <section className="categories">
          <div className="categories__breadcrumbs">
            <script className='structured-data-list' type="application/ld+json">
                {JSON.stringify(breadcrumbsStructuredData)}
            </script>
            <Link to='/' className="home-link">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="12" cy="12" r="12" fill="url(#paint0_linear_10126_3637)"/>
                <path d="M18.9692 17.4411C18.9729 17.6282 18.9393 17.8141 18.8705 17.9881C18.8016 18.162 18.6989 18.3205 18.5683 18.4542C18.4377 18.588 18.2818 18.6943 18.1098 18.767C17.9377 18.8397 17.7529 18.8774 17.5662 18.8777C17.3779 18.878 17.1914 18.841 17.0173 18.7689C16.8433 18.6968 16.6851 18.591 16.552 18.4575C16.4188 18.3241 16.3132 18.1656 16.2413 17.9912C16.1693 17.8167 16.1324 17.6298 16.1327 17.4411V16.8297C15.4922 17.8996 13.7232 19 11.7406 19C8.05005 19 5 15.8821 5 12C5.00066 11.0784 5.18279 10.1659 5.53594 9.31497C5.8891 8.464 6.40633 7.69123 7.05802 7.04096C7.7097 6.39068 8.48301 5.87568 9.33365 5.52546C10.1843 5.17524 11.0955 4.99669 12.0151 5.00005C16.3462 5.00005 19.0302 8.24021 18.9997 12L18.9692 17.4411ZM12.0151 7.64924C9.57508 7.64924 7.98905 9.60556 7.98905 11.9898C7.98905 14.3741 9.57508 16.3304 12.0151 16.3304C14.4552 16.3304 16.0412 14.3741 16.0412 11.9898C16.0412 9.60556 14.4552 7.64924 12.0151 7.64924Z" fill="white"/>
                <defs>
                <linearGradient id="paint0_linear_10126_3637" x1="24" y1="24" x2="0" y2="0" gradientUnits="userSpaceOnUse">
                <stop stopColor="#FC6076"/>
                <stop offset="0.484375" stopColor="#FF4D66"/>
                <stop offset="1" stopColor="#FF6826"/>
                </linearGradient>
                </defs>
              </svg>
            </Link>
            <div className="categories__breadcrumbs_item categories__breadcrumbs_item--page">
              <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M7.18436 11.3545C7.62418 10.9476 7.62418 10.2524 7.18436 9.84554L3.02703 6L7.18436 2.15446C7.62418 1.74763 7.62418 1.05237 7.18436 0.645537C6.79045 0.281168 6.18252 0.281169 5.78861 0.645537L6.91406e-07 6L5.78861 11.3545C6.18252 11.7188 6.79045 11.7188 7.18436 11.3545Z" fill="#3E3E53" />
              </svg>
              <Link to='/blog' onClick={() => { titlingBlog(props.content.description.default_title, props.content.description.default_text, undefined); onblogBreadcrumbs() }}><span>Blog</span></Link>
            </div>
            {breadcrumbsTitle &&
              <div className="categories__breadcrumbs_item">
                <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M7.18436 11.3545C7.62418 10.9476 7.62418 10.2524 7.18436 9.84554L3.02703 6L7.18436 2.15446C7.62418 1.74763 7.62418 1.05237 7.18436 0.645537C6.79045 0.281168 6.18252 0.281169 5.78861 0.645537L6.91406e-07 6L5.78861 11.3545C6.18252 11.7188 6.79045 11.7188 7.18436 11.3545Z" fill="#3E3E53" />
                </svg>
                <span>{htmlEntities.decode(breadcrumbsTitle)}</span>
              </div>
            }
          </div>
          <div className="categories__lists">
            <div className="categories__main-list">
              {(isMobile && allCategories.length) &&
                <div className="categories__mainlist_wrapper">
                  <div className="categories__mainlist_title" >{content.sublist.categories_title}</div>
                  <Swiper {...{
                    slidesPerView: 'auto',
                    freeMode: true,
                    spaceBetween: 10,
                  }}
                  >
                    <div className="categories__mainlist_item" key={0}>
                      <span className="categories__mainlist_item--title"
                        onClick={() => { getAllPosts(); titlingBlog(props.content.description.default_title, props.content.description.default_text, undefined); categoriesBreadcrumbs(undefined) }}>{content.sublist.all_articles_text}</span>
                    </div>
                    {Object.values(props.categories).map((category, key) => {
                      return (
                        <div className="categories__mainlist_item" key={key}>
                          <Link to={`/blog/${category.slug}`}>
                            <span className="categories__mainlist_item--title"
                              onClick={() => { scrollOnChangeCategory(); titlingBlog(category.name, category.description, undefined); categoriesBreadcrumbs(category.name) }} dangerouslySetInnerHTML={{ __html: htmlEntities.decode(category.name)}}></span>
                          </Link>
                        </div>
                      )
                    })}
                  </Swiper>
                </div>
              }
              {showPrevLabel() && slug != 'autoren' ?
                <div className="pagination-wrapper" style={{ position: 'relative' }}>
                  <ReactPaginate
                    previousLabel={content.read_more_button_text}
                    nextLabel={false}
                    breakLabel={false}
                    pageCount={pagesQuantity}
                    onPageChange={handlePageClickTop}
                    previousClassName={'pagination__mobile pagination__mobile--top'}
                    forcePage={page ? page - 1 : 0}
                    pageCount={false}
                  />
                  {loadedStatus || window.innerWidth >= 992 ? null : <Loader />}
                </div>
                :
                null}
              <Articles
                authorIds={authorsIds}
                loadedStatus={loadedStatus}
                postType={postType}
                posts={posts}
                autorenPosts={autorenPosts}
                activeCategory={allCategories.filter(category => category.slug === slug)}
                activeAuthor={allCategories.filter(author => author.slug === slug)}
                categoriesBreadcrumbs={categoriesBreadcrumbs}
                titlingBlog={titlingBlog}
                readMoreButton={content.read_more_button_text}
                readMoreAuthorsButton={content.read_more_authors_button_text}
              />


              {showNextLabel() && slug != 'autoren' ?
                <div className={`pagination-wrapper ${(disableCondition && !page) ? "pagination__disable" : "pagination-wrapper"}`} style={{ position: 'relative' }}>
                  <ReactPaginate
                    previousLabel={isDesktop ? <i className="icon-angle-left" /> : false}
                    nextLabel={isDesktop ? <i className="icon-angle-right" /> : content.read_more_button_text}
                    breakLabel={isDesktop ? '...' : false}
                    breakClassName={'break-me'}
                    pageCount={pagesQuantity}
                    onPageChange={handlePageClick}
                    containerClassName={`pagination ${(disableCondition && !page) ? "disable" : " "}`}
                    pageClassName={`${(disableCondition && !page) ? "pagination__disable" : "pagination__item"}`}
                    subContainerClassName={'pages pagination'}
                    activeClassName={'pagination__item-active'}
                    previousClassName={'pagination__btn pagination__btn--prev'}
                    nextClassName={isDesktop ? 'pagination__btn pagination__btn--next' : `${disableCondition ? "pagination__disable" : "pagination__mobile"}`}
                    forcePage={page ? page - 1 : 0}
                  />
                  {loadedStatus || window.innerWidth >= 992 ? null : <Loader />}
                </div>
                :
                null
              }
            </div>
            <div className="categories__sub-list">
              <div className="sublist-wrapper">
                {!isMobile &&
                  <div className="sublist-kategorien">
                    <div className="categories__sub-title">
                      {content.sublist.categories_title}
                    </div>
                    <div className="sublist-kategorien__content">
                      <div className="categories__item">
                        <span onClick={() => { getAllPosts(); scrollOnChangeCategory(); titlingBlog(props.content.description.default_title, props.content.description.default_text, undefined); categoriesBreadcrumbs(undefined) }}>{content.sublist.all_articles_text}</span>
                      </div>
                      <CategoriesList
                        scrollOnChangeCategory={scrollOnChangeCategory}
                        categories={props.categories}
                        categoriesBreadcrumbs={categoriesBreadcrumbs}
                        titlingBlog={titlingBlog}
                      />
                    </div>
                  </div>
                }
                <div className="sublist-tags">
                  <div className="categories__sub-title">{content.sublist.tags_title}</div>
                  <ul>
                    {
                      populareTags.map((item, key) => {
                        return (
                          <li key={key}><Link to={`/blog?tag=${item.slug}`} onClick={() => { scrollOnChangeCategory(); titlingBlog(`${content.description.tags_title} <br> #${item.name}`, item.description, undefined); }}><span>#{item.name}</span></Link></li>
                        )
                      })
                    }
                  </ul>
                </div>
                <div className="sublist-autoren">
                  <div className="categories__sub-title">{content.sublist.authors_title}</div>
                  <div className="sublist-autoren__list">
                    {recentAuthors[0] &&
                      <div className="sublist-autoren__list_item" onClick={() => { scrollOnChangeCategory(); titlingBlog(`${content.description.authors_title} <br> ${recentAuthors[0].name}`, recentAuthors[0].description, recentAuthors[0].acf.avatar); authorBreadcrumbsBlog(recentAuthors[0].name) }}>
                        <AuthorPost author={recentAuthors[0]} />
                      </div>
                    }
                    {recentAuthors[1] &&
                      <div className="sublist-autoren__list_item" onClick={() => { scrollOnChangeCategory(); titlingBlog(`${content.description.authors_title} <br> ${recentAuthors[1].name}`, recentAuthors[1].description, recentAuthors[1].acf.avatar); authorBreadcrumbsBlog(recentAuthors[1].name) }}>
                        <AuthorPost author={recentAuthors[1]} />
                      </div>
                    }
                    {recentAuthors[2] &&
                      <div className="sublist-autoren__list_item" onClick={() => { scrollOnChangeCategory(); titlingBlog(`${content.description.authors_title} <br> ${recentAuthors[2].name}`, recentAuthors[2].description, recentAuthors[2].acf.avatar); authorBreadcrumbsBlog(recentAuthors[2].name) }}>
                        <AuthorPost author={recentAuthors[2]} />
                      </div>
                    }
                  </div>
                  <div className="authors-button">
                    <Link to={`/blog/autoren`}>
                      <span className="authors-button--title"
                        onClick={() => { scrollOnChangeCategory(); titlingBlog(content.sublist.all_authors_title, props.content.description.default_text, undefined); categoriesBreadcrumbs(content.sublist.authors_breadcrumbs_text, 'category.count') }}>{content.sublist.authors_button_text}</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
};

export default withRouter(Categories);
