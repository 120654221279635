import React, { Component } from "react";

import MainBanner from '../../MainBanner/MainBanner';
import { Link } from "react-router-dom";

import "./Hosting.scss";

import PageMeta from "../../PageMeta/PageMeta";
import CustomSchema from "../../CustomSchema/CustomSchema";

import axios from "axios";
import API from "../../../api";
import Review from "./Review";
import SplitScreen from "./SplitScreen/SplitScreen";
import Technologies from "./Technologies/Technologies";

class Hosting extends Component {

    state = {
        page_data: [],
        yoast_meta: [],
        loaded_status: false
    };

    componentDidMount() {
        // Get company menu items
        axios.get(API.basePath + '/wp-json/wp/v2/pages?slug=hosting')
            .then(response => {
                this.setState({
                    page_data: response.data[0].acf,
                    yoast_meta: response.data[0],
                    loaded_status: true
                });
            })
            .catch(error => {
                console.log(error);
            });
        this.props.setContactElements();
    }


    render() {

        if (this.state.loaded_status) {

            const page_data = this.state.page_data.hosting_content;
            return (
                <>
                    <PageMeta metaData={this.state.yoast_meta || null} hreflangs={this.state.page_data['hreflang_list'] || null} />
                    <CustomSchema schema={this.state.page_data.schema} />
                    <div className="hosting-page">
                        <MainBanner content={this.state.page_data.main_banner_content} id='hosting' />
                        <div className="hosting-page__content" id="scrollPosition">

                            <div className="about-hosting">
                                <div className="about-hosting__title">
                                    <h1 className="about-hosting__title-text" dangerouslySetInnerHTML={{ __html: page_data.about_hosting.title }} />
                                    <div className="about-hosting__title-bg" dangerouslySetInnerHTML={{ __html: page_data.about_hosting.title_bg }} />
                                </div>
                                <div className="about-hosting__content">
                                    <div className="about-hosting__description" dangerouslySetInnerHTML={{ __html: page_data.about_hosting.description }} />
                                    <div className="about-hosting__logotypes">
                                        <div className="about-hosting__logotypes-item">
                                            <img src={page_data.about_hosting.logo_shopware.url} alt="" />
                                        </div>
                                        <div className="about-hosting__logotypes-item">
                                            <img src={page_data.about_hosting.logo_magento.url} alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="about-hosting__advantages">
                                    {
                                        page_data.about_hosting.advantages.map((item, key) => {
                                            return (
                                                <div className="about-hosting__advantage" key={key}>
                                                    <div className="about-hosting__advantage-icon" dangerouslySetInnerHTML={{ __html: item.advantage.icon }} />
                                                    <div className="about-hosting__advantage-label" dangerouslySetInnerHTML={{ __html: item.advantage.label }} />
                                                    <div className="about-hosting__advantage-index">{key + 1}</div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>

                            <div className="hosting-split-screen">
                                <SplitScreen content={page_data.split_screen || null} />
                            </div>

                            <div className="hosting-technologies">
                                <Technologies content={page_data.technologies || null} />
                            </div>

                            <div className="hosting-reviews">
                                <div className="hosting-reviews__title">
                                    <h2 className="hosting-reviews__title-text" dangerouslySetInnerHTML={{ __html: page_data.reviews.title }} />
                                    <div className="hosting-reviews__title-bg" dangerouslySetInnerHTML={{ __html: page_data.reviews.title_bg }} />
                                </div>
                                <div className="hosting-reviews__content">
                                    <div className="hosting-reviews__review hosting-reviews__review--left">
                                        <Review data={page_data.reviews.left_review} />
                                        <div className="hosting-reviews__more-link">
                                            <Link className="base-btn" to={page_data.reviews.left_review.link.url}>{page_data.reviews.left_review.link.label}</Link>
                                        </div>
                                    </div>
                                    <div className="hosting-reviews__screen">
                                        <img src={page_data.reviews.screen.url} alt="" />
                                    </div>
                                    <div className="hosting-reviews__review hosting-reviews__review--right">
                                        <Review data={page_data.reviews.right_review} />
                                        <div className="hosting-reviews__more-link">
                                            <Link className="base-btn" to={page_data.reviews.right_review.link.url}>{page_data.reviews.right_review.link.label}</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            );

        } else {
            return (
                <>
                    <PageMeta metaData={this.state.yoast_meta || null} />
                    <div className="hosting-page">
                        <MainBanner content={this.state.page_data.main_banner_content || null} id='hosting' />
                    </div>
                </>
            );
        }
    }
}

export default Hosting;