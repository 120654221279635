import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import './ContactBlock.scss';

const ContactButton = (props) => {
    const {
        button_text,
        image,
        first_label,
        second_label,
        third_label,
        title,
        text,
        background_text,
    } = props.data;

    const [isContactActive, setIsContactActive] = useState(false);

    useEffect(() => {
        setIsContactActive(false);
        window.addEventListener('scroll', animOnScroll);
        return () => {
            window.removeEventListener('scroll', animOnScroll);
        };
    }, [
        props.location.pathname,
        props.preventContactBlockAnimation,
        props.smallButtonStatus,
    ]);

    function animOnScroll(event) {
        const monitorHeight = window.innerHeight;
        const scrolledPixels = window.pageYOffset;
        const pageHeight = event.srcElement.body.offsetHeight;
        const footerHeight =
            document.querySelector('.page__footer').clientHeight +
            document.querySelector('.contactBlock').clientHeight;

        if (props.preventContactBlockAnimation) {
            if (scrolledPixels + monitorHeight > pageHeight - footerHeight) {
                setIsContactActive(true);
            } else {
                setIsContactActive(false);
            }
        } else {
            if (scrolledPixels + monitorHeight > pageHeight - footerHeight) {
                setIsContactActive(true);
            }
        }
    }

    return (
        <div
            className={`contactBlock ${
                isContactActive ? 'contactBlock--active' : ''
            }`}
        >
            <LazyLoadImage
                alt={image.alt}
                className='contactBlock__image'
                src={image.url} />

            <div className='contactBlock__holder'>

                <div className='contactBlock__itemsHolder'>
                    <div className='contactBlock__itemsHolder_item contactBlock__itemsHolder_item--1'>
                        {first_label}
                    </div>
                    <div className='contactBlock__itemsHolder_item contactBlock__itemsHolder_item--2'>
                        {second_label}
                    </div>
                    <div className='contactBlock__itemsHolder_item contactBlock__itemsHolder_item--3'>
                        {third_label}
                    </div>
                </div>

                <div className='contactBlock__textHolder'>
                    <p className='contactBlock__textHolder_title'>{title}</p>
                    <p className='contactBlock__textHolder_text'>{text}</p>
                </div>

                <div className='contactBlock__bgText'>
                    <span>{background_text}</span>
                </div>

            </div>

            {props.smallButtonStatus ? (
                <Link
                    to='/kontakt'
                    className={`smallContactButton ${
                        isContactActive
                            ? 'smallContactButton--inActive'
                            : 'smallContactButton--active'
                    }`}
                >
                    <div className='smallContactButton__inner'>
                        <i className='icon-envelope'></i>
                        <p>{button_text}</p>
                    </div>
                    <div className='smallContactButton__shadow smallContactButton__shadow--first'></div>
                    <div className='smallContactButton__shadow smallContactButton__shadow--second'></div>
                </Link>
            ) : (
                ''
            )}

            <Link to='/kontakt' className='bigContactButton'>
                <div className='bigContactButton__inner'>
                    <i className='icon-envelope'></i>
                    <p>{button_text}</p>
                </div>
            </Link>
        </div>
    );
};

export default withRouter(ContactButton);
