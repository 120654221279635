import React, { useRef, useEffect, useState, useCallback } from "react"

import Particles from "react-particles-js";

import Plx from "react-plx/lib/Plx";

import "./TimeLine.scss"

import useMobile from "../../../../hooks/useMobile"


const TimeLine = (props) => {
    const { title, start_block_image, work_schedule, comming_soon, rocket_launch } = props.content
    const animationBlock = useRef(null);
    const [displayedShedule, setDisplayedShedule] = useState([]);
    const isMobile = useMobile();
    const { canAnimate } = props;

    useEffect(() => {
        setDisplayedShedule([...work_schedule].reverse())
    }, [isMobile, work_schedule])

    function getRandomIntInclusive(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    const createElement = useCallback(
        () => {
            const divElement = document.querySelector(".exhaust-fumes");

            let i = 1;
            let j = -18
            while (i <= 12) {
                let w = Math.floor(Math.abs(400 / j));
                if (w >= 120) w = 90;
                if (!document.getElementById("smoke" + i)) {
                    const element = document.createElement("span");
                    element.setAttribute("id", "smoke" + i);
                    element.setAttribute("class", "inner");
                    element.style.width = getRandomIntInclusive(w + 100, w + 150) + "px";
                    element.style.height = element.style.width;
                    element.style.setProperty("--x", i * 55)
                    element.style.setProperty("--y", w)
                    divElement.appendChild(element);
                }
                i++;
                j += 4;
            }
        },
        [],
    )

    const particles = {
        particles: {
            number: { value: 15, },
            color: { value: "#3E3E53" },
            opacity: {
                value: 0.9,
                anim: { enable: true }
            },
            size: {
                value: 20,
                random: true,
                anim: {
                    enable: true,
                    speed: 5
                }
            },
            line_linked: {
                enable: false
            },
            move: {
                speed: 5
            }
        }
    }

    const handleScroll = useCallback(
        () => {
            if(!isMobile) {
                const rocket = animationBlock.current.getBoundingClientRect();

                if (canAnimate(rocket)) {
                    createElement()
                }
            }
        },
        [canAnimate, createElement, isMobile],
    )

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        }
    }, [handleScroll])

    const parallaxData = [
        {
            start: ".timeline",
            end: ".support-projects",
            duration: 200,
            properties: [
                {
                    startValue: 60,
                    endValue: -20,
                    unit: "%",
                    property: "translateY",
                },
            ],
        },
    ];

    const RocketWrapper = React.forwardRef((props, ref) => <Plx ref={ref} parallaxData={parallaxData} {...props} />)

    return (
        <section className="timeline">
            <div className="timeline__title">
                <h2 className="timeline__title_text">{title}</h2>
                <div className="timeline__title_bg">{title}</div>
            </div>
            <div className="timeline__wrapper">
                <div className="timeline__content">
                    <div className="timeline__item">
                        <div className="timeline__item--inner">
                            <div className="timeline__item_date">
                                <svg width="46" height="24" viewBox="0 0 46 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M37.3018 0.54471C32.1294 -1.01645 26.8555 0.869107 23.8637 5.35999L23.2957 6.20139L22.7278 5.35999C19.7257 0.869107 14.4722 -1.02659 9.28962 0.54471C4.13749 2.10587 0.800781 6.59675 0.800781 12C0.800781 17.3931 4.1375 21.884 9.29977 23.4553C14.462 25.0266 19.7359 23.1309 22.7379 18.64L23.3059 17.7986L23.8738 18.64C23.9955 18.8326 24.1274 19.0151 24.2592 19.1874C26.5614 22.2794 30.0198 23.9926 33.7014 23.9926C34.888 23.9926 36.0949 23.8202 37.3018 23.4553C42.4641 21.8941 45.8008 17.3931 45.8008 12C45.8008 6.59675 42.4641 2.10587 37.3018 0.54471ZM17.3931 15.0716C15.7095 17.5959 13.0827 17.8797 11.1659 17.3019C9.2592 16.724 7.2308 15.0311 7.2308 12C7.2308 8.96891 9.26934 7.26582 11.1659 6.69813C11.6933 6.53593 12.2714 6.44469 12.8697 6.44469C14.4519 6.44469 16.1659 7.09349 17.3931 8.92836L19.4418 12L17.3931 15.0716ZM35.4357 17.3019C33.5391 17.8797 30.9022 17.5959 29.2186 15.0716L28.1233 13.4192L27.1699 11.9899L29.2186 8.91822C30.9022 6.394 33.529 6.11016 35.4357 6.68799C37.3424 7.26582 39.3708 8.95877 39.3708 11.9899C39.3708 15.0311 37.3424 16.724 35.4357 17.3019Z" fill="url(#paint0_linear)"/>
                                    <path d="M28.1143 13.4192L24.2502 19.1874C24.1184 19.005 23.9865 18.8225 23.8648 18.64L23.2969 17.7986L27.161 12L28.1143 13.4192Z" fill="#67121E" fillOpacity="0.17"/>
                                    <path d="M23.3096 6.20086L19.4455 11.9589C19.3137 11.7764 19.1818 11.594 19.0601 11.4115L18.4922 10.5701L22.3563 4.77148L23.3096 6.20086Z" fill="#67121E" fillOpacity="0.17"/>
                                    <defs>
                                        <linearGradient id="paint0_linear" x1="45.8008" y1="24" x2="25.87" y2="-13.3702" gradientUnits="userSpaceOnUse">
                                            <stop stopColor="#FC6076"/>
                                            <stop offset="0.484375" stopColor="#FF4D66"/>
                                            <stop offset="1" stopColor="#FF6826"/>
                                        </linearGradient>
                                    </defs>
                                </svg>
                            </div>
                            <div className="timeline__item_content">
                                <div className="timeline__item_title">{comming_soon.title}</div>
                                <img src={comming_soon.image.url} alt={comming_soon.image.alt} />
                            </div>
                        </div>
                    </div>
                    {displayedShedule.map((item, key) => {
                        return (
                            <div className="timeline__item" key={key}>
                                <div 
                                    className={`timeline__item--inner
                                    ${item.add_achievements === false && item.add_image_block === true && item.image_block.title === '' ? 'image-only' : '' }`}
                                >
                                    <div className="timeline__item_date">
                                        <span>{item.year}</span>
                                    </div>
                                    <div 
                                        className={`timeline__item_content
                                        ${item.add_achievements === true && item.achievements.title === '' ? 'no-achievements-title' : 'has-achievements-title'}`
                                    }>
                                        {
                                            item.add_achievements === true ? (
                                                <div className="achievements">
                                                    {item.achievements.title && <div className="timeline__item_title">{item.achievements.title}</div>}
                                                    <div className="achievements__container">
                                                        {item.achievements.numbers.map((achievement, key) => {
                                                            return (
                                                                <div className="achievements__item" key={key}>
                                                                    <div className="achievements__item--inner">
                                                                        <div className="achievements__item_number">{achievement.number}</div>
                                                                        <div className="achievements__item_text" dangerouslySetInnerHTML={{ __html: achievement.text }}></div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            ) : ('')
                                        }

                                        {item.add_image_block && item.image_block_list && item.image_block_list.map((image_block_item, idx) => {
                                            return (
                                                <div 
                                                    className={`image-block
                                                    ${item.add_achievements === true && item.add_image_block === true ? 'add-line' : '' }`}
                                                    key={idx}
                                                >
                                                    <div className="image-block__content">
                                                        {image_block_item.image_block.title && (
                                                            <div className="timeline__item_title">{image_block_item.image_block.title}</div>
                                                        )}
                                                        <img src={image_block_item.image_block.image.url} alt={image_block_item.image_block.image.alt} />
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
                <img className="timeline__start-image" src={start_block_image.url} alt={start_block_image.alt} />
                { !isMobile &&
                    <div className="timeline__rocket-container">
                        <RocketWrapper className="timeline__rocket" >
                            <img src={rocket_launch.rocket.url} alt="rocket" ref={animationBlock} />
                        </RocketWrapper>
                        <div className="exhaust-fumes"></div>
                        <Particles className="tsparticles" params={particles} />
                    </div>
                }
            </div>
        </section>
    )
}

export default TimeLine
