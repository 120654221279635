import readingTime from 'reading-time/lib/reading-time';

export default function useReadingTime({ show = false, wordsPerMinute = 300 }) {
    const calculateEstimation = (content = '') => {
        if (!show) {
            return 0
        }

        return Math.ceil(readingTime(content, { wordsPerMinute: wordsPerMinute })?.minutes)
    }

    return { calculateEstimation }
}
