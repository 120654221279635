import React, {useContext} from 'react'
import './PostTechnologies.scss'
import ThemeGeneralSettingsContext from '../../ThemeGeneralSettingsContext';
import PostOtherTechnologies from "../PostOtherTechnologies/PostOtherTechnologies";

export default function PostTechnologies({
 page_data = {},
 rightSidebarRef,
 rightSidebarHeight,
 showCertificates = true,
 isMobile
} = {}) {

    const { certificates } = useContext(ThemeGeneralSettingsContext)
    const {shopware_developer, shopware_designer} = certificates;
    const smallCertificates = rightSidebarHeight <= window.innerHeight - 100 && rightSidebarHeight !== 0;

    return (
        <aside className="techologies-container">
            {!isMobile && <PostOtherTechnologies pageData={page_data}/>}
            {showCertificates && (
                    <div className={smallCertificates ?
                        "shopware__content--right certificates-sidebar small" :
                        "shopware__content--right certificates-sidebar"
                    }
                         ref={rightSidebarRef}>
                        <div className="certificates-sidebar--inner">
                            <img src={shopware_developer?.url} alt={shopware_developer?.alt}/>
                            <img src={shopware_designer?.url} alt={shopware_designer?.alt}/>
                        </div>
                    </div>
                )}
        </aside>
    )
}