import {Link} from "react-router-dom";
import './PostOtherTechnologies.scss';

export default function PostOtherTechnologies ({pageData}) {

    const { acf = {} } = pageData;

    return ( <div className="other-technologies__content-right">
        <div className="technologies-title">{acf?.technologies_header}</div>
        <div className="technologies-list">
            {(acf?.technologies).map((technologie) => {
                const key = technologie?.link?.title;
                return (
                    <div className="technologies-item" key={key}>
                        <Link to={`${technologie?.link?.url}`}>
                            <span>{technologie?.link?.title}</span>
                        </Link>
                    </div>
                )
            })}
        </div>
    </div>)
}